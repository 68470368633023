import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepContent from '@material-ui/core/StepContent';
import PersonalForm from "../../components/PersonalForm"
import SubscriptionPlans from "../../components/SubscriptionPlans"
import NewPaymentMethodForm from "../../components/NewPaymentMethodForm"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import { Auth, API } from "aws-amplify";
import "./main.css"
import { s3Upload, createCustomer, upgradeUser } from "../../libs/awsLib";
import NavigationPrompt from "react-router-navigation-prompt";
import ConfirmationModal from "../../components/ConfirmationModal";
import ViewablePasswordField from "../../components/ViewablePasswordField";
import LoadingMenu from "../../components/LoadingMenu";
import "./main.css";
import config from "../../config"


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: "flex",
        padding: "2rem 0"
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepContainer: {
        padding: theme.spacing(2),
        display: "flex",
        margin: "auto",
        maxWidth: "1000px",
        width: "100%"
    },
    stepView: {
        margin: "auto",
        width: "90%"
    },
    title: {
        fontSize: "2rem"
    },
    panel: {
        padding: "1rem"
    },
    panelContainer: {
        margin: "1rem 0"
    },
    infoSection: {
        margin: "1rem 0"
    },
    stepRoot: {
        padding: "1.5rem"
    }
}));

export default function Upgrade(props) {
    const { history, storeUser, user } = props;

    const { name, email } = user;

    // Loads Stripe into the Elements wrapper
    const stripePromise = loadStripe(config.STRIPE_KEY)

    const classes = useStyles();

    // The current step the user is viewing
    const [activeStep, setActiveStep] = React.useState(0);

    // Holds all use data from signup
    const [newUserData, setNewUserData] = React.useState({ name, email });

    //A boolean indicating whether the form is complete
    const [isComplete, setIsComplete] = React.useState({ 0: true });

    // A boolean indicating whether the final dialog is open
    const [confirmDialogOpen, handleConfirmDialogOpen] = React.useState(false);

    const [confirmField, setConfirmField] = useState("")

    const [passwordError, setPasswordError] = useState(false)

    const [passwordVerifying, setPasswordVerifying] = useState(false)

    const [creating, setCreating] = useState(false)

    const [cardError, setCardError] = React.useState(null);

    // A ref holding the newly created user ID
    const userId = React.useRef();

    const steps = getSteps();

    // Handles any form change
    const handleChange = (step, data) => {
        const tmp = isComplete;
        tmp[step] = data.complete;

        setIsComplete({ ...tmp })

        const tmp2 = { ...newUserData, ...data };

        setNewUserData(tmp2)

        setCardError(null)

    }

    // Handles incrementing the step
    const handleNext = () => {
        // Set next step
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    // Handles decrementing the step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // Sign up steps
    function getSteps() {
        return [
            'User Info',
            'Choose a Subscription Plan',
            'Billing Info',
            'Confirm your Account',
        ];
    }

    // Sign up step views
    function getStepContent(step) {
        switch (step) {
            // Account Info
            case 0:
                return (
                    <>
                        <PersonalForm
                            onChange={(data) => handleChange(step, data)}
                            user={newUserData}
                            disabled={["name", "email"]}
                        />
                    </>
                );
            // Displays all subscription plans
            case 1:
                return (
                    <>
                        <SubscriptionPlans
                            onChange={(data) => handleChange(step, data)}
                            plan={newUserData["plan"]}
                        />
                    </>
                )
            // Displays Stripe CardElement
            case 2:
                return (
                    <>
                        {
                            isComplete[step] &&
                            <>
                                <Typography style={{ color: "green" }} variant="subtitle1"><i className="far fa-check-circle"></i>&nbsp;Your card information has been saved. Entering new information below will reset it.</Typography>
                            </>
                        }
                        {
                            cardError &&
                            <>
                                <Typography color="secondary" variant="subtitle1"><i className="far fa-cross-circle"></i>&nbsp;{cardError}</Typography>
                            </>
                        }
                        <Elements stripe={stripePromise}>
                            <NewPaymentMethodForm
                                onSubmit={(data) => handleChange(step, data)}
                                onError={(message) => setCardError(message)}

                            />
                        </Elements>
                        {/* Inform that Payment Info can not be updated later, all info must be reentered again */}
                        <Typography variant="subtitle1">For security reasons, your card's information will not be saved between steps. If you wish to update your card's information after leaving this step, you will have to re-enter it.</Typography>
                    </>
                )
            // Displays all sign up information for confirmation
            case 3:
                return (
                    <div className={classes.panel}>
                        <div className={classes.panelContainer}>
                            {/* Show User Name */}
                            <Typography color="textSecondary" style={{ fontSize: "1.3rem" }} variant="overline" >
                                Your Account Info &nbsp;<span style={{ color: "blue", cursor: "pointer" }} onClick={() => setActiveStep(0)}>Edit</span>
                            </Typography>
                            <div className={classes.infoSection}>
                                <Typography style={{ fontSize: "1rem" }} variant="h6" >
                                    Your Name
                            </Typography>
                                <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="subtitle1" >
                                    {newUserData["name"]}
                                </Typography>
                            </div>
                            {/* Show User Email */}
                            <div className={classes.infoSection}>
                                <Typography style={{ fontSize: "1rem" }} variant="h6" >
                                    Your Email
                            </Typography>
                                <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="subtitle1" >
                                    {newUserData["email"]}
                                </Typography>
                            </div>

                            {/* Show Phone */}
                            <div className={classes.infoSection}>
                                <Typography style={{ fontSize: "1rem" }} variant="h6" >
                                    Your Phone Number
                        </Typography>
                                <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="subtitle1" >
                                    {newUserData["phone"] ? newUserData["phone"] : "Not Provided"}
                                </Typography>
                            </div>

                            {/* Show Company */}
                            <div className={classes.infoSection}>
                                <Typography style={{ fontSize: "1rem" }} variant="h6" >
                                    Your Company's Name
                            </Typography>
                                <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="subtitle1" >
                                    {newUserData["company"] ? newUserData["company"] : "Not Provided"}
                                </Typography>
                            </div>

                            {/* Show Logo */}
                            <div className={classes.infoSection}>
                                <Typography style={{ fontSize: "1rem" }} variant="h6" >
                                    Your Company's Logo
                            </Typography>
                                <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="subtitle1" >
                                    {newUserData["logo"] ? newUserData["logo"] : "Not Provided"}
                                </Typography>
                            </div>

                            {
                                newUserData["logo"] ?
                                    <img alt="User Logo" src={newUserData["logo"]} style={{ width: "100%" }} />
                                    : ""
                            }
                        </div>

                        {/* Show Selected Subscription Product */}
                        <div className={classes.panelContainer}>
                            <Typography color="textSecondary" style={{ fontSize: "1.3rem" }} variant="overline" >
                                The Product &nbsp;<span style={{ color: "blue", cursor: "pointer" }} onClick={() => setActiveStep(1)}>Edit</span>
                            </Typography>
                            <Paper elevation={3} className={classes.panel}>
                                <Typography variant="subtitle2">
                                    {newUserData["product"].name}
                                </Typography>
                                <Typography variant="caption">
                                    {newUserData["product"].description}
                                </Typography>
                            </Paper>
                        </div>

                        {/* Show Selected Subscription Plan */}
                        <div className={classes.panelContainer}>
                            <Typography color="textSecondary" style={{ fontSize: "1.3rem" }} variant="overline" >
                                Your Pricing Plan &nbsp;<span style={{ color: "blue", cursor: "pointer" }} onClick={() => setActiveStep(1)}>Edit</span>
                            </Typography>
                            <Paper elevation={3} className={classes.panel}>
                                <Typography variant="subtitle2">
                                    {`${newUserData["plan"].nickname}`} Plan
                            </Typography>
                                <Typography variant="caption">
                                    <strong style={{ color: "#6b95f4", display: "block" }}>
                                        ${(newUserData["plan"].amount / 100).toFixed(2)} / {newUserData["plan"].interval}
                                    </strong>
                                    {newUserData["plan"].description}
                                </Typography>
                            </Paper>
                        </div>

                        {/* Show Entered Card information */}
                        <div className={classes.panelContainer}>
                            <Typography color="textSecondary" style={{ fontSize: "1.3rem" }} variant="overline" >
                                Your Card &nbsp;<span style={{ color: "blue", cursor: "pointer" }} onClick={() => setActiveStep(2)}>Edit</span>
                            </Typography>
                            <Paper elevation={3} className={classes.panel}>
                                <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                // className={classes.root}
                                >

                                    <ListItem>
                                        <ListItemIcon>
                                            <i className={`fab fa-cc-${newUserData["paymentMethod"].card.brand.toLowerCase().split(" ").join("-")}`} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <div>
                                                        ••••••••••••{newUserData["paymentMethod"].card.last4}
                                                    </div>

                                                </>
                                            }
                                            secondary={
                                                <>
                                                    {newUserData["paymentMethod"].card.funding.charAt(0).toUpperCase().concat(newUserData["paymentMethod"].card.funding.slice(1))} | Expires {newUserData["paymentMethod"].card.exp_month} / {newUserData["paymentMethod"].card.exp_year}
                                                </>
                                            }
                                        />

                                        <Typography variant="overline" color="primary">
                                            Default
                                        </Typography>

                                    </ListItem>
                                </List>
                            </Paper>
                        </div>

                    </div>
                )
            default:
                return 'Unknown step';
        }
    }

    // Validate verification code, complete Stripe signup, and show success message
    async function completeSignUp() {
        setCreating(true)

        const userPayload = newUserData;

        try {
            // Upload the user logo to S3 (https://medium.com/@anjanava.biswas/uploading-files-to-aws-s3-from-react-app-using-aws-amplify-b286dbad2dd7)
            let logo = null;
            if (newUserData["logo_file"]) {
                const result = await s3Upload(newUserData["logo_file"], "public");
                if (!result.error) logo = result;
                else throw { message: "An error occurred when your logo was being uploaded" }
            }

            userPayload["logo"] = logo;


            // Parse the new user's data and make a request via POST to /billing to create the Stripe Customer
            const stripeResponse = await createCustomer(
                {
                    payment_method_id: userPayload["paymentMethod"].id,
                    email: userPayload["email"],
                    name: userPayload["name"],
                    plan_id: userPayload["plan"].id
                }
            )

            const { customer, subscription } = stripeResponse;
            if (!customer || !subscription) {
                throw { message: "An error occurred when your Stripe account was being created" };
            }


            // Make the ADUROI user via POST to /user
            const userResponse = await upgradeUser(
                {
                    user_id: user.user_id,
                    logo: userPayload["logo"],
                    company: userPayload["company"],
                    active: true,
                    type: "personal",
                    name: userPayload["name"],
                    customer_id: customer.id,
                    invite_id: user.invite_id,
                    realtor_id: user.realtor_id,
                }
            )

            if (!userResponse) {
                throw { message: "An error occurred when creating your ADUROI account" }
            }

            let authUser = await Auth.currentAuthenticatedUser();

            // After a new user has been created via POST to /billing, redirect to the home page
            await storeUser(authUser);

            // Store DynamoDB user (TODO)
            history.replace("/");


        } catch (e) {
            setCreating(false)
            setPasswordError(e.message)
        }
    }

    const handlePasswordVerify = async () => {
        setPasswordError(false)
        setPasswordVerifying(true);

        try {
            // Verify Password
            await Auth.signIn(user.email, confirmField);

            completeSignUp();
        } catch (e) {
            setPasswordError(true)
        }
        setPasswordVerifying(false)
    }

    return (
        <div className={classes.root}>
            <NavigationPrompt when={(confirmDialogOpen || !(JSON.stringify(newUserData) == JSON.stringify({}))) && !creating} afterCancel={() => console.log("cancel")} afterConfirm={() => console.log("confirm")}>
                {({ onConfirm, onCancel }) => (
                    <ConfirmationModal
                        open={true}
                        setOpen={onCancel}
                        handleConfirm={onConfirm}
                        text="Account creation is incomplete. Are you sure you want to leave this page?"
                    />
                )}
            </NavigationPrompt>

            <ConfirmationModal
                open={confirmDialogOpen}
                title={`Are you sure you want to upgrade your account?`}
                text={!creating ? `To confirm your account upgrade, enter your password below.` : null}
                setOpen={!(passwordVerifying || creating) ? handleConfirmDialogOpen : () => { }}
                handleConfirm={!creating ? handlePasswordVerify : () => { }}
                disabled={passwordVerifying || creating}
            >
                {
                    creating ?
                        <LoadingMenu
                            text={`Your account is being created!`}
                        />
                        :


                        <ViewablePasswordField
                            value={confirmField}
                            onChange={(e) => setConfirmField(e.target.value)}
                            fullWidth
                            placeholder="Your Password"
                            className={classes.infoSection}
                            error={passwordError}
                            helperText={passwordError ? "The entered password is incorrect." : ""}

                        />
                }

            </ConfirmationModal>

            <Paper elevation={10} className={classes.stepContainer}>

                <div className={classes.stepView}>

                    <Typography align="center" color="primary" className={classes.title} variant="overline" component="h3">Upgrade Your Account</Typography>

                    {/* Process stepper */}
                    <Stepper orientation={"vertical"} activeStep={activeStep} classes={{ root: classes.stepRoot }}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                    <StepContent>

                                        <div>
                                            <div style={{ display: "flex" }}>
                                                {/* Step View */}
                                                <div style={{ margin: "auto", width: "100%" }}>


                                                    {getStepContent(activeStep)}

                                                    {/* Buttons to control the step the user is on */}
                                                    <div style={{ margin: "2rem 0", display: "flex", justifyContent: "space-between" }}>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            className={classes.button}
                                                            variant="outlined"
                                                        >
                                                            Back
                                                        </Button>

                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={!(activeStep === steps.length - 1) ? handleNext : () => handleConfirmDialogOpen(true)}
                                                            className={classes.button}
                                                            disabled={!isComplete[activeStep] && !(activeStep === steps.length - 1)}
                                                        >
                                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                        </Button>
                                                    </div>



                                                </div>

                                            </div>

                                        </div>
                                    </StepContent>
                                </Step>
                            );
                        })}
                    </Stepper>


                </div>
            </Paper>


        </div>
    );
}