import React, { useState } from "react";
import { TextField, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import Dropzone from 'react-dropzone';
import PropTypes from "prop-types"
import "./main.css"

PersonalForm.propTypes = {
    user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        company: PropTypes.string,
        phone: PropTypes.number,
        logo: PropTypes.string,
    }),
    disabled: PropTypes.array,
    onChange: PropTypes.func.isRequired,
}

const defaultUser = {
    name: "",
    email: "",
    company: "",
}

PersonalForm.defaultProps = {
    user: defaultUser,
    disabled: []
}

const useStyles = makeStyles({
    textMargin: {
        margin: "1rem 0"
    }
})

const requiredValues = [
    {
        value: "name",
        verify: (value) => value
    },
    {
        value: "email",
        verify: (value) => {
            if (!value) return false;
            return value.includes("@")
        }
    },
]

function PersonalForm(props) {
    const { onChange, user, disabled } = props;

    let initialUser = user;
    if (!Object.keys(initialUser).length) initialUser = defaultUser

    const [userData, setUserData] = useState(initialUser);
    const [dropzoneError, setDropzoneError] = useState(false);
    const classes = useStyles();
    const IMG_PLACEHOLDER = "https://pbs.twimg.com/media/DnE2oP6UYAAJr8R.jpg";

    const verifyComplete = (values) => {
        // Verify these fields are valid and not undefined
        let isComplete = true;

        for (const rv of requiredValues) {
            isComplete = isComplete && rv.verify(values[rv.value])
        }

        return isComplete
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const tmp = userData;
        tmp[name] = value;

        tmp.complete = verifyComplete(tmp);

        setUserData({ ...tmp })
        onChange({ ...tmp })

    }


    const handleDrop = (imageFiles) => {
        let image = imageFiles[0];

        let tmp = userData;
        tmp["logo_file"] = image;

        if (typeof image == "object") {
            image = URL.createObjectURL(image);
            tmp["logo"] = image;
        }

        tmp.complete = verifyComplete(tmp);

        setUserData({ ...tmp });
        onChange({ ...tmp })


        setDropzoneError(false);

    }

    const addDefaultSrc = ev => {
        ev.target.src = IMG_PLACEHOLDER;

        setDropzoneError(true);
    }

    return (
        <>
            <div >
                <TextField
                    autoComplete="off"
                    fullWidth
                    name="name"
                    value={userData["name"]}
                    label={"Your Name"}
                    onChange={handleChange}
                    className={classes.textMargin}
                    required
                    disabled={disabled.includes("name")}
                />
                <TextField
                    autoComplete="off"
                    type="email"
                    fullWidth
                    name="email"
                    value={userData["email"]}
                    label={"Your Email"}
                    onChange={handleChange}
                    className={classes.textMargin}
                    required
                    disabled={disabled.includes("email")}
                />

                <NumberFormat
                    autoComplete="off"
                    mask={"_"}
                    format={"(###) ###-####"}
                    customInput={TextField}
                    fullWidth
                    name="phone"
                    value={userData["phone"]}
                    label={"Your Phone Number"}
                    onValueChange={(values) => {
                        handleChange({
                            target: {
                                value: values.floatValue,
                                name: "phone"
                            }
                        })
                    }}
                    className={classes.textMargin}
                    disabled={disabled.includes("phone")}
                />

                <TextField
                    autoComplete="off"
                    fullWidth
                    name="company"
                    value={userData["company"]}
                    label={"Your Company Name"}
                    onChange={handleChange}
                    className={classes.textMargin}
                    disabled={disabled.includes("company")}
                />


                <div>
                    <TextField
                        autoComplete="off"
                        disabled
                        value={userData["logo"] || ''}
                        placeholder={"Logo URL"}
                        label={"Your Logo"}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classes.textMargin}
                    />

                    {/* DROPZONE WITH PREVIEW */}
                    <Dropzone
                        style={{ margin: "0.5em" }}
                        onDrop={(img) => handleDrop(img)}>
                        {({ getRootProps, getInputProps }) => (
                            <Box >
                                <div {...getRootProps({ className: dropzoneError ? 'dropzone-error' : 'dropzone' })}>
                                    <input {...getInputProps({ multiple: false, accept: 'image/*' })} />
                                    <div className="image-overlay">
                                        <p className="image-overlay-text">Drag 'n' drop an image here, or click to select one</p>
                                    </div>
                                    <img
                                        onError={addDefaultSrc}
                                        alt="User Logo"
                                        style={{ margin: "auto", width: "100%" }}
                                        src={
                                            !userData["logo"] ?
                                                IMG_PLACEHOLDER

                                                : userData["logo"]
                                        }
                                    />
                                </div>
                            </Box>
                        )}
                    </Dropzone>

                </div>


            </div>
        </>
    )

}

export default PersonalForm;
