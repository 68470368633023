import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepContent from '@material-ui/core/StepContent';
import FinalSignUpDialog from "../../components/FinalSignUpDialog"
import PageMessage from "../../components/PageMessage"
import { Auth, API } from "aws-amplify";
import Loading from "../../components/Loading"
import "./main.css"
import parseQuery from "../../libs/parseQuery";
import { getInvite, getUser, createUser, s3GetDownloadURL } from "../../libs/awsLib";
import NavigationPrompt from "react-router-navigation-prompt";
import ConfirmationModal from "../../components/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: "flex",
        padding: "2rem 0"
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepContainer: {
        padding: theme.spacing(2),
        display: "flex",
        margin: "auto",
        maxWidth: "1000px",
        width: "100%"
    },
    stepView: {
        margin: "auto",
        width: "90%"
    },
    title: {
        fontSize: "2rem"
    },
    panel: {
        padding: "1rem"
    },
    panelContainer: {
        margin: "1rem 0"
    },
    infoSection: {
        margin: "1rem 0"
    },
    stepRoot: {
        padding: "1.5rem"
    }
}));

export default function Invite(props) {
    const { userHasAuthenticated, history, storeUser } = props;

    const classes = useStyles();

    // The current step the user is viewing
    const [activeStep, setActiveStep] = React.useState(0);

    // A boolean indicating whether the final dialog is open
    const [confirmDialogOpen, handleConfirmDialogOpen] = React.useState(false);

    // The invite object
    const inviteData = useRef()

    // The invite code
    const inviteCode = useRef()

    // A boolean indicating invite retrieval is occurring
    const [isVerifying, setIsVerifying] = useState(true);

    // The realtor's user object
    const realtorData = useRef()

    // A ref holding the newly created user ID
    const userId = useRef();

    const steps = getSteps();

    // Handles incrementing the step
    const handleNext = () => {
        // Set next step
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    // Handles decrementing the step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // Sign up steps
    function getSteps() {
        return [
            'Confirm your Account',
        ];
    }

    // Sign up step views
    function getStepContent(step) {
        switch (step) {
            // Displays all sign up information for confirmation
            case 0:
                return (
                    <div className={classes.panel}>
                        <div className={classes.panelContainer}>
                            <Typography color="textSecondary" style={{ fontSize: "1.3rem" }} variant="overline" >
                                Your Account Info
                            </Typography>

                            {/* Show User Name */}
                            <div className={classes.infoSection}>
                                <Typography style={{ fontSize: "1rem" }} variant="h6" >
                                    Your Name
                            </Typography>
                                <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="subtitle1" >
                                    {inviteData.current["name"]}
                                </Typography>
                            </div>
                            {/* Show User Email */}
                            <div className={classes.infoSection}>
                                <Typography style={{ fontSize: "1rem" }} variant="h6" >
                                    Your Email
                            </Typography>
                                <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="subtitle1" >
                                    {inviteData.current["email"]}
                                </Typography>
                            </div>

                            {/* Show Reatlor's Name */}
                            <div className={classes.infoSection}>
                                <Typography style={{ fontSize: "1rem" }} variant="h6" >
                                    Account Courtesy of
                            </Typography>
                                <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="subtitle1" >
                                    {realtorData.current["name"]}
                                </Typography>
                            </div>

                            {/* Show Company */}
                            <div className={classes.infoSection}>
                                <Typography style={{ fontSize: "1rem" }} variant="h6" >
                                    {/* Account Courtesy of Company */}
                            </Typography>
                                <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="subtitle1" >
                                    {realtorData.current["company"] ? realtorData.current["company"] : "Not Provided"}
                                </Typography>
                            </div>

                            {/* Show Logo */}
                            <div className={classes.infoSection}>
                                <Typography style={{ fontSize: "1rem" }} variant="h6" >
                                    {/* Account Courtesy of Company's Logo */}
                                </Typography>

                                {
                                    realtorData.current["logo"] ?
                                        <img alt="Realtor's Logo" src={realtorData.current["logo"]} style={{ width: "100%" }} />
                                        :

                                        <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="subtitle1" >
                                            {"Not Provided"}
                                        </Typography>
                                }
                            </div>

                        </div>

                    </div>
                )
            default:
                return 'Unknown step';
        }
    }

    async function completeSignUp() {

        try {

            // Verify the invite still exists
            const inviteObj = await getInvite(inviteCode.current)

            if(!inviteObj) throw { message: "This invite no longer exists" }

            // Make the ADUROI user via POST to /user
            const userResponse = await createUser({
                active: true,
                type: "client",
                name: inviteData.current["name"],
                email: inviteData.current["email"],
                invite_id: inviteData.current["code"],
                realtor_id: inviteData.current["user_id"]
            })

            userId.current = userResponse.user_id;


            if (!userResponse) {
                throw { message: "An error occurred when creating your ADUROI account" }
            }

        } catch (e) {
            throw (e)

        }
    }

    const handlePostVerification = async () => {
        // Save returned user id to Cognito's user account
        try {
            let user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
                'custom:user_id': userId.current,
            })
            userHasAuthenticated(true);

            // Store DynamoDB user (TODO)
            await storeUser({
                attributes: {
                    "custom:user_id": userId.current,
                    "email": user.attributes.email
                }
            });
            history.replace("/");

        } catch (e) {
            await Auth.signOut()

            throw e
        }

    }

    useEffect(() => {
        async function loadInviteInfo(invite_id) {
    
            try {
                // Retrieve the invite
    
                const inviteObj = await getInvite(invite_id)
    
                // If an invite is associated with the specified code....
    
                if (inviteObj) {
                    inviteData.current = inviteObj
    
                    // Retrieve the realtor's user account
                    let realtorObj = await getUser(inviteObj.user_id)
    
                    if (realtorObj) {
                        if (realtorObj.logo) realtorObj.logo = await s3GetDownloadURL(realtorObj.logo)
                        realtorData.current = realtorObj;
                    }
                }
    
                setIsVerifying(false);
    
            } catch (e) {
    
                setIsVerifying(false);
    
            }
    
        }

        inviteCode.current = parseQuery(props.history.location.search).code
        loadInviteInfo(inviteCode.current);
    }, [])

    return (
        <div className={classes.root}>
            <NavigationPrompt when={confirmDialogOpen} afterCancel={() => console.log("cancel")} afterConfirm={() => console.log("confirm")}>
                {({ onConfirm, onCancel }) => (
                    <ConfirmationModal
                        open={true}
                        setOpen={onCancel}
                        handleConfirm={onConfirm}
                        text="Account creation is incomplete. Are you sure you want to leave this page?"
                    />
                )}
            </NavigationPrompt>

            {
                isVerifying ?
                    <>
                        <Loading
                            text={`Verifying invite...`}
                        />
                    </>
                    :

                    // If the invite is no longer being verified AND there is no set invite object...
                    !inviteData.current ?

                        <>

                            <PageMessage text={
                                <>
                                    No invite exists with the specified code <i>{inviteCode.current}</i>
                                </>
                            }>
                                <i style={{ margin: "auto", fontSize: "5rem", color: "#ff6f6f" }} className="fas fa-ban"></i>
                            </PageMessage>

                        </>
                        :

                        // If the invite is no longer active
                        !(inviteData.current.active) ?

                            <>
                                <PageMessage text={
                                    <>
                                        The specified invite with code <i>{inviteCode.current}</i> has already been activated
                                </>
                                }>
                                    <i style={{ margin: "auto", fontSize: "5rem", color: "orange" }} className="fas fa-lock-open"></i>
                                </PageMessage>
                            </>
                            :

                            // If the invite is no longer active
                            !(realtorData.current) ?

                                <>
                                    <PageMessage text={
                                        <>
                                            The provider of your courtesy account no longer has an account on ADUROI
                                        </>
                                    }>
                                        <i style={{ margin: "auto", fontSize: "5rem", color: "#ff6f6f" }} className="fas fa-user-slash"></i>
                                    </PageMessage>
                                </>
                                :


                                <>
                                    <FinalSignUpDialog
                                        open={confirmDialogOpen}
                                        setOpen={handleConfirmDialogOpen}
                                        onPasswordSave={completeSignUp}
                                        onEmailConfirm={handlePostVerification}
                                        onError={() => { }}
                                        email={inviteData.current["email"]}
                                    />

                                    <Paper elevation={10} className={classes.stepContainer}>

                                        <div className={classes.stepView}>
                                            <Typography align="center" color="primary" className={classes.title} variant="overline" component="h3">Invite Sign up</Typography>

                                            {/* Process stepper */}
                                            <Stepper orientation={"vertical"} activeStep={activeStep}
                                                classes={{
                                                    root: classes.stepRoot
                                                }}>
                                                {steps.map((label, index) => {
                                                    const stepProps = {};
                                                    const labelProps = {};

                                                    return (
                                                        <Step key={label} {...stepProps}>
                                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                                            <StepContent>

                                                                <div>
                                                                    <div style={{ display: "flex" }}>
                                                                        {/* Step View */}
                                                                        <div style={{ margin: "auto", width: "100%" }}>


                                                                            {getStepContent(activeStep)}

                                                                            {/* Buttons to control the step the user is on */}
                                                                            <div style={{ margin: "2rem 0", display: "flex", justifyContent: "space-between" }}>
                                                                                <Button
                                                                                    disabled={activeStep === 0}
                                                                                    onClick={handleBack}
                                                                                    className={classes.button}
                                                                                    variant="outlined"
                                                                                >
                                                                                    Back
                                                                        </Button>

                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={!(activeStep === steps.length - 1) ? handleNext : () => handleConfirmDialogOpen(true)}
                                                                                    className={classes.button}
                                                                                >
                                                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                                                </Button>
                                                                            </div>



                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </StepContent>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>


                                        </div>
                                    </Paper>
                                </>

            }
        </div>
    );
}