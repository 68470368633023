import React from "react";
import NumberFormat from "react-number-format";
import { Field } from "react-final-form";
import { TextField } from "mui-rff";

function CurrencyField(props) {
  const { name, ...rest } = props;
  return (
    <Field
      name={name}
      parse={value => value}
      format={value => value}
      component= {
        ({ input, meta }) => {
          return (
            <NumberFormat
              onValueChange={(values) => input.onChange(values.floatValue)}
              {...rest}
              error={meta.error ? true : false}
              helperText={meta.error}
              value={input.value}
              customInput={TextField}
              thousandSeparator
              prefix="$"
            />)
        }
      }
    />
      
  );
}

export default CurrencyField;
