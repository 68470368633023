import React, { useState } from "react";
import { Auth } from "aws-amplify";
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/styles/makeStyles"
import LoaderButton from "../../components/LoaderButton";
import EmailVerificationDialog from "../../components/EmailVerificationDialog";
import ForgotPasswordDialog from "../../components/ForgotPasswordDialog";
import { useFormFields } from "../../libs/hooksLib";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import "./Login.css";

const useStyles = makeStyles({
  field: {
    margin: "1rem 0",
    width: "100%"
  },
  title: {
    margin: "1rem 0",
    fontSize: "1.5rem"
  }
})

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles()
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    code: ""
  });
  const [showPass, setShowPass] = useState(false)
  const [error, setError] = useState("")
  const [showConfirmEmailDialog, setShowConfirmEmailDialog] = useState(false)
  const [showForgotPassDialog, setShowForgotPassDialog] = useState(false)

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const user = await Auth.signIn(fields.email, fields.password);
      const res = await props.storeUser(user);
      if (!res) throw { message: "A problem occurred when retrieving your account" }
    } catch (e) {
      // alert(e.message);

      setError(e.message)
      setIsLoading(false);

      // If user is not verified...
      // {
      //   code: "UserNotConfirmedException"
      //   name: "UserNotConfirmedException"
      //   message: "User is not confirmed."
      // }
      if (e.code === "UserNotConfirmedException") {
        await Auth.resendSignUp(fields.email);
        setShowConfirmEmailDialog(true);

      }

    }
  }

  async function handlePostConfirmation() {
    setShowConfirmEmailDialog(false);
    setIsLoading(true);

    try {
      const user = await Auth.signIn(fields.email, fields.password);
      props.storeUser(user);
    } catch (e) {
      // alert(e.message);

      setError(e.message)
      setIsLoading(false);

      // If user is not verified...
      // {
      //   code: "UserNotConfirmedException"
      //   name: "UserNotConfirmedException"
      //   message: "User is not confirmed."
      // }
      if (e.code === "UserNotConfirmedException") {
        await Auth.resendSignUp(fields.email);
        setShowConfirmEmailDialog(true);

      }

    }
  }

  async function handlePostPasswordChange(password) {
    setShowForgotPassDialog(false);
    setIsLoading(true);
    setError("")

    try {
      const user = await Auth.signIn(fields.email, password);
      props.storeUser(user);
    } catch (e) {
      // alert(e.message);

      setError(e.message)
      setIsLoading(false);

      // If user is not verified...
      // {
      //   code: "UserNotConfirmedException"
      //   name: "UserNotConfirmedException"
      //   message: "User is not confirmed."
      // }
      if (e.code === "UserNotConfirmedException") {
        await Auth.resendSignUp(fields.email);
        setShowConfirmEmailDialog(true);

      }

    }
  }

  async function handleForgotPassword() {
    try {
      if (fields.email) {

        await Auth.forgotPassword(fields.email);
        setShowForgotPassDialog(true);
      }
      else {
        throw { message: "Please enter your email." }
      }
    } catch (e) {
      setError(e.message)
      setShowForgotPassDialog(false);

    }


  }

  return (
    <div className="Login">

      <EmailVerificationDialog
        open={showConfirmEmailDialog}
        setOpen={setShowConfirmEmailDialog}
        onEmailConfirm={handlePostConfirmation}
        email={fields.email}
      />
      <ForgotPasswordDialog
        open={showForgotPassDialog}
        setOpen={setShowForgotPassDialog}
        onPasswordChange={handlePostPasswordChange}
        email={fields.email}
      />

      <Typography
        className={classes.title}
        align="center"
        variant="overline"
        component="h5"
      >
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          required
          label="Email"
          value={fields["email"]}
          onChange={handleFieldChange}
          variant="outlined"
          name="email"
          className={classes.field}
          autoComplete="off"
        />
        <TextField
          autoComplete="off"
          required
          label="Password"
          type={!showPass ? "password" : ""}
          value={fields["password"]}
          name="password"
          onChange={handleFieldChange}
          variant="outlined"
          className={classes.field}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" >
                <IconButton edge="end" onClick={() => setShowPass(!showPass)}>
                  {
                    showPass ?
                      <Visibility />
                      :
                      <VisibilityOff />
                  }
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoaderButton
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
        <div style={{ display: "flex", margin: "1rem 0" }}>
          <Button onClick={handleForgotPassword} size="small" style={{ margin: "auto" }}>
            Forgot Password&nbsp;<i className="far fa-question-circle"></i>
          </Button>
        </div>
      </form>
      <Typography style={{ margin: "1rem 0" }} align="center" variant="subtitle1" component="p" color="secondary">
        {error}
      </Typography>
    </div>
  );
}
