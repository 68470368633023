import React, { useState, useEffect } from "react"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import NavigationPrompt from "react-router-navigation-prompt";
import RequestNotification from "./RequestNotification"
import ConfirmationModal from "./ConfirmationModal"
import SubscriptionForm from "./SubscriptionForm"
import NewSubscriptionForm from "./NewSubscriptionForm"
import UserSubscriptions from "./UserSubscriptions"
import SubscriptionUpdateConfirmationModal from "./SubscriptionUpdateConfirmationModal"
import ViewablePasswordField from "./ViewablePasswordField"
import makeStyles from "@material-ui/styles/makeStyles"
import SlidePanel from "./SlidePanel"
import { Auth } from "aws-amplify"
import { createSubscription, updateSubscription, deleteSubscription } from "../libs/awsLib"

const useStyles = makeStyles({
    button: {
        marginLeft: "1rem"
    },
    section: {
        margin: "2rem 0"

    }
})

const subSavingAlertMap = {
    "success": "Your subscription was successfully updated!",
    "error": "An error occurred when attempting to update your subscription.",
    "loading": "Updating your subscription...."

}

const subDeletingAlertMap = {
    "success": `Your subscription was successfully deleted.`,
    "error": "An error occurred when attempting to delete your subscription.",
    "loading": "Deleting your subscription..."
}

const subCreatingAlertMap = {
    "success": `Your subscription was successfully created!`,
    "error": "An error occurred when attempting to create your subscription.",
    "loading": "Creating your subscription..."
}


export default function SubscriptionSettings(props) {
    const { user, isSubscribed, userHasSubscribed } = props;
    const classes = useStyles();

    const [selectedSub, setSelectedSub] = useState({});

    const [updatedSub, setUpdatedSub] = useState({});

    const [actionPending, setActionPending] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false)

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const [subConfirmDialogOpen, setSubConfirmDialogOpen] = useState(false);

    const [requestDialogOpen, setRequestDialogOpen] = useState(false);

    const [requestStatus, setRequestStatus] = useState("error");

    const [requestText, setRequestText] = useState(subSavingAlertMap);

    const [confirmField, setConfirmField] = useState("")

    const [passwordError, setPasswordError] = useState(false)

    const [passwordVerifying, setPasswordVerifying] = useState(false)

    const [updated, setUpdated] = useState({
        subs: false
    });


    const handleSelectChange = (item) => {
        if (!item) item = {}

        setSelectedSub(item)
    }

    const handleOpenDialog = () => {
        setDialogOpen(!dialogOpen)
    }

    const handleSaveSub = async (updates) => {
        // Close the create dialog
        setDialogOpen(false)

        // Close confirmation dialog
        setSubConfirmDialogOpen(false)

        // Show that an action is pending
        setActionPending(true)

        // Map user deleting request text
        setRequestText(subSavingAlertMap)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        const updatedSub = { updates, id: selectedSub.id, sub_item_id: selectedSub.items.data[0].id}
        // Save the subscription
        try {

            const res = await updateSubscription(updatedSub, user.user_id)
            if (res.error) throw {}
            else setRequestStatus("success")

            // Reset the selectedSub
            setSelectedSub({})

        } catch (e) {
            setRequestStatus("error")
        }

        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Refresh subs (TODO)
        handleRefresh("subs")
    }

    const handleDeleteSub = async () => {
        // Close the delete dialog
        setDeleteDialogOpen(false)

        // Map user deleting request text
        setRequestText(subDeletingAlertMap)

        // Show that an action is pending
        setActionPending(true)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Delete the subscription
        try {

            const res = await deleteSubscription(selectedSub.id)
            if (res.error) throw {}
            else setRequestStatus("success")

            // Reset the selectedSub
            setSelectedSub({})

            // Reset isSubscribed
            userHasSubscribed(false)

        } catch (e) {
            setRequestStatus("error")
        }

        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Refresh subs (TODO)
        handleRefresh("subs")

    }

    const handleCreateSub = async (sub) => {
        // Close the create dialog
        setCreateDialogOpen(false)

        // Map user deleting request text
        setRequestText(subCreatingAlertMap)

        // Show that an action is pending
        setActionPending(true)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        try {

            const res = await createSubscription(sub, user.customer_id)
            if (res.error) throw {}
            else setRequestStatus("success")

            // Reset isSubscribed (TODO)
            userHasSubscribed(true)

        } catch (e) {
            setRequestStatus("error")
        }

        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Refresh subs (TODO)
        handleRefresh("subs")

    }

    const handleVerifySub = (updatedSub) => {
        setUpdatedSub(updatedSub);
        setSubConfirmDialogOpen(true)
    }

    const handlePasswordVerify = async () => {
        setPasswordError(false)
        setPasswordVerifying(true);

        try {
            // Verify Password
            await Auth.signIn(user.email, confirmField);

            handleDeleteSub();
        } catch (e) {
            setPasswordError(true)
        }
        setPasswordVerifying(false)
    }

    const handlePostRefresh = (name) => {
        const tmp = updated;
        tmp[name] = true;

        setUpdated({...tmp})

    }

    const handleRefresh = (name) => {
        const tmp = updated;
        tmp[name] = false;

        setUpdated({...tmp})
    }

    return (
        <div>

            <SubscriptionUpdateConfirmationModal
                open={subConfirmDialogOpen}
                onClose={() => setSubConfirmDialogOpen(false)}
                onSubmit={handleSaveSub}
                update={updatedSub}
                user={user}
            />

            <RequestNotification
                status={requestStatus}
                text={actionPending ? requestText["loading"] : requestText[requestStatus]}
                open={requestDialogOpen}
                setOpen={setRequestDialogOpen}
                loading={actionPending}
            />

            <ConfirmationModal
                open={deleteDialogOpen}
                title={`Are you sure you want to delete this subscription?`}
                text={`Access to ADUROI evaluations will immediately be revoked after termination. If you still wish to continue, enter your password below.`}
                setOpen={setDeleteDialogOpen}
                handleConfirm={handlePasswordVerify}
                disabled={passwordVerifying}
            >
                <ViewablePasswordField
                    value={confirmField}
                    onChange={(e) => setConfirmField(e.target.value)}
                    fullWidth
                    placeholder="Your Password"
                    className={classes.infoSection}
                    error={passwordError}
                    helperText={passwordError ? "The entered password is incorrect." : ""}

                />
            </ConfirmationModal>


            <NavigationPrompt when={dialogOpen || createDialogOpen} afterCancel={() => console.log("cancel")} afterConfirm={() => console.log("confirm")}>
                {({ onConfirm, onCancel }) => (
                    <ConfirmationModal
                        open={true}
                        setOpen={onCancel}
                        handleConfirm={onConfirm}
                        text="Changes to your subscription are unsaved and will be lost. Are you sure you want to leave this page?"
                    />
                )}
            </NavigationPrompt>

            <SlidePanel
                open={dialogOpen}
                handleClose={handleOpenDialog}
                style={{
                    padding: "4rem 2rem 2rem 6rem",
                }}
            >
                <SubscriptionForm
                    subscription={selectedSub}
                    customer_id={user.customer_id}
                    onSubmit={(data) => handleVerifySub(data)}
                />

            </SlidePanel>

            <SlidePanel
                open={createDialogOpen}
                handleClose={() => setCreateDialogOpen(false)}
                style={{
                    padding: "4rem 2rem 2rem 6rem",
                }}
            >
                <NewSubscriptionForm
                    customer_id={user.customer_id}
                    onSubmit={(data) => handleCreateSub(data)
                    }
                    user={user}
                />

            </SlidePanel>

            <div className={classes.section}>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div>
                        {
                            !isSubscribed ?

                                <Button
                                    onClick={!createDialogOpen ? () => setCreateDialogOpen(true) : null}
                                    disabled={actionPending || createDialogOpen}
                                    className={classes.button}
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                >
                                    Add&nbsp;<i className="fas fa-cart-plus fa-xs" />
                                </Button>

                                :

                                <>
                                    <Button
                                        onClick={selectedSub.id ? handleOpenDialog : null}
                                        disabled={!selectedSub.id}
                                        className={classes.button}
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Update&nbsp;<i className="fas fa-wrench fa-xs" />
                                    </Button>
                                    <Button
                                        onClick={selectedSub.id ? () => setDeleteDialogOpen(true) : null}
                                        disabled={!selectedSub.id}
                                        className={classes.button}
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                    >
                                        Delete&nbsp;<i className="far fa-trash-alt fa-xs" />
                                    </Button>

                                </>
                        }

                    </div>

                </div>

                <Typography variant="overline" component="h5" style={{ fontSize: "1.3rem" }}>Subscriptions</Typography>


                <UserSubscriptions
                    selected={selectedSub.id}
                    customer_id={user.customer_id}
                    onChange={(data) => handleSelectChange(data)}
                    updated={updated["subs"]}
                    onLoaded={() => handlePostRefresh("subs")}
                />

            </div>

        </div>
    )
}


