import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./AduView.css";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TrendingUpOutlined from '@material-ui/icons/TrendingUpOutlined';

import styled from "styled-components";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import Loading from "../../components/Loading"
import QuestionIcon from '@material-ui/icons/HelpOutline';
import { getEvaluation, deleteEvaluation } from "../../libs/awsLib"




const yearIncrements = [5, 10, 15, 20, 25, 30];

const useStyles = makeStyles({
  root: {
    display: "flex",
    margin: "2rem 0"
  },
  centered: {
    margin: "auto",
    maxWidth: "1000px",
    width: "90%"
  },
  table: {
    minWidth: 650
  },
  tableHeader: {
    fontWeight: "bold",
    color: "black",
    minWidth: "350px"

  },
  tableHead: {
    backgroundColor: "#2ddaa2",
    color: "white"
  },
  button: {
    margin: "1rem 0.5rem",
    textDecoration: "none"
  }
});

function createData() {
  return { ...arguments };
}

const commaListToArray = string => {
  return string.split(",")
}


export default function Adus(props) {

  const { user } = props;

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState("");
  const [iRRs, setIRRs] = useState([]);
  const [cashGains, setCashGains] = useState([]);
  const [cOcReturns, setCOcReturns] = useState([]);
  const [CFinYears, setCFinYears] = useState([]);
  const [CuCFexPurchs, setCuCFexPurchs] = useState([]);
  const [CuCFincPurchs, setCuCFincPurchs] = useState([]);
  const [taxIncs, setTaxIncs] = useState([]);
  const [cashGainAduScenarios, setCashGainAduScenarios] = useState([]);
  const [cashGainNonaduScenarios, setCashGainNonaduScenarios] = useState([]);
  const [cashGainAduBetters, setCashGainAduBetters] = useState([]);
  const [maxCashAduScenarios, setMaxCashAduScenarios] = useState([]);
  const [maxCashNonaduScenarios, setMaxCashNonaduScenarios] = useState([]);
  const [maxCashAduBetters, setMaxCashAduBetters] = useState([]);
  const [cfInYearAduScenarios, setCfInYearAduScenarios] = useState([]);
  const [cfInYearNonaduScenarios, setCfInYearNonaduScenarios] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const purchaseYear = new Date(notes.purchaseDate).getFullYear();

  const data = {
    labels: notes.graphLabels ? commaListToArray(notes.graphLabels) : [],
    datasets: [{
      type: 'bar',
      label: "With ADU",
      data: notes.graphADUdata ? commaListToArray(notes.graphADUdata) : [],
      fill: false,
      backgroundColor: '#2ddaa2',
      borderColor: '#2ddaa2',
      //yAxisID: 'y-axis-1'
    }, {
      label: "Without ADU",
      type: 'bar',
      data: notes.graphNoADUdata ? commaListToArray(notes.graphNoADUdata) : [],
      fill: false,
      backgroundColor: 'rgb(128,128,128)',
      borderColor: 'rgb(128,128,128)',
      //yAxisID: 'y-axis-2'
    }]
  }

  useEffect(() => {

    async function onLoad() {
      try {
        const adu = await getEvaluation(props.match.params.id, user.user_id);
        console.log(adu)
        setNotes(adu);
        callingFn(adu);

        setIsLoading(false)

        // console.log("notes", notes);
      } catch (e) {
        alert(e);
      }
    }

    // console.log("notes", notes);

    async function callingFn(notes) {
      try {
        setIRRs(yearSplitIntoNumbersDecimals(notes.iRRs));
        setCashGains(yearSplitIntoNumbers(notes.cashGains));
        setCOcReturns(yearSplitIntoNumbersTwoDec(notes.cOcReturns));
        setCFinYears(yearSplitIntoNumbers(notes.CFinYears));
        setCuCFexPurchs(yearSplitIntoNumbers(notes.CuCFexPurchs));
        setCuCFincPurchs(yearSplitIntoNumbers(notes.CuCFincPurchs));
        setTaxIncs(yearSplitIntoNumbers(notes.taxIncs));
        setCashGainAduScenarios(
          yearSplitIntoNumbers(notes.cashGainAduScenarios)
        );
        setCashGainNonaduScenarios(
          yearSplitIntoNumbers(notes.cashGainNonaduScenarios)
        );
        setCashGainAduBetters(yearSplitIntoNumbers(notes.cashGainAduBetters));
        setMaxCashAduScenarios(yearSplitIntoNumbers(notes.maxCashAduScenarios));
        setMaxCashNonaduScenarios(
          yearSplitIntoNumbers(notes.maxCashNonaduScenarios)
        );
        setMaxCashAduBetters(yearSplitIntoNumbers(notes.maxCashAduBetters));
        setCfInYearAduScenarios(
          yearSplitIntoNumbers(notes.cfInYearAduScenarios)
        );
        setCfInYearNonaduScenarios(
          yearSplitIntoNumbers(notes.cfInYearNonaduScenarios)
        );
        // setAdu(summary);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    onLoad();
  }, [props.match.params.id]);


  const yearSplitIntoNumbersDecimals = string => {
    let summaryString = string.split(",");
    const numArray = summaryString.map(value => {
      let numValue = parseInt(value * 100);
      return numValue + "%";
    });
    return numArray;
  };

  const yearSplitIntoNumbers = string => {
    let summaryString = string.split(",");
    const numArray = summaryString.map(value => {
      let isNegative = false;
      let num = value;

      if (value.includes("-")) {
        isNegative = true;
        num = value.split("-")[1];
      }

      const parsedNum = Number(parseInt(num)).toLocaleString('en');
      let numValue = `${isNegative ? "(" : ""}$${parsedNum}${isNegative ? ")" : ""}`;
      return numValue;
    });
    return numArray;
  };

  const yearSplitIntoNumbersTwoDec = string => {
    let summaryString = string.split(",");
    const numArray = summaryString.map(value => {
      let isNegative = false;
      let num = value;

      if (value.includes("-")) {
        isNegative = true;
        num = value.split("-")[1];
      } const parsedNum = Number(parseFloat(num).toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: 2
      });
      let numValue = `${isNegative ? "-" : ""} $${parsedNum}`;
      return numValue;
    });
    return numArray;
  };


  const createCashFlowAnalysisData = (text, tip) => {
    if (text) {
      return { label: text.split(":")[0], value: text.split(":")[1], tip }
    }
    else return ""
  }

  const cashFlowAnalysis = [
    createCashFlowAnalysisData(
      notes.capRateText,
      "This is your ROI ignoring financing. Calculated by taking steady-state Net Operating Income (NOI) divided by purchase price, construction and renovations."
    ),
    createCashFlowAnalysisData(
      notes.priceToRentText,
      `Relevant only if purchaser not living in one of the units. Generally,
      1x-15x means much better to buy than rent, 16x-21x renting better,
      over 21x much better renting than buying. Takes purchase price +
      construction and renovation and divides by annual rental income.`
    ),
    createCashFlowAnalysisData(
      notes.noiMaxCashText,
      `This approximates your pre-financing-costs return on the cash you have
    invested.`
    ),
    createCashFlowAnalysisData(
      notes.rentByCashInvestText,
      `Very rough indication of Return on Investment ignoring any price
    appreciation in the property, expenses, and financing costs.`
    ),
    createCashFlowAnalysisData(
      notes.maxCashText,
      `This is the maximum cash you have invested in the property, including
    the principal payment portion of loan payments, before you begin
    receiving positive cash flow.`
    ),
    createCashFlowAnalysisData(
      notes.firstCFposText,
      ` Date at which you start to receive net positive annual income (e.g.
      when annual rents exceed all expenses and principal portions of loan
      payments).`
    ),
    createCashFlowAnalysisData(
      notes.yearsToCFposText,
      `Years from date of property purchase until above date.`
    ),
    createCashFlowAnalysisData(
      notes.firstCFposExPrincText,
      `When does rent from your tenants cover expenses and start to help
    contribute to the principal portion of your mortgage and construction
    loan payments.`
    ),
    createCashFlowAnalysisData(
      notes.yearsToCFposExPrincText,
      `Years from date of property purchase until above date.`
    ),
    createCashFlowAnalysisData(
      notes.netCashGreatUsedExPrincText,
      ` The date at which all cumulative rental payments less cumulative
    expenses (except the principal portion of loans) is greater than the
    total cash you invested. In other words, this is when your tenants
    have paid you back what you invested in the property (except the
    portion of principal you may have paid on loan payments).`
    ),
    createCashFlowAnalysisData(
      notes.netCashGreatUsedPrincText,
      ` The date at which all cumulative rental payments less cumulative
    expenses is greater than the total cash you invested. In other words,
    this is when your tenants have paid you back what you invested in the
    property.`
    )
  ];

  const firstRows = [

    createData("Returns (if sold in that specific year) (after tax)"),
    createData("IRR", ...iRRs),
    createData("Total Cash Gain", ...cashGains),
    createData("Cash on Cash Return", ...cOcReturns),
    createData("Cash Flow and Income (assumes property not sold)"),
    createData("Cash Flow in Year", ...CFinYears),
    createData("Cumulative Cash Flow (excl purchase and construction)", ...CuCFexPurchs),
    createData("Cumulative Cash Flow (incl purchase and construction)", ...CuCFincPurchs),
    createData("Taxable Income in Year", ...taxIncs),
  ];

  const secondRows = [
    createData("Cash Gain (Used).  Assumes property sold in year."),
    createData("ADU Scenario", ...cashGainAduScenarios),
    createData("Non-ADU Primary Residence Scenario", ...cashGainNonaduScenarios),
    createData("ADU is Better (Worse) by:", ...cashGainAduBetters),
    createData("Maximum Cash Required"),
    createData("ADU Scenario", ...maxCashAduScenarios),
    createData("Non-ADU Primary Residence Scenario", ...maxCashNonaduScenarios),
    createData("ADU is Better (Worse) by:", ...maxCashAduBetters),
    createData("Cash Flow In Year (excludes purchase and rennovations and assumes property not sold)"),
    createData("ADU Scenario", ...cfInYearAduScenarios),
    createData("Non-ADU Primary Residence Scenario", ...cfInYearNonaduScenarios),
  ];

  const lastRows = [
    createData("Initial Property Taxes", notes.initialPropertyTaxes),
    createData("Initial Property Insurance", notes.initialPropertyInsurance),
    createData("Maintenance", notes.maintenance),
    createData("Capitalization Rate (Cap Rate)", notes.capRate),
    createData("Price to Rent Ratio", notes.priceToRentRatio),
    createData(
      "NOI divided by max cash invested",
      notes.noiDividedByMaxCashInvested
    ),
    createData(
      "Annual rent divided by cash invested",
      notes.rentDivByCashInvested
    ),
    createData("Maximum Cash Used", notes.maxCash),
    createData("First Year of Positive Cash Flow", notes.firstCFpos),
    createData("Years to Cash Flow Positive", notes.yearsToCFpos),
    createData(
      "First Year of Cash Flow Positive (excl Principal)",
      notes.firstCFposExPrinc
    ),
    createData(
      " Years to Cash Flow Positive (excl Principal payments)",
      notes.yearsToCFposExPrinc
    ),
    createData(
      `Year when net cash received (plus Princ portion of payment) > cash
    used (excl principal pmts)`,
      notes.netCashGreatUsedExPrinc
    ),
    createData(
      `Year when net cash received (plus Princ portion of payment) > cash
    used`,
      notes.netCashGreatUsedPrinc
    ),
    createData("Gross Rental Yield", notes.grossRentalYield)
  ];


  async function handleDelete() {
    setIsDeleting(true)
    await deleteEvaluation(props.match.params.id, user.user_id);

    setIsDeleting(false)
    setDeleteDialogOpen(false)
    props.history.replace("/")
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => { if (!isDeleting) setDeleteDialogOpen(false) }}
      >
        <DialogTitle>{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this evaluation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={isDeleting} onClick={() => setDeleteDialogOpen(false)} color="default">
            Cancel
          </Button>
          <Button disabled={isDeleting} onClick={handleDelete} color="secondary" autoFocus>
            Delete&nbsp;{isDeleting ? <i className="fas fa-spinner fa-spin"></i> : ""}
          </Button>
        </DialogActions>
      </Dialog>
      {
        isLoading ?
          <Loading />
          :
          <div className={classes.centered}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link to={`/`} >
                <Tooltip title="Back to All Evaluations">
                  <IconButton className={classes.button} variant="outlined">
                    <i className="fas fa-long-arrow-alt-left fa-xs" />
                  </IconButton>
                </Tooltip>
              </Link>
              <div>

                <Link to={`/adu/${notes.noteId}`} >
                  <Tooltip title="Edit Evaluation">
                    <IconButton className={classes.button} variant="contained" color="primary">
                      <i className="fas fa-edit fa-xs" />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Tooltip title="Delete Evaluation">
                  <IconButton onClick={() => setDeleteDialogOpen(true)} className={classes.button} variant="contained" color="secondary">
                    <i className="far fa-trash-alt fa-xs"></i>
                  </IconButton>
                </Tooltip>

              </div>

            </div>
            <Typography align="center" variant={"h4"}>{notes["propertyNickname"]} <Typography color="textSecondary" variant={"h4"} component="span">| Investment Analysis Summary</Typography> </Typography>
            <div style={{ width: "100%" }}>
              <Bar
                data={data}
                options={{
                  scales: {
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Cash Gain if Sold in Year'
                      },
                      ticks: {
                        callback: function (value, index, values) {
                          return value.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 });
                        }
                      }
                    }]
                  },
                  tooltips: {
                    callbacks: {
                      label: function (tooltipItem, data) {
                        var tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return parseInt(tooltipValue).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 });
                      }
                    }
                  }
                }}
              />
            </div>

            {/* Key Inputs & Assumptions */}
            <Typography style={{ margin: "1rem 0" }} variant="h4" color="primary">Summary</Typography>
            <List>
              {Object.keys(notes).map((field, idx) => {
                if (field.includes("assump")) return (
                  <ListItem key={field + idx}>
                    <ListItemIcon>
                      <TrendingUpOutlined style={{ color: "#2ddaa2" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={notes[field]}
                      primaryTypographyProps={{ variant: "subtitle2" }}
                    />
                  </ListItem>
                )
              })}

            </List>

            {/* Cash Flow Analysis */}
            <Typography style={{ margin: "1rem 0" }} variant="h4" color="primary">Key Metrics</Typography>

            {cashFlowAnalysis.map((row, i) => (
              <ExpansionPanel key={row.label + i}>
                <ExpansionPanelSummary
                  expandIcon={<QuestionIcon />}
                  aria-controls="general-content"
                >
                  <Typography variant={"subtitle1"}><span style={{ fontWeight: "bold" }}>{row.label}:</span>&nbsp;<span>{row.value}</span></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: "block" }}>
                  {row.tip}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}

            {/* Returns and Cash Flow Analysis */}
            <Typography style={{ margin: "1rem 0" }} variant="h4" color="primary">Returns and Cash Flow Analysis</Typography>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={6} component="th" scope="row" className={classes.tableHead}>Years from initial purchase</TableCell>
                        {yearIncrements.map((item, idx) =>
                          <TableCell className={classes.tableHead} key={item + idx} align="center">{item} ({purchaseYear + item})</TableCell>
                        )}

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {firstRows.map((row, i) => {
                        const isHeader = Object.keys(row).length === 1;

                        return (
                          <TableRow hover key={row + i}>
                            <TableCell colSpan={6} className={isHeader ? classes.tableHeader : null} component="th" scope="row">
                              {row[0]}
                            </TableCell>
                            {

                              isHeader ?

                                yearIncrements.map((item, idx) =>
                                  <TableCell key={item + idx} align="right">
                                  </TableCell>
                                )
                                :
                                Object.keys(row).slice(1).map((label, idx) => (
                                  <TableCell key={label + idx} align="right">{row[label]}
                                  </TableCell>
                                ))

                            }

                          </TableRow>
                        )
                      })}
                    </TableBody>
              </Table>
            </TableContainer>
                <br />
                {/* Adding ADU vs. Living in Home as Primary Residence */}
                <Typography style={{ margin: "1rem 0" }} variant="h4" color="primary">Adding ADU vs. Living in Home as Primary Residence</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={6} className={classes.tableHead}>Years from initial purchase</TableCell>
                        {yearIncrements.map((item, idx) =>
                          <TableCell className={classes.tableHead} key={item + idx} align="center">{item} ({purchaseYear + item})</TableCell>

                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {secondRows.map((row, i) => {
                        const isHeader = Object.keys(row).length === 1;

                        return (
                          <TableRow hover key={row + i}>
                            <TableCell colSpan={6} className={isHeader ? classes.tableHeader : null} component="th" scope="row">
                              {row[0]}
                            </TableCell>
                            {

                              isHeader ?

                                yearIncrements.map((item, idx) =>
                                  <TableCell key={item + idx} align="right">
                                  </TableCell>
                                )
                                :
                                Object.keys(row).slice(1).map((label, idx) => (
                                  <TableCell key={label + idx} align="right">{row[label]}
                                  </TableCell>
                                ))

                            }

                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Adding ADU vs. Living in Home as Primary Residence */}
                {/* 
                <Typography style={{ margin: "1rem 0" }} variant="h4" color="primary">Adding ADU vs. Living in Home as Primary Residence</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={6} className={classes.tableHead}>Item</TableCell>
                        <TableCell className={classes.tableHead}>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lastRows.map((row, i) => {
                        const isHeader = Object.keys(row).length === 1;

                        return (
                          <TableRow hover key={row + i}>
                            <TableCell colSpan={6} className={isHeader ? classes.tableHeader : null} component="th" scope="row">
                              {row[0]}
                            </TableCell>
                            {

                              Object.keys(row).slice(1).map((label, idx) => (
                                <TableCell key={label + idx}>{row[label]}
                                </TableCell>
                              ))

                            }

                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              */}
                {/*
            <TableContainer component={Paper}>
              <Table size="small"  className={classes.table} aria-label="simple table">
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
               
                <TableBody>
                  {lastRows.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {row.year}
                      </TableCell>
                      <TableCell align="right">{row.five}</TableCell>
                      <TableCell align="right">{row.ten}</TableCell>
                      <TableCell align="right">{row.fifteen}</TableCell>
                      <TableCell align="right">{row.twenty}</TableCell>
                      <TableCell align="right">{row.twentyFive}</TableCell>
                      <TableCell align="right">{row.thirty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            */}

Note: This information is intended to help you successfully make informed investment decisions but please note it relies on assumptions and may contain errors and you should not rely solely on this for your investment decisions.
          </div>
      }
    </div>
  );
}
