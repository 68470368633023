import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import LoadingMenu from "./LoadingMenu"
import { getUserPayments, getCustomer } from '../libs/awsLib';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

UserPaymentMethods.propTypes = {
  onChange: PropTypes.func.isRequired,
  customer_id: PropTypes.string.isRequired,
  selected: PropTypes.string,
  updated: PropTypes.bool,
  onLoaded: PropTypes.func
}

export default function UserPaymentMethods(props) {
  const { onChange, customer_id, selected, updated, onLoaded } = props;

  const classes = useStyles();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setLoading] = useState(true)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(selected);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState();

  const handlePaymentMethodClick = (paymentMethod) => {

    const id = paymentMethod.id;

    if (selectedPaymentMethod === id) {
      setSelectedPaymentMethod(null);
      onChange(null)
    } else {
      setSelectedPaymentMethod(id);
      onChange(paymentMethod)

    }
  };

  async function loadCustomer(customer_id) {
    const customer = await getCustomer(customer_id)
    setDefaultPaymentMethod(customer.default_source)
    if (!selectedPaymentMethod) setSelectedPaymentMethod(customer.default_source)
  }

  async function getData() {
    try {
      setLoading(true);

      const { data: paymentMethods } = await getUserPayments(customer_id);

      setPaymentMethods(paymentMethods)

      await loadCustomer(customer_id);

      setLoading(false);
      if(onLoaded) onLoaded()

    } catch (e) {
      console.log(e)
    }
  }
  
  useEffect(() => {
    getData();

  }, [customer_id])

  useEffect(() => {
    if(!updated) getData();

  }, [updated])


  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            All Saved Payment Methods
        </ListSubheader>
        }
        className={classes.root}
      >
        {
          isLoading ?

            <LoadingMenu />

            :

            paymentMethods.length === 0 ?
              <>
                {
                  paymentMethods.length === 0 && (
                    <div style={{ width: "100%", display: "flex", margin: "3rem 0" }}>
                      <div style={{ margin: "auto" }}>
                        No saved payment methods found.
                  </div>
                    </div>
                  )
                }
              </>
              :

              <>
                {
                  paymentMethods.map((method, idx) => (
                    <span key={idx}>
                      <ListItem
                        selected={selectedPaymentMethod === method.id}
                        button
                        onClick={() =>
                          handlePaymentMethodClick(method)
                        }>
                        <ListItemIcon>
                          <i className={`fab fa-cc-${method.card.brand.toLowerCase().split(" ").join("-")}`} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <>
                              <div>
                                ••••••••••••{method.card.last4}
                              </div>

                            </>
                          }
                          secondary={
                            <>
                              {method.card.funding.charAt(0).toUpperCase().concat(method.card.funding.slice(1))} | Expires {method.card.exp_month} / {method.card.exp_year}
                            </>
                          }
                        />
                        {
                          defaultPaymentMethod === method.id &&
                          <Typography variant="overline" color="primary">
                            Default
                          </Typography>
                        }
                      </ListItem>
                    </span>
                  ))
                }
              </>
        }


      </List>
    </>
  );
}