import React, { useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchBar from "../../components/SearchBar";

import { Link } from "react-router-dom";
import "./Home.css";
import { getUserEvaluations } from "../../libs/awsLib";


export default function Home(props) {
  const [adus, setAdus] = useState([]);
  const [filteredAdus, setFilteredAdus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }

      try {
        const adus = await getUserEvaluations(props.user.user_id);
        adus.sort((a, b) => b.createdAt - a.createdAt);
        setAdus(adus);
        setFilteredAdus(adus);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function renderAdusList(adus) {
    return [{}].concat(adus).map((adu, i) =>
      i !== 0 ? (
        <ListItem key={adu + i} style={{ padding: "0.5rem 1rem 0.5 2rem" }} button onClick={() => props.history.push(`/view/${adu.noteId}`)}>
          <ListItemIcon>
            <i className="fas fa-chart-line" style={{ color: "#2ddaa2" }} />
          </ListItemIcon>
          <ListItemText
            primary={
              adu.propertyNickname
            }
            secondary={
              "Created: " + new Date(adu.createdAt).toLocaleString()
            }
          />
        </ListItem>
      ) : (
          <ListItem key={adu + i} style={{ padding: "0.5rem 1rem" }} button onClick={() => props.history.push("/adu/new")}>
            <ListItemText
              primary={
                <Typography align="center">
                  <i style={{ color: "#2ddaa2" }} className="fas fa-plus" /> &nbsp;Add a new ADU Evaluation
                </Typography>
              }
            />
          </ListItem>
        )
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <Typography variant="h4">ADUROI</Typography>
        <Typography variant="overline" color="textSecondary">Evaluate adding an ADU to a property.</Typography>
        <div>
          <span>
            <Link to="/signup">
              <Tooltip title="Sign Up">
                <IconButton>
                  <i style={{color: "#2ddaa2"}} className="fas fa-user-plus"></i>
                </IconButton>
              </Tooltip>
            </Link>
            <Link to="/login">
              <Tooltip title="Login">

                <IconButton>
                  <i className="fas fa-sign-in-alt"></i>
                </IconButton>
              </Tooltip>
            </Link>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="Home">
      {props.isAuthenticated ?
        <>
          <div className="adus" style={{ display: "flex", margin: "2rem 0" }}>
            <Paper style={{ width: "95%", maxWidth: "800px", margin: "auto", padding: "0 1.5rem" }} elevation={10}>
              <Typography style={{ fontSize: "1.4rem", padding: "1rem 0" }} align="center" component="h4" variant="overline">Your Properties</Typography>
              <div style={{ padding: "0 1.5rem" }}>
                <SearchBar
                  items={adus}
                  label={"Search Evaluations"}
                  onChange={(items) => setFilteredAdus(items)}
                  filter={(item, query) => {
                    const q = query.toLowerCase();

                    const ln = item.propertyNickname.toLowerCase();

                    if (ln.includes(q)) return true
                    else return false
                  }}
                />

              </div>
              <List
              >
                {!isLoading && renderAdusList(filteredAdus)}
              </List>
            </Paper>
          </div>
        </>

        :

        renderLander()}
    </div>
  );
}
