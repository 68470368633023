import React from "react";
import { TextField, Radios, Select, DatePicker } from "mui-rff";
import {
    MenuItem
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import PercentField from "../components/PercentField";
import CurrencyField from "../components/CurrencyField";

export default {
    general: [
        {
            size: {xs: 12, sm: 6},
            field: (
                <TextField
                    inputRef={input => input && input.focus()}
                    label="Property Nickname"
                    name="propertyNickname"
                    type="text"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <CurrencyField
                    label="Purchase Price"
                    name="purchasePrice"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <PercentField
                    label="Down Payment %"
                    name="downPayment"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <PercentField
                    label="Mortgage APR %"
                    name="mortgageAPR"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <CurrencyField
                    label="Construction Costs"
                    name="constructionCosts"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <Radios
                    label="Construction Funded Via Loan?"
                    name="constructionFundedViaLoan"
                    formControlProps={{ margin: "none" }}
                    radioGroupProps={{ row: true }}
                    data={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" }
                    ]}
                />
            )
        },
        {
            size: {xs: 12, sm: 6, md: 3},
            field: (
                <CurrencyField
                    label="Unit 1 Monthly Rent"
                    name="unit1MonthlyRent"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6, md: 3},
            field: (
                <CurrencyField
                    label="Unit 2 Monthly Rent"
                    name="unit2MonthlyRent"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6, md: 3},
            field: (
                <CurrencyField
                    label="Unit 3 Monthly Rent"
                    name="unit3MonthlyRent"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6, md: 3},
            field: (
                <CurrencyField
                    label="Unit 4 Monthly Rent"
                    name="unit4MonthlyRent"
                    margin="none"
                    required={true}
                />
            )
        },
    ],
    propertyLoanDetails: [
        {
            size: {xs: 12, sm: 6},
            field: (
                <PercentField
                    label="Annual Increase In Property Value"
                    name="annualIncreaseInPropertyValue"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <DatePicker
                    name="purchaseDate"
                    label="Purchase Date"
                    dateFunsUtils={DateFnsUtils}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <Select
                    name="mortgageTerm"
                    label="Mortgage Term"
                    formControlProps={{ margin: "none" }}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                </Select>
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <CurrencyField
                    label="Closing Costs"
                    name="closingCosts"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <PercentField
                    label="Selling Costs"
                    name="sellingCosts"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <TextField label="Address" name="address" type="text" margin="none" />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <TextField label="URL" name="propertyURL" type="text" margin="none"/>
            )
        },
    ],
    rentalDetails: [
        {
            size: {xs: 12, sm: 6},
            field: (
                <PercentField
                    label="Rent Increase Annually"
                    name="rentIncreaseAnnually"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6, md: 3},
            field: (
                <TextField
                    label="Unit 1 First Rent Month(month after purchase)"
                    name="unit1FirstRentMonth"
                    type="number"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6, md: 3},
            field: (
                <TextField
                    label="Unit 2 First Rent Month(month after purchase)"
                    name="unit2FirstRentMonth"
                    type="number"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6, md: 3},
            field: (
                <TextField
                    label="Unit 3 First Rent Month(month after purchase)"
                    name="unit3FirstRentMonth"
                    type="number"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6, md: 3},
            field: (
                <TextField
                    label="Unit 4 First Rent Month(month after purchase)"
                    name="unit4FirstRentMonth"
                    type="number"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <PercentField
                    label="Vacancy and Property Management Fees"
                    name="vacancyandPropertyManagementFees"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <CurrencyField
                    label="Utilities/gardener/HOA"
                    name="utilitiesGardenerHoa"
                    margin="none"
                    required={true}
                />
            )
        },
    ],
    constructionRemodelingDetails: [
        {
            size: {xs: 12, sm: 6},
            field: (
                <TextField
                    label="Months of Construction"
                    name="monthsofConstruction"
                    type="number"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <TextField
                    label="Construction Start Month"
                    name="constructionStartMonth"
                    type="number"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <PercentField
                    label="Construction Loan APR"
                    name="constructionLoanAPR"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <Select
                    name="contructionLoanTerm"
                    label="Contruction Loan Term"
                    formControlProps={{ margin: "none" }}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                </Select>
            )
        },
    ],
    taxDetails: [
        {
            size: {xs: 12, sm: 6},
            field: (
                <Radios
                    label="Real Estate Professional designation for tax?"
                    name="realEstateProfessional"
                    formControlProps={{ margin: "none" }}
                    radioGroupProps={{ row: true }}
                    data={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" }
                    ]}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <PercentField
                    label="Income tax rate (non-cap gains)"
                    name="incomeRaxRate"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <PercentField
                    label="Cap Gains Tax Rate"
                    name="capGainsTaxRate"
                    margin="none"
                    required={true}
                />
            )
        },
        {
            size: {xs: 12, sm: 6},
            field: (
                <Radios
                    label="Filing Single or Jointly"
                    name="filingSingleorJointly"
                    formControlProps={{ margin: "none" }}
                    radioGroupProps={{ row: true }}
                    data={[
                        { label: "Single", value: "Single" },
                        { label: "Jointly", value: "Jointly" }
                    ]}
                />
            )
        },
    ],
}
