import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import Home from "./views/Home";
import Login from "./views/Login";
import Signup from "./views/Signup";
import NotFound from "./views/NotFound";
import NewAdu from "./views/NewAdu/NewAdu";
import AduView from "./views/AduView";
import Settings from "./views/Settings";
import Invite from "./views/Invite";
import SubscribedRoute from "./components/SubscribedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import InactiveClientRoute from "./components/InactiveClientRoute";
import Adus from "./views/Adus";
import AduSummary from "./views/Summary";
import Upgrade from "./views/Upgrade";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/signup"
        exact
        component={Signup}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/invite"
        exact
        component={Invite}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/settings"
        // exact
        component={Settings}
        appProps={appProps}
      />
      <SubscribedRoute
        path="/adu/new"
        exact
        component={NewAdu}
        appProps={appProps}
      />
      <SubscribedRoute
        path="/adu/summary"
        exact
        component={AduSummary}
        appProps={appProps}
      />
      <SubscribedRoute
        path="/adu/:id"
        exact
        component={Adus}
        appProps={appProps}
      />
      <SubscribedRoute
        path="/view/:id"
        exact
        component={AduView}
        appProps={appProps}
      />
      <InactiveClientRoute
        path="/upgrade"
        exact
        component={Upgrade}
        appProps={appProps}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
