import React, { useState } from "react";
import LoaderButton from "../../components/LoaderButton";
import AduForm from "../../components/AduForm";
import Loading from "../../components/Loading";
import "./NewAdu.css";
import { API } from "aws-amplify";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  IconButton,
  Tooltip,
  Button
} from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles"
import { getSheetData, createEvaluation, postSheetData } from "../../libs/awsLib"

function createAdu(data) {
  return API.post("notes", "/notes", {
    body: data
  });
}
const today = new Date();
const closedate = new Date(today.setDate( today.getDate() + 30 ));
const useStyles = makeStyles({
  button: {
    margin: "1rem 0.5rem",
    textDecoration: "none"
  },
  root: {
    margin: "2rem 0"
  }
})

const formData = {
  propertyNickname: "",
  purchasePrice: 750000,
  purchaseDate: closedate,
  downPayment: .2,
  mortgageAPR: .04,
  mortgageTerm: 30,
  closingCosts: 5000,
  rentIncreaseAnnually: .03,
  unit1MonthlyRent: 0,
  unit2MonthlyRent: 2000,
  unit3MonthlyRent: 0,
  unit4MonthlyRent: 0,
  unit1FirstRentMonth: 1,
  unit2FirstRentMonth: 6,
  unit3FirstRentMonth: 1,
  unit4FirstRentMonth: 1,
  constructionCosts: 150000,
  monthsofConstruction: 6,
  constructionStartMonth: 1,
  constructionFundedViaLoan: "Yes",
  constructionLoanAPR: .05,
  contructionLoanTerm: 10,
  annualIncreaseInPropertyValue: .03,
  sellingCosts: .07,
  utilitiesGardenerHoa: 0,
  vacancyandPropertyManagementFees: .10,
  realEstateProfessional: "No",
  incomeRaxRate: .35,
  capGainsTaxRate: .20,
  filingSingleorJointly: "Single"
};

export default function NewAdu(props) {

  const { user } = props;

  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles()
  // const [tableData, setTableData] = useState({});

  const onSubmit = async values => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    setIsLoading(true);
    await sleep(300);
    const data = {
      body: values
    };
    // axios
    //   .post(
    //     "https://2htewwo86g.execute-api.us-west-2.amazonaws.com/google-sheet/google-sheets",
    //     JSON.stringify(data)
    //   )
    console.log(values)
    postSheetData(data)
      .then(async function (response) {
        console.log(response)

        let sumData = await callingFn();
        console.log(sumData)
        const newTableData = { ...sumData, ...values };
        // setTableData(newTableData);
        return newTableData;
      })
      .then(async function (response) {
        await createEvaluation(response, user.user_id);
        await sleep(3000);
        props.history.push("/");
        return response;
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  async function callingFn() {
    // let res = await axios.get(
    //   "https://2htewwo86g.execute-api.us-west-2.amazonaws.com/google-sheet/google-sheets"
    // );
    let res = await getSheetData();

    // setTableData(data);
    return res;

  }

  return (
    <div className={classes.root}>
      {
        isLoading ?
          <Loading text="Creating..." />
          :
          <AduForm
            onSubmit={onSubmit}
            initialValues={formData}
            ActionButtons={
              ({ submitting }) =>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Link to={`/`} >
                    <Tooltip title="Back to All Evaluations">
                      <IconButton className={classes.button} variant="outlined">
                        <i className="fas fa-long-arrow-alt-left fa-xs" />
                      </IconButton>
                    </Tooltip>
                  </Link>
                  <div>

                    <Tooltip title="Save Evaluation">
                      <IconButton type="submit" className={classes.button} variant="contained" color="primary">
                        <i className="fas fa-save fa-xs" />
                      </IconButton>
                    </Tooltip>

                  </div>

                </div>
            }
          />
      }


    </div>
  );
}
