// import React, { useState } from "react";
// import { API } from "aws-amplify";
// import { Elements, StripeProvider } from "react-stripe-elements";
// import config from "../../config";

// export default function Settings(props) {

//   return (
//     <div>
//       {/* <StripeProvider apiKey={config.STRIPE_KEY}>
//         <Elements>




//         </Elements>
//       </StripeProvider> */}
//     </div>
//   );
// }

import React from 'react';
import clsx from 'clsx';
import { Switch, Redirect, NavLink } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ToolTip from '@material-ui/core/Tooltip';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AuthenticatedRoute from "../../components/AuthenticatedRoute";
import UserSettings from "../../components/UserSettings";
import BillingSettings from "../../components/BillingSettings";
import InviteSettings from "../../components/InviteSettings";
import SubscriptionSettings from "../../components/SubscriptionSettings";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import "./main.css"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPosition: {
    top: "auto",
    zIndex: 1000
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  container: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: '15px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: "73px",
    },
  },
  content: {
    padding: "1rem"
  },
  buttonLink: {
    color: "inherit",
    textDecoration: "none"
  }
}));

export default function Settings(props) {
  const { match, location, user } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const defaultRoute = `${match.path}/user`;

  const pages = [
    {
      path: `${match.url}/user`,
      component: UserSettings,
      label: "User Info",
      icon: <AccountCircleIcon />,
    },
    {
      path: `${match.url}/invites`,
      component: InviteSettings,
      label: "Client invites",
      icon: <SupervisedUserCircleIcon />,
      scope: "personal"

    },
    {
      path: `${match.url}/billing`,
      component: BillingSettings,
      label: "Billing Dashboard",
      icon: <CreditCardIcon />,
      scope: "personal"
    },
    {
      path: `${match.url}/subscriptions`,
      component: SubscriptionSettings,
      label: "User Subscriptions",
      icon: <LoyaltyIcon />,
      scope: "personal"
    }
  ]

  return (
    <div>
      <CssBaseline />

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }, "drawer-top-margin")}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.drawerPosition]: true
          }),
        }}
      >
        {/* Open Navbar Icon */}
        <div className={classes.toolbar}>
          <IconButton onClick={() => setOpen(!open)}>
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>

        <Divider />

        <List>
          {pages.map((page, index) => (


            < span key={page + index}>
              {
                page.scope === "personal" && user.type !== "personal" ?
                  ""
                  :
                  <>

                    {
                      open ?
                        <NavLink to={page.path} className={classes.buttonLink}>

                          <ListItem onClick={() => setOpen(false)} button selected={page.path == location.pathname}>
                            <ListItemIcon>
                              {page.icon}
                            </ListItemIcon>
                            <ListItemText primary={page.label} />

                          </ListItem>
                        </NavLink>
                        :
                        <NavLink to={page.path} className={classes.buttonLink}>

                          <ToolTip title={page.label} >
                            <ListItem button selected={page.path == location.pathname}>
                              <ListItemIcon>
                                {page.icon}
                              </ListItemIcon>
                              <ListItemText primary={page.label} />
                            </ListItem>
                          </ToolTip>
                        </NavLink>

                    }
                  </>

              }
            </span>
          ))}
        </List>

      </Drawer>

      <main className={classes.container}>
        <div className={classes.content}>

          <Switch location={props.location}>
            {
              pages.map((page, idx) => (
                <AuthenticatedRoute key={`page-${idx}`} exact path={page.path} component={page.component} appProps={{ ...props }} />

              ))
            }
            <Redirect to={defaultRoute} />
          </Switch>



        </div>

      </main>
    </div >
  );
}