import React, { useState, useEffect } from "react"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import NavigationPrompt from "react-router-navigation-prompt";
import RequestNotification from "./RequestNotification"
import ConfirmationModal from "./ConfirmationModal"
import UserPaymentMethods from "./UserPaymentMethods"
import EditPaymentMethodForm from "./EditPaymentMethodForm"
import NewPaymentMethodForm from "./NewPaymentMethodForm"
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles"
import SlidePanel from "./SlidePanel"
import { updatePaymentMethod, createPaymentMethod, deletePaymentMethod } from "../libs/awsLib";
import config from "../config"

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: "1rem"
    },
    section: {
        margin: "2rem 0"

    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}))

const methodSavingAlertMap = {
    "success": "Your payment method was successfully updated!",
    "error": "An error occurred when attempting to save your payment method.",
    "loading": "Saving your payment method..."

}

const methodDeletingAlertMap = {
    "success": `Your payment method was successfully removed.`,
    "error": "An error occurred when attempting to remove your payment method.",
    "loading": "Removing your payment method..."
}

const methodCreatingAlertMap = {
    "success": `Your payment method was successfully created!`,
    "error": "An error occurred when attempting to create the new payment method.",
    "loading": "Creating your payment method..."
}

export default function BillingSettings(props) {
    const { user } = props;
    const classes = useStyles();

    // Loads Stripe into the Elements wrapper
    const stripePromise = loadStripe(config.STRIPE_KEY)

    const [selectedMethod, setSelectedMethod] = useState({});

    const [actionPending, setActionPending] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false)

    const [createDialogOpen, setCreateDialogOpen] = useState(false)

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [requestDialogOpen, setRequestDialogOpen] = useState(false);

    const [requestStatus, setRequestStatus] = useState("error");

    const [requestText, setRequestText] = useState(methodSavingAlertMap);

    const [errorMessage, setErrorMessage] = useState(null);

    const [updated, setUpdated] = useState({
        methods: false
    });


    const handleSelectChange = (item) => {
        if (!item) item = {}

        setSelectedMethod(item)
    }

    const handleOpenDialog = () => {
        setDialogOpen(!dialogOpen)
    }

    const handleSaveMethod = async ({ card: method }) => {
        // Close the create dialog
        setDialogOpen(false)

        // Show that an action is pending
        setActionPending(true)

        // Map user deleting request text
        setRequestText(methodSavingAlertMap)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Save the method
        try {

            const res = await updatePaymentMethod({ ...method, id: selectedMethod.id }, user)
            if (res.error) throw {}
            else setRequestStatus("success")

            // Reset the selectedMethod
            setSelectedMethod({})
            setErrorMessage(null)

        } catch (e) {
            if(e.response.data.error.raw.message) setErrorMessage(e.response.data.error.raw.message)
            setRequestStatus("error")
        }

        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Refresh methods (TODO)
        handleRefresh("methods")

    }

    const handleDeleteMethod = async () => {
        // Close the delete dialog
        setDeleteDialogOpen(false)

        // Map user deleting request text
        setRequestText(methodDeletingAlertMap)

        // Show that an action is pending
        setActionPending(true)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Remove the client invite
        try {

            const res = await deletePaymentMethod(selectedMethod, user)
            if (res.error) throw {}
            else setRequestStatus("success")

            // Reset the selectedMethod
            setSelectedMethod({})
            setErrorMessage(null)

        } catch (e) {
            if(e.response.data.error.raw.message) setErrorMessage(e.response.data.error.raw.message)
            setRequestStatus("error")
        }

        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Refresh methods (TODO)
        handleRefresh("methods")

    }

    const handleCreateMethod = async ({ paymentMethod }) => {
        // Close the create dialog
        setCreateDialogOpen(false)

        // Show that an action is pending
        setActionPending(true)

        // Map user deleting request text
        setRequestText(methodCreatingAlertMap)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Create the new method
        try {

            const res = await createPaymentMethod(paymentMethod, user)
            if (res.error) throw res
            
            else setRequestStatus("success")

            // Reset the selectedMethod
            setSelectedMethod({})
            setErrorMessage(null)

        } catch (e) {
            if(e.response.data.error.raw.message) setErrorMessage(e.response.data.error.raw.message)
            setRequestStatus("error")
        }

        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Refresh methods (TODO)
        handleRefresh("methods")

    }

    const handlePostRefresh = (name) => {
        const tmp = updated;
        tmp[name] = true;

        setUpdated({ ...tmp })

    }

    const handleRefresh = (name) => {
        const tmp = updated;
        tmp[name] = false;

        setUpdated({ ...tmp })
    }

    return (
        <div>

            <RequestNotification
                status={requestStatus}
                text={actionPending ? requestText["loading"] : errorMessage ? errorMessage : requestText[requestStatus]}
                open={requestDialogOpen}
                setOpen={setRequestDialogOpen}
                loading={actionPending}
            />

            <ConfirmationModal
                open={deleteDialogOpen}
                title={`Are you sure you want to remove this payment method?`}
                text={`Information for this payment method will be permanently deleted.`}
                setOpen={setDeleteDialogOpen}
                handleConfirm={handleDeleteMethod}
            />


            <NavigationPrompt when={dialogOpen || createDialogOpen} afterCancel={() => console.log("cancel")} afterConfirm={() => console.log("confirm")}>
                {({ onConfirm, onCancel }) => (
                    <ConfirmationModal
                        open={true}
                        setOpen={onCancel}
                        handleConfirm={onConfirm}
                        text="Changes to your payment method are unsaved and will be lost. Are you sure you want to leave this page?"
                    />
                )}
            </NavigationPrompt>

            <SlidePanel
                open={dialogOpen}
                handleClose={handleOpenDialog}
                style={{
                    padding: "4rem 2rem 2rem 6rem",
                }}
            >
                <EditPaymentMethodForm
                    method={selectedMethod}
                    handleSubmit={handleSaveMethod}
                />


            </SlidePanel>

            <SlidePanel
                open={createDialogOpen}
                handleClose={() => setCreateDialogOpen(false)}
                style={{
                    padding: "4rem 2rem 2rem 6rem",
                }}
            >
                <Elements stripe={stripePromise}>
                    <NewPaymentMethodForm
                        onSubmit={(data) => handleCreateMethod(data)}

                    />
                </Elements>


            </SlidePanel>

            <div className={classes.section}>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div>
                        <Button
                            onClick={selectedMethod.id ? handleOpenDialog : null}
                            disabled={!selectedMethod.id}
                            className={classes.button}
                            size="small"
                            variant="outlined"
                            color="primary"
                        >
                            Update&nbsp;<i className="fas fa-wrench fa-xs" />
                        </Button>
                        <Button
                            onClick={selectedMethod.id ? () => setDeleteDialogOpen(true) : null}
                            disabled={!selectedMethod.id}
                            className={classes.button}
                            variant="outlined"
                            size="small"
                            color="secondary"
                        >
                            Delete&nbsp;<i className="far fa-trash-alt fa-xs" />
                        </Button>

                    </div>

                </div>

                <Typography variant="overline" component="h5" style={{ fontSize: "1.3rem" }}>Payment Methods</Typography>



                <UserPaymentMethods
                    selectedItem={selectedMethod.id}
                    customer_id={user.customer_id}
                    onChange={(data) => handleSelectChange(data)}
                    updated={updated["methods"]}
                    onLoaded={() => {
                        handlePostRefresh("methods")
                    }}
                />
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"

                    className={classes.list}
                >


                    <ListItem style={{ padding: "0.5rem 1rem" }} button onClick={() => setCreateDialogOpen(true)}>
                        <ListItemText
                            primary={
                                <Typography align="center">
                                    <i style={{ color: "#2ddaa2" }} className="fas fa-plus" /> &nbsp;Add a new Payment Method
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>

            </div>

        </div>
    )
}