import React, { useMemo, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import useResponsiveFontSize from "../libs/useResponsiveFontSize";
import Button from "@material-ui/core/Button"

NewPaymentMethodForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};



function NewPaymentMethodForm(props) {
  const options = useOptions();

  const stripe = useStripe();
  const elements = useElements();

  const [isComplete, setIsComplete] = useState(false);
  const [saved, setSaved] = useState(false);
  const { onSubmit, onError } = props;

  const handleSubmit = async event => {

    event.preventDefault();

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)
    });

    if(payload.error) {
      console.log(payload)
      onError(payload.error.message)
    } else {
      onSubmit({...payload, complete: true});
  
      setSaved(true)
    }

  };

  return (
    <>
      <div>

        <label style={{ width: "100%", margin: "1rem 0 2rem 0" }}>
          <span style={{ margin: "1rem 0", display: "block" }}>
            Card details
                    </span>

          <CardElement
            options={options}
            onChange={(e) => setIsComplete(e.complete)}
          />

        </label>
        <Button style={{margin: "1rem 0"}} disabled={!isComplete || saved} fullWidth onClick={handleSubmit} variant="contained" color="primary">
          {"Save"}
        </Button>
      </div>
    </>
  )
}

export default NewPaymentMethodForm