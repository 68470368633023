import React, { useState, useEffect, useRef } from "react"
import NavigationPrompt from "react-router-navigation-prompt";
import RequestNotification from "./RequestNotification"
import ConfirmationModal from "./ConfirmationModal"
import InviteForm from "./InviteForm"
import UserInvites from "./UserInvites"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import makeStyles from "@material-ui/styles/makeStyles"
import { createInvite, deleteInvite, checkRemainingInvites } from "../../src/libs/awsLib"
import { Auth } from "aws-amplify";

const useStyles = makeStyles({
    infoSection: {
        margin: "1rem 0"
    },
    button: {
        marginLeft: "1rem"
    },
    section: {
        margin: "2rem 0"

    }
})

const clientSavingAlertMap = {
    "success": "Your client has been successfully invited to ADUROI!",
    "error": "An error occurred when attempting to invite your client.",
    "loading": "Sending an invite to your client..."

}

const clientDeletingAlertMap = {
    "success": `Your client was successfully removed.`,
    "error": "An error occurred when attempting to remove your client.",
    "loading": "Removing your client..."
}

export default function InviteSettings(props) {
    const { user } = props;

    const initialUser = {
        "name": "",
        "email": ""
    }

    const [newClient, setNewClient] = useState({ ...initialUser });

    const [selectedClient, setSelectedClient] = useState({})

    const [actionPending, setActionPending] = useState(false);

    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [requestDialogOpen, setRequestDialogOpen] = useState(false);

    const [requestStatus, setRequestStatus] = useState("error");

    const [requestText, setRequestText] = useState(clientSavingAlertMap);

    const [formComplete, setFormComplete] = useState(false)

    const [reqError, setReqError] = useState("");

    const [remaining, setRemaining] = useState(0);

    const [updated, setUpdated] = useState({
        invites: false
    });


    const classes = useStyles()

    const isEmpty = (obj) => {
        if (!obj) return true;

        if (Object.keys(obj).length === 0) return true;

        return false;
    }

    const addClient = async () => {

        // Verify if an account with this email already exists
        try {
            await Auth.signIn(newClient["email"], "123")
            setReqError("")
        } catch(e) {
            if(e.code !== 'UserNotFoundException') {
                setReqError("An ADUROI user already exists with this email.")
                return;
            }
        }

        // Close the create dialog
        setCreateDialogOpen(false)

        // Show that an action is pending
        setActionPending(true)

        // Map client creating request text
        setRequestText(clientSavingAlertMap)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)


        // Create the invite
        try {

            const res = await createInvite(newClient, user)
            if (res.error) throw {}
            else setRequestStatus("success")


            // Reset the new client
            setNewClient({ ...initialUser })

        } catch (e) {
            setRequestStatus("error")
        }

        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Refresh clients (TODO)
        handleRefresh("invites")


    }

    const removeClient = async () => {
        // Close the delete dialog
        setDeleteDialogOpen(false)

        // Map user deleting request text
        setRequestText(clientDeletingAlertMap)

        // Show that an action is pending
        setActionPending(true)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Remove the client invite

        try {

            const res = await deleteInvite(selectedClient, user)
            if (res.error) throw {}
            else setRequestStatus("success")

            // Reset the new client
            setNewClient({ ...initialUser })
            // Reset the selected client
            setSelectedClient({})

        } catch (e) {
            setRequestStatus("error")
        }


        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        // Refresh clients (TODO)
        handleRefresh("invites")
    }

    const handleUpdatedClient = (client) => {
        const { name, email } = client

        setFormComplete(client.complete)
        setNewClient({ name, email })

    }

    const handlePostRefresh = (name) => {
        const tmp = updated;
        tmp[name] = true;

        setUpdated({...tmp})

    }

    const handleRefresh = (name) => {
        const tmp = updated;
        tmp[name] = false;

        setUpdated({...tmp})
    }

    async function checkLimit() {
        // Check for customer's client limit
        const remaining = await checkRemainingInvites(user);
        setRemaining(remaining);

    }

    useEffect(() => {

        checkLimit()
    }, [])
    return (
        <>
            <RequestNotification
                status={requestStatus}
                text={actionPending ? requestText["loading"] : requestText[requestStatus]}
                open={requestDialogOpen}
                setOpen={setRequestDialogOpen}
                loading={actionPending}
            />
            <ConfirmationModal
                open={createDialogOpen}
                title={"Invite Creation"}
                setOpen={setCreateDialogOpen}
                handleConfirm={formComplete ? addClient : ()=>{}}
                disabled={!formComplete}
            >

                <Typography variant="subtitle1" align="center" style={{fontSize: "0.9rem"}} color="secondary">
                    {reqError}
                </Typography>
                <InviteForm
                    user={newClient}
                    onChange={(data) => handleUpdatedClient(data)}
                />

            </ConfirmationModal>
            <ConfirmationModal
                open={deleteDialogOpen}
                title={`Are you sure you want to remove your client ${selectedClient["name"]}?`}
                text={`Your client's account will be deactivated, and will no longer have access to ADUROI.`}
                setOpen={setDeleteDialogOpen}
                handleConfirm={removeClient}
            ></ConfirmationModal>


            <NavigationPrompt when={deleteDialogOpen} afterCancel={() => console.log("cancel")} afterConfirm={() => console.log("confirm")}>
                {({ onConfirm, onCancel }) => (
                    <ConfirmationModal
                        open={true}
                        setOpen={onCancel}
                        handleConfirm={onConfirm}
                        text="Invite creation is incomplete. Are you sure you want to leave this page?"
                    />
                )}
            </NavigationPrompt>

            <div className={classes.section}>
                {/* Client Invites */}


                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div>
                        <Button
                            onClick={!createDialogOpen && !(remaining <= 0) ? () => setCreateDialogOpen(true) : null}
                            disabled={actionPending || createDialogOpen || (remaining <= 0)}
                            className={classes.button}
                            size="small"
                            variant="outlined"
                            color="primary"
                        >
                            Add&nbsp;<i className="fas fa-user-plus fa-xs" />
                        </Button>
                        <Button
                            onClick={(!deleteDialogOpen || !isEmpty(selectedClient)) ? () => setDeleteDialogOpen(true) : null}
                            disabled={actionPending || deleteDialogOpen || isEmpty(selectedClient)}
                            className={classes.button}
                            variant="outlined"
                            size="small"
                            color="secondary"
                        >
                            Remove&nbsp;<i className="fas fa-user-times fa-xs" />
                        </Button>

                    </div>

                </div>

                < Typography variant="overline" component="h5" style={{ fontSize: "1.1rem" }}>
                    Client Invites&nbsp;&nbsp;
                
                        <Typography variant="subtitle1" component="span" style={{ fontSize: "0.8rem" }} color="primary">
                            {remaining} remaining invites
                        </Typography>
                        
                    

                </Typography>


                <UserInvites
                    user_id={user.user_id}
                    onChange={(data) => setSelectedClient({ ...data })}
                    updated={updated["invites"]}
                    onLoaded={() => {
                        handlePostRefresh("invites")
                        checkLimit()
                    }}
                />
            </div>

        </>
    )
}