export default function parseQuery(query) {
    const queryUnmarked = query.slice(1);

    const queryStrings = queryUnmarked.split("&")

    const result = {};

    for(const queryString of queryStrings) {
        const key = queryString.split("=")[0];
        const value = queryString.split("=")[1];

        result[key] = value;
    }

    return result
}