module.exports = (values) => {
    const errors = {};
    const fields = [
      {
        validateField: value => !value,
        name: "propertyNickname",
        error: "Required for identifying your evaluation."
      },
      {
        validateField: value => value < 0 || value > 15000000 || !value,
        name: "purchasePrice",
        error: "Purchase price must be greater than zero and less than $15,000,000"
      },
      {
        validateField: value => value < 0 || value > 1 || !value,
        name: "downPayment",
        error: "Down payment must be between 0% and 100%"
      },
      {
        validateField: value => value < 0 || value > 1 || !value,
        name: "mortgageAPR",
        error: "Mortgage APR must be between 0% and 100%"
      },
      {
        validateField: value => value < 0 || !value,
        name: "closingCosts",
        error: "Closing costs can't be a negative number.  Should be a dollar value greater than zero."
      },
      {
        validateField: value => value < 0 || value > 5 || !value,
        name: "rentIncreaseAnnually",
        error: "Rent increase per year must be between 0% and 500%"
      },
      {
        validateField: value => value < 0 || value > 1000000,
        name: "unit1MonthlyRent",
        error: "Monthly rent can't be a negative number and must be less than $1,000,000."
      },
      {
        validateField: value => value < 0 || value > 1000000,
        name: "unit2MonthlyRent",
        error: "Monthly rent can't be a negative number and must be less than $1,000,000."
      },
      {
        validateField: value => value < 0 || value > 1000000,
        name: "unit3MonthlyRent",
        error: "Monthly rent can't be a negative number and must be less than $1,000,000."
      },
      {
        validateField: value => value < 0 || value > 1000000,
        name: "unit4MonthlyRent",
        error: "Monthly rent can't be a negative number and must be less than $1,000,000."
      },
      {
        validateField: value => value < 0 || value > 360 || !value,
        name: "unit1FirstRentMonth",
        error: "The month in which construction starts needs be between 1 and 360."
      },
      {
        validateField: value => value < 0 || value > 360 || !value,
        name: "unit2FirstRentMonth",
        error: "The month in which construction starts needs be between 1 and 360."
      },
      {
        validateField: value => value < 0 || value > 360 || !value,
        name: "unit3FirstRentMonth",
        error: "The month in which construction starts needs be between 1 and 360."
      },
      {
        validateField: value => value < 0 || value > 360 || !value,
        name: "unit4FirstRentMonth",
        error: "The month in which construction starts needs be between 1 and 360."
      },
      {
        validateField: value => value < 0 || value > 10000000,
        name: "constructionCosts",
        error: "Construction costs can't be a negative number and must be less than $10,000,000."
      },
      {
        validateField: value => value < 0 || value > 18,
        name: "monthsofConstruction",
        error: "Duration of construction must be between 0 and 18 months."
      },
      {
        validateField: value => value < 0 || value > 360 || !value,
        name: "constructionStartMonth",
        error: "Construction must start between 0 and 360 months following acquisition."
      },
      {
        validateField: value => value < 0 || value > 1 || !value,
        name: "constructionLoanAPR",
        error: "Construction loan APR must be between 0% and 100%"
      },
      {
        validateField: value => value < 0 || value > 5 || !value,
        name: "annualIncreaseInPropertyValue",
        error: "Property value increase per year must be between 0% and 500%"
      },
      {
        validateField: value => value < 0 || value > 0.5 || !value,
        name: "sellingCosts",
        error: "Selling costs must be a percent between 0% and 50% of sale price."
      },
      {
        validateField: value => value < 0 || value > 100000,
        name: "utilitiesGardenerHoa",
        error: "Monthly Utility, Gardner, and Home Owners Assoc costs must be between $0 and $100,000"
      },
      {
        validateField: value => value < 0 || value > 0.99 || !value,
        name: "vacancyandPropertyManagementFees",
        error: "Vacancy rate and property management fees together must be a percent between 0% and 99% of rents."
      },
      {
        validateField: value => value < 0 || value > 0.99 || !value,
        name: "incomeRaxRate",
        error: "Income tax rate must be a percent between 0% and 99% of taxable income."
      },
      {
        validateField: value => value < 0 || value > 0.99 || !value,
        name: "capGainsTaxRate",
        error: "Capital gain tax rate must be a percent between 0% and 99% of taxable capital gains income."
      }
    ]
  
    for (const field of fields) {
      const { validateField, name, error } = field;
      if (validateField(values[name])) errors[name] = error;
    }
  
    return errors;
}