import React, { useEffect, useRef, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { getUser, s3GetDownloadURL } from "../libs/awsLib"

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 'auto',
        bottom: "-10rem",
        padding: "1.2rem"
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        margin: "auto",
        height: "100px"
    }
}));

export default function Footer(props) {
    const classes = useStyles();
    const { user } = props;
    const [realtorInfo, setRealtorInfo] = useState();

    useEffect(() => {
        async function getURL() {
            // Retrieve realtor logo 
            const { realtor_id } = user

            const realtorData = await getUser(realtor_id);


            if (realtorData) {
                let url
                if (realtorData.logo) url = await s3GetDownloadURL(realtorData.logo)
                const tmp = {
                    logo: url,
                    name: realtorData.name,
                    company: realtorData.company
                }

                setRealtorInfo({ ...tmp })

            }
        }

        if (user.type === "client") {
            getURL()
        } else setRealtorInfo(null)
    }, [user])

    return (
        <AppBar position="absolute" color="default" className={classes.appBar}>
            <Toolbar>


                {
                    realtorInfo ?
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography align="center" variant="subtitle1">Account Courtesy of <b>{realtorInfo["name"]}</b></Typography>

                            </Grid>
                            {realtorInfo["company"] ?

                                <Grid item xs={12}>
                                    <Typography align="center" variant="caption" component="p"><i>{realtorInfo["company"]}</i></Typography>

                                </Grid>
                                :
                                ""
                            }
                            {/* <div className={classes.grow} /> */}

                            {realtorInfo["logo"] ?

                                <Grid item xs={12}>
                                    <div style={{ display: "flex", marginTop: "1rem" }}>
                                        <img className={classes.logo} src={realtorInfo["logo"]}></img>
                                    </div>
                                </Grid>
                                :
                                ""
                            }

                        </Grid>
                        :

                        ""
                }
            </Toolbar>
        </AppBar>
    )


}