import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LoadingMenu from "./LoadingMenu"
import PropTypes from "prop-types";
import {getProducts} from "../libs/awsLib"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

SubscriptionPlans.propTypes = {
    onChange: PropTypes.func.isRequired,
    plan: PropTypes.shape({
        id: PropTypes.string
    })
}

export default function SubscriptionPlans(props) {
    const { onChange, plan } = props;

    const classes = useStyles();
    const [openSubs, setOpenSubs] = React.useState({});
    const [subscriptions, setSubscriptions] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(plan ? plan.id : null);
    const [isLoading, setIsLoading] = useState(true);

    const handlePlanClick = (product, plan) => {
        const id = plan.id;
        setSelectedPlan(id);
        onChange({ product, plan, complete: true })
    };

    useEffect(() => {
        async function getData() {
            try {
                const products = await getProducts();

                const tmp = {};
                for (const product of products) {
                    tmp[product.id] = true;
                }
                
                setOpenSubs({ ...tmp });
                setSubscriptions(products)
                setIsLoading(false)

            } catch (e) {
                console.log(e)
            }
        }
        getData();

    }, [])

    const handleProductClick = (product) => {

        // Prevent closing if the user has selected a plan within this product
        for (const plan of product.plans) {
            if (selectedPlan === plan.id) return;
        }

        const id = product.id;
        const isOpen = openSubs[id];
        const tmp = openSubs;
        tmp[id] = !isOpen;

        setOpenSubs({ ...tmp });
    };

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Available Subscription Plans
                </ListSubheader>
            }
            className={classes.root}
        >
            {
                isLoading ?

                    <LoadingMenu

                    />
                    :

                    subscriptions.length ?

                        <>
                            {
                                subscriptions.map((product, idx) => (
                                    <span key={idx}>
                                        <ListItem button onClick={() =>

                                            handleProductClick(product)

                                        }>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        <strong>{product.name}</strong>
                                                    </>
                                                }
                                                secondary={
                                                    <>
                                                        {product.description}
                                                    </>
                                                }
                                            />
                                            {openSubs[product.id] ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse
                                            in={openSubs[product.id]}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <List component="div" disablePadding>
                                                {
                                                    product.plans.map((plan, idx) => (

                                                        <ListItem key={plan.id} selected={selectedPlan === plan.id} button className={classes.nested} onClick={() =>
                                                            handlePlanClick(product, plan)}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    <>
                                                                        {`${plan.nickname}`} Plan
                                                                    </>
                                                                }
                                                                secondary={
                                                                    <>
                                                                        <strong style={{ color: "#6b95f4", display: "block" }}>
                                                                            ${(plan.amount / 100).toFixed(2)} / {plan.interval}
                                                                        </strong>
                                                                        {plan.description}
                                                                    </>
                                                                }
                                                            />
                                                            {
                                                                selectedPlan === plan.id ?
                                                                    <CheckCircle style={{ color: "#04cf04" }} />
                                                                    :
                                                                    <CheckCircleOutline style={{ color: "#cfcccc" }} />

                                                            }
                                                        </ListItem>

                                                    ))
                                                }

                                            </List>
                                        </Collapse>
                                    </span>
                                ))
                            }

                        </>
                        :

                        <>
                            <div style={{ width: "100%", display: "flex", padding: "2rem 0" }}>
                                <div style={{ margin: "auto" }}>
                                    No subscription plans were found.
                            </div>
                            </div>
                        </>
            }

        </List>
    );
}