import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { makeStyles } from '@material-ui/core/styles';

// Requiring specific props
RequestNotification.propTypes = {
    status: PropTypes.oneOf(["error", "success"]).isRequired,
    text: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    icon: {
        display: "unset",
        fontSize: "17px"
    }
}));

export default function RequestNotification(props) {
    // ALLOWED STATUS CODES
    // error = red alert
    // success = green alert

    const [mainProps, setProps] = useState(props);
    const { status, text, open, setOpen, loading } = mainProps;
    const classes = useStyles()

    useEffect(() => {
        setProps(props);
    }, [props])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            {

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={open} autoHideDuration={2000} onClose={!loading ? handleClose : null}>
                    <Alert classes={{icon: classes.icon}} iconMapping={{ info: <i className={"fas fa-circle-notch fa-spin"} /> }} severity={loading ? "info" : status} onClose={!loading ? handleClose : null}>

                    {/* <Alert iconMapping={{ info: <i className={"fas fa-circle-notch fa-spin"} /> }} severity={loading ? "info" : status} onClose={!loading ? handleClose : null}> */}
                        <AlertTitle>{ loading ? "Pending" : status === "error" ? "Error" : status === "success" ? "Success" : "Default"}</AlertTitle>
                        {text}
                    </Alert>
                </Snackbar>


            }
        </>
    )
}