import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Auth } from "aws-amplify";
import { checkStrength } from "../libs/verify";

// Requiring specific props
ForgotPasswordDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onPasswordChange: PropTypes.func.isRequired,
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    field: {
        margin: "1.5rem 0"
    },
    paper: {
        margin: 0,
        width: "90%"
    },
})

export default function ForgotPasswordDialog(props) {
    const classes = useStyles();
    const [mainProps, setProps] = useState(props);
    const { open, onPasswordChange, setOpen, email } = mainProps;
    const [codeVerifying, setCodeVerifying] = useState(false);
    const [requestError, setRequestError] = useState("");

    // A boolean indicating if the current password is viewable
    const [showPass, setShowPass] = useState(false)

    // Boolean indicating the current password is verified (free of all errors)
    const [passwordVerified, setPasswordVerified] = useState(false);

    // All current password errors
    const [reqPassErrors, setReqPassErrors] = useState([]);

    const [verificationData, setVerificationData] = useState({
        original: "",
        duplicate: "",
        code: ""
    });

    // Handle changes to verification code input
    const handleCodeChange = (event) => {
        const { name, value } = event.target;

        const tmp = verificationData;
        tmp[name] = value;

        setVerificationData({ ...tmp })
    }

    // Verify the code
    const handlePassChangeConfirm = async () => {

        try {
            await Auth.forgotPasswordSubmit(email, verificationData["code"], verificationData["original"]);

            setCodeVerifying(false);
            onPasswordChange(verificationData["original"])


        } catch (e) {

            setRequestError(e.message)
            setCodeVerifying(false);
        }

    }

    // Close the dialog
    const handleClose = () => {
        setOpen(false);
    };


    // Handle password input changes
    const handlePasswordChange = (event) => {
        const { name, value } = event.target;

        const tmp = verificationData;
        tmp[name] = value;
        setVerificationData({ ...tmp })

        // Retrieve password strength errors
        const requiredTestErrors = checkStrength(tmp.original, { upper: true, lower: true, number: true });

        // if the password and the duplicate match AND there are no failed required tests...
        if ((tmp.original === tmp.duplicate) && !requiredTestErrors.length) {
            setReqPassErrors([])
            setPasswordVerified(true);
        }
        // Otherwise show the password errors
        else {
            setPasswordVerified(false);
            let errors = [];
            if (tmp.original !== tmp.duplicate) errors.push("Your passwords must match.")

            // Add all other errors
            errors = errors.concat(requiredTestErrors);
            setReqPassErrors([...errors])

        }

    }


    useEffect(() => {
        setProps(props)
        // Reset if the dialog is closed
        setVerificationData({
            code: "",
            original: "",
            duplicate: ""
        })
        setRequestError("")

    }, [props])

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={(!codeVerifying) ? handleClose : () => { }}
                classes={{ paper: classes.paper }}

            >


                <DialogTitle>{"Change Your Password"}</DialogTitle>

                <DialogContent>

                    {

                        <>
                            {/* VERIFICATION CODE */}
                            <TextField
                                fullWidth
                                label="Verification Code (Check your email)"
                                className={classes.field}
                                onChange={handleCodeChange}
                                value={verificationData["code"]}
                                name="code"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><i className="fas fa-fingerprint"></i></InputAdornment>,
                                }}
                                variant="outlined"
                            />

<>
                                    {/* Print all password errors */}
                                    {
                                        reqPassErrors.map((item, idx) => (
                                            <Typography
                                                color="secondary"
                                                key={item + idx}
                                                variant="caption"
                                                component="p"
                                            >
                                                {item}
                                            </Typography>
                                        ))
                                    }
                                    <TextField
                                        fullWidth
                                        label="Your Password"
                                        className={classes.field}
                                        onChange={handlePasswordChange}
                                        type={!showPass ? "password" : ""}
                                        value={verificationData["original"]}
                                        name="original"

                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><i className="fas fa-key"></i></InputAdornment>,
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton edge="end" onClick={() => setShowPass(!showPass)}>
                                                        {
                                                            showPass ?
                                                                <Visibility />
                                                                :
                                                                <VisibilityOff />
                                                        }
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextField
                                        fullWidth
                                        label="Verify Your Password"
                                        className={classes.field}
                                        onChange={handlePasswordChange}
                                        type={!showPass ? "password" : ""}
                                        value={verificationData["duplicate"]}
                                        name="duplicate"

                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{passwordVerified ? <i style={{ color: "green" }} className="fas fa-check-circle"></i> : <i style={{ color: "red" }} className="fas fa-times-circle"></i>}</InputAdornment>,
                                        }}
                                        variant="outlined"
                                    />

                                </>

                        </>
                    }

                    <Typography
                        color="secondary"
                        variant="caption"
                        component="p"
                    >
                        {requestError}
                    </Typography>

                </DialogContent>



                <DialogActions>


                    <>
                        <Button disabled={codeVerifying} onClick={!codeVerifying ? handleClose : ()=>{}} color="default">
                            Cancel
                                </Button>
                        <Button disabled={codeVerifying || !passwordVerified} onClick={(passwordVerified && !codeVerifying)? handlePassChangeConfirm : ()=>{}} color="primary">
                            Submit
                        </Button>
                    </>

                </DialogActions>


            </Dialog>
        </div>
    )
}