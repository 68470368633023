import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton";
import AduForm from "../../components/AduForm";
import { Link } from "react-router-dom";
import { getEvaluation, getSheetData, postSheetData, updateEvaluation, deleteEvaluation } from "../../libs/awsLib"

import {
  IconButton,
  Tooltip,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles"
import "./Adus.css";
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import axios from "axios";

import Loading from "../../components/Loading"

const useStyles = makeStyles({
  header: {
    fontWeight: "bold",
    margin: "1rem 0"
  },
  button: {
    margin: "1rem 0.5rem",
    textDecoration: "none"
  },
  root: {
    margin: "2rem 0"
  }
});

export default function Adus(props) {

  const { user } = props;

  const [infoLoading, setInfoLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [tableData, setTableData] = useState({});
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const classes = useStyles();

  const onSubmit = async values => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    setIsLoading(true);
    await sleep(300);
    const data = {
      body: values
    };

    // axios
    //   .post(
    //     // GOOGLE POST - PUT USER INPUT INTO SHEETS (APIinputs tab)
    //     "https://2htewwo86g.execute-api.us-west-2.amazonaws.com/google-sheet/google-sheets",
    //     JSON.stringify(data)
    //   )
    postSheetData(data)
      .then(async function (response) {
        console.log(response)

        let sumData = await callingFn();
        const newTableData = { ...sumData, ...values };
        setTableData(newTableData);
        return newTableData;
      })
      .then(async function (response) {
        await updateEvaluation(props.match.params.id, response, user.user_id);
        props.history.push(`/view/${props.match.params.id}`);
        return response;
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        return error;
      });
  };

  async function callingFn() {
    const response = await getSheetData();
    let summary = response;

    setTableData(summary);
    return summary;
  }

  useEffect(() => {

    async function onLoad() {
      try {
        const data = await getEvaluation(props.match.params.id, user.user_id)
        setFormData(data);
        setInfoLoading(false);

        return data;
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  async function handleDelete(event) {
    event.preventDefault();

    setIsDeleting(true);

    try {
      await deleteEvaluation(props.match.params.id, user.user_id);
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsDeleting(false);
    }
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => { if (!isDeleting) setDeleteDialogOpen(false) }}
      >
        <DialogTitle>{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this evaluation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={isDeleting} onClick={() => setDeleteDialogOpen(false)} color="textSecondary">
            Cancel
          </Button>
          <Button disabled={isDeleting} onClick={handleDelete} color="secondary" autoFocus>
            Delete&nbsp;{isDeleting ? <i className="fas fa-spinner fa-spin"></i> : ""}
          </Button>
        </DialogActions>
      </Dialog>
      {
        infoLoading ?
          <Loading />
          :
          isLoading ?
            <Loading text="Saving..." />
            :

            < AduForm
              onSubmit={onSubmit}
              initialValues={formData}
              ActionButtons={
                ({ submitting }) =>
                  
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Link to={`/view/${props.match.params.id}`} >
                      <Tooltip title="Back to Evaluation">
                        <IconButton className={classes.button} variant="outlined">
                          <i className="fas fa-long-arrow-alt-left fa-xs" />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <div>

                      <Tooltip title="Save Evaluation">
                        <IconButton type="submit" className={classes.button} variant="contained" color="primary">
                          <i className="fas fa-save fa-xs" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Evaluation">
                        <IconButton onClick={() => setDeleteDialogOpen(true)} className={classes.button} variant="contained" color="secondary">
                          <i className="far fa-trash-alt fa-xs"></i>
                        </IconButton>
                      </Tooltip>

                    </div>

                  </div>
              }
            />
      }
    </div>
  );
}
