import React from "react";
import { Grid, Typography } from "@material-ui/core";

function LoadingMenu(props) {
    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            // backgroundImage: "radial-gradient(transparent, rgba(128, 128, 128, 0.15))",
            padding: "3rem"
        }}>
            <div style={{ margin: "auto" }}>
                <Grid container>
                    <Grid item>
                        <div style={{ marginBottom: "0" }}>
                            <Typography style={{fontSize: "1rem"}} variant={"overline"} align="center">
                                {props.text ? props.text : "Loading"}
                            </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                            <i style={{ margin: "auto", color: "rgb(45, 218, 162)" }} className="fas fa-circle-notch fa-spin"></i>
                        </div>
                    </Grid>

                </Grid>
            </div>
        </div>
    )
}

export default LoadingMenu;