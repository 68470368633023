import React, { useState, useEffect, useRef } from "react";
import { Grid, Button, TextField } from '@material-ui/core';
import CardExpField from "./CardExpField";
import PropTypes from "prop-types";

EditPaymentMethodForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
}
export default function EditPaymentMethodForm(props) {
    const [mainProps, setProps] = useState(props)
    const { handleSubmit, method } = mainProps;
  
    const { exp_month, exp_year, last4 } = method.card;

    const initialCard = useRef()
      
    const [cardData, setCard] = useState({ exp_month, exp_year})

    const [isComplete, setComplete] = useState(true);

    const onSubmit = async event => {
      event.preventDefault();
      
      handleSubmit({
          card: cardData
      });
    };

    const handleCardChange = (data) => {
        const { exp_month, exp_year, complete } = data;
        setCard({exp_month, exp_year})
        setComplete(complete);
    }
    
    useEffect(() => setProps(props), [props])

    useEffect(() => {
        initialCard.current = { exp_month, exp_year}
        setCard({ exp_month, exp_year})
    }, [])

    return ( 
        <>
            <form onSubmit={!(JSON.stringify(initialCard.current) === JSON.stringify(cardData)) && isComplete ? onSubmit : null}>
                <Grid style={{width: "100%", margin: "1rem 0"}} spacing={5} container>
                    <Grid xs={12} sm={8} item>
                        <TextField
                            disabled
                            label="Card Number"
                            fullWidth
                            value={`●●●●●●●●●●●●${last4}`}
                        />
                    </Grid>
                    <Grid xs={12} sm={4} item>
                        <CardExpField
                            expMonth={cardData.exp_month}
                            expYear={cardData.exp_year}
                            onChange={handleCardChange}
                        />
                    </Grid>
                </Grid>
                <Button fullWidth disabled={(JSON.stringify(initialCard.current) === JSON.stringify(cardData)) || !isComplete} type="submit" variant="contained" color="primary">
                    {"Save"}
                </Button>
            </form>
        </>
    )
}