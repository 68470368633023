import { Storage, API, Auth } from "aws-amplify";
import config from "../../src/config";

export async function s3Upload(file, level = "private", name) {
  Storage.configure({
    bucket: config.s3.BUCKET,
    level: level,
    region: config.s3.REGION,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  });

  const filename = `${Date.now()}${name ? "-" + name : ""}`;

  try {

    const stored = await Storage.put(filename, file, {
      contentType: file.type
    });

    return stored.key;
  } catch (e) {

    return { error: e };
  }

}

export async function s3Update(file, level = "private", key) {
  Storage.configure({
    bucket: config.s3.BUCKET,
    level: level,
    region: config.s3.REGION,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  });

  try {

    const stored = await Storage.put(key, file, {
      contentType: file.type
    });

    return stored.key;
  } catch (e) {

    return { error: e };
  }

}

export async function s3GetDownloadURL(key) {

  try {

    const url = await Storage.get(key);

    return url;
  } catch (e) {

    return { error: e };
  }

}

// GET
//-----------------------------------------------------------------------


export async function getUser(user_id) {

  const res = await API.get("notes", `/users/${user_id}`)

  if (res) {
    Object.defineProperty(res, "name", Object.getOwnPropertyDescriptor(res, "username"));
    delete res["username"]
  }

  return res;
}

export async function getUserSubscriptions(customer_id) {

  const res = await API.get("notes", `/billing/subscriptions/${customer_id}`)

  return res;
}

export async function getUserInvites(user_id) {
  const res = await API.get("notes", `/invites?user=${user_id}`);

  const invites = []
  for (let invite of res) {
    Object.defineProperty(invite, "name", Object.getOwnPropertyDescriptor(invite, "username"));
    delete invite["username"]
    invites.push(invite)
  }

  return invites;
}

export async function checkRemainingInvites(user) {
  const invites = await getUserInvites(user.user_id);
  const { data: subscriptions } = await getUserSubscriptions(user.customer_id);

  let LIMIT = 0;

  if (subscriptions.length) LIMIT = subscriptions[0].plan.metadata.INVITE_LIMIT;

  let remaining = 0;

  if (invites.length < LIMIT) remaining = LIMIT - invites.length

  return remaining

}

export async function getProducts() {
  const res = await API.get("notes", "/billing/products");

  return res;
}

export async function getCustomer(customer_id) {
  const res = await API.get("notes", `/billing?customer_id=${customer_id}`);

  return res;
}

export async function getUserPayments(customer_id) {
  const res = await API.get("notes", `/billing/payment/${customer_id}`);

  return res;
}

export async function getSheetData() {
  const res = await API.get("notes", "/sheets")

  return res;
}

export async function getEvaluation(id, user_id) {
  const res = await API.get("notes", `/notes/${id}+${user_id}`);

  return res;
}

export async function getUserEvaluations(user_id) {
  const res = await API.get("notes", `/notes?user=${user_id}`);

  return res;
}

export async function getInvite(invite_code) {
  const res = await API.get("notes", `/invites/${invite_code}`)

  if (res) {
    Object.defineProperty(res, "name", Object.getOwnPropertyDescriptor(res, "username"));
    delete res["username"]
  }

  return res;
}

// POST
//-----------------------------------------------------------------------
export async function postSheetData(data) {
  return API.post("notes", "/sheets", data)
}

export async function createUser(user) {
  Object.defineProperty(user, "username", Object.getOwnPropertyDescriptor(user, "name"));
  delete user["name"]

  const res = await API.post("notes", "/users", {
    body: user
  })

  return res;
}

export async function upgradeUser(user) {
  Object.defineProperty(user, "username", Object.getOwnPropertyDescriptor(user, "name"));
  delete user["name"]

  const res = await API.post("notes", "/users?upgrade=true", {
    body: user
  })

  return res;
}

export async function createCustomer(customer) {
  const res = await API.post("notes", "/billing", {
    body: customer
  })

  return res;
}

export async function createEvaluation(data, user_id) {
  const res = await API.post("notes", `/notes?user=${user_id}`, {
    body: data
  })

  return res;
}

export async function createInvite(client, user) {

  Object.defineProperty(client, "username", Object.getOwnPropertyDescriptor(client, "name"));
  delete client["name"]

  const res = await API.post("notes", "/invites", {
    body: {
      client,
      user
    }
  })

  return res;
}

export async function createSubscription(sub, customer_id) {

  const { cancel_at_period_end,
    collection_method,
    default_payment_method,
    plan,
    product } = sub;

  const subscription = {
    cancel_at_period_end,
    collection_method,
    default_payment_method,
    plan,
    product
  };

  const res = await API.post("notes", `/billing/subscriptions`, {
    body: {
      subscription,
      customer_id
    }
  })

  return res;
}

export async function createPaymentMethod(method, user) {

  const { id } = method
  const { customer_id } = user

  return API.post("notes", `/billing/payment/${customer_id}`, {
    body: {
      method: {
        id
      }
    }
  });
}

// PUT
//-----------------------------------------------------------------------

export async function updateUser(user_id, user, type) {

  Object.defineProperty(user, "username", Object.getOwnPropertyDescriptor(user, "name"));
  delete user["name"]

  const res = await API.put("notes", `/users/${user_id}`, {
    body: { ...user, type }
  })

  return res;
}

export async function updateEvaluation(eval_id, data, user_id) {

  return API.put("notes", `/notes/${eval_id}+${user_id}`, {
    body: data
  });
}

export async function updateInvite(user, data) {

  const { invite_id: code, realtor_id: user_id } = user
  const { lastLogin } = data

  return API.put("notes", `/invites/${code}`, {
    body: {
      invite: { code, user_id, lastLogin }
    }
  });
}

export async function updateSubscription(updateData, user_id) {

  const { updates, id, sub_item_id } = updateData;

  const {
    cancel_at_period_end,
    collection_method,
    default_payment_method,
    plan,
    uninvites
  } = updates;

  const subscription = {
    cancel_at_period_end,
    collection_method,
    default_payment_method,
    sub_item: {
      plan,
      id: sub_item_id
    },
    id
  };

  const res = await API.put("notes", `/billing/subscriptions/${id}`, {
    body: {
      subscription,
      uninvites,
      user_id
    }
  })

  return res;
}

export async function updatePaymentMethod(method, user) {

  const { exp_month, exp_year, id } = method
  const { customer_id } = user

  return API.put("notes", `/billing/payment/${customer_id}`, {
    body: {
      method: {
        exp_month, exp_year, id
      }
    }
  });
}

// DELETE
//-----------------------------------------------------------------------
export async function deleteEvaluation(eval_id, user_id) {

  return API.del("notes", `/notes/${eval_id}+${user_id}`);
}

export async function deleteUser(user) {

  const cognitoUser = await Auth.currentAuthenticatedUser()

  let logo = null;

  if (user.logo) {
    logo = {
      bucket: config.s3.BUCKET,
      key: `public/${user.logo}`
    }
  }

  return API.del("notes", `/users/${user.user_id}`, {
    body: {
      customer_id: user.customer_id,
      user_id: user.user_id,
      cognito: {
        pool: cognitoUser.pool.userPoolId,
        username: cognitoUser.username
      },
      type: user.type,
      logo
    }
  });
}

export async function deleteInvite(invite, user) {

  const { client_id, code, name: client_name, email, user_id } = invite
  const { name: realtorName } = user

  return API.del("notes", `/invites/${code}`, {
    body: {
      user: { name: realtorName },
      invite: { client_id, code, name: client_name, email, user_id }
    }
  });
}

export async function deletePaymentMethod(method, user) {

  const { id } = method
  const { customer_id } = user

  return API.del("notes", `/billing/payment/${customer_id}`, {
    body: {
      method: {
        id
      }
    }
  });
}

export async function deleteSubscription(sub_id) {



  return API.del("notes", `/billing/subscriptions/${sub_id}`);
}
