import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import LoadingMenu from "./LoadingMenu"

import PropTypes from "prop-types"
import { getUserSubscriptions, getProducts } from "../libs/awsLib";

UserSubscription.propTypes = {
    customer_id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
    sideMargin: {
        margin: "0 1rem"
    }
}));

const stripeStatusIcons = {
    active: (
        <RadioButtonChecked style={{ color: "#04cf04" }} />
    ),
    incomplete: (
        <RadioButtonChecked style={{ color: "rgb(255, 188, 0)" }} />
    ),
    past_due: (
        <RadioButtonChecked style={{ color: "rgb(255, 68, 68)" }} />
    )
}

const stripeStatusMessage = {
    active: "Active",
    incomplete: "Payment Incomplete",
    past_due: "Past Due"
}

export default function UserSubscription(props) {

    const { customer_id, onChange, selected, updated, onLoaded } = props;
    const classes = useStyles();
    const [selectedSub, setSelectedSub] = useState(selected);
    const [mainProps, setProps] = useState(props);
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setLoading] = useState(true)

    const handleSubClick = (sub) => {

        let id = sub.id;

        if (selectedSub !== id) {
            setSelectedSub(id);
            onChange(sub);
        } else {
            setSelectedSub(null);
            onChange(null);
        }

    };

    const simpleTime = (unix_timestamp, daysAhead = 0) => {
        var a = new Date(unix_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var month = months[a.getMonth()];
        var date = a.getDate() + daysAhead;

        var time = month + ' ' + date;
        return time;
    }

    async function onLoad() {
        setLoading(true)

        const { data: subscriptions } = await getUserSubscriptions(customer_id);

        const products = await getProducts();

        const productMap = {};
        for (const product of products) productMap[product.id] = product;

        const tmp = subscriptions

        for (let i = 0; i < tmp.length; i++) {
            const productId = tmp[i].plan.product
            tmp[i].plan.product = productMap[productId]
        }

        setSubscriptions(tmp);

        setLoading(false)
        onLoaded();
    }
    useEffect(() => {

        onLoad()
    }, [])

    useEffect(()=> {
        setSelectedSub(props.selected)
        setProps(props)
    }, [props])

    useEffect(() => {
        if(!updated) onLoad()
    }, [updated])

    return (
        <>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        My Subscription Plans
                    </ListSubheader>
                }
                className={classes.root}
            >

                {
                    isLoading ?
                        <LoadingMenu />
                        :

                        subscriptions.length === 0 ?
                            <>
                                {
                                    subscriptions.length === 0 && (
                                        <div style={{ width: "100%", display: "flex", margin: "3rem 0" }}>
                                            <div style={{ margin: "auto" }}>
                                                No subscriptions found.
                            </div>
                                        </div>
                                    )
                                }
                            </>
                            :

                            <>
                                {
                                    subscriptions.map((sub, idx) => (
                                        <span key={idx}>
                                            <ListItem
                                                button
                                                selected={selectedSub === sub.id}
                                                onClick={() =>
                                                    handleSubClick(sub)
                                                }>
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            {sub.plan.product.name}
                                                        </>
                                                    }
                                                    secondary={
                                                        <>
                                                            <span style={{ display: "block", margin: "0.2rem 0" }}>
                                                                {
                                                                    sub.billing === "charge_automatically" && !sub.cancel_at_period_end ?
                                                                        <>
                                                                            Renews automatically on {simpleTime(sub.current_period_end)} <Icon className="fas fa-sync-alt" style={{ fontSize: "inherit", verticalAlign: "middle", margin: "0 0.1rem" }}></Icon>
                                                                        </>

                                                                        :
                                                                        sub.billing === "send_invoice" ?
                                                                            <>
                                                                                Next invoice due {simpleTime(sub.current_period_end, 1)} <Icon className="fas fa-file-invoice-dollar" style={{ fontSize: "inherit", verticalAlign: "middle", margin: "0 0.1rem" }}></Icon>
                                                                            </>
                                                                            :
                                                                            sub.cancel_at_period_end ?

                                                                                <>
                                                                                    Cancels automatically on {simpleTime(sub.cancel_at)}
                                                                                </>

                                                                                :

                                                                                ""
                                                                }



                                                            </span>

                                                            <strong style={{ color: "#6b95f4" }}> ${(sub.plan.amount / 100).toFixed(2)} / {sub.plan.interval} · </strong>{`${sub.plan.nickname}`} Plan
                                            <span style={{ display: "block" }}>
                                                                {sub.plan.product.description}
                                                            </span>

                                                        </>
                                                    }
                                                />
                                                <Typography className={classes.sideMargin} variant="overline" color="textSecondary">
                                                    {stripeStatusMessage[sub.status]}
                                                </Typography>
                                                {stripeStatusIcons[sub.status]}
                                            </ListItem>


                                        </span>
                                    ))
                                }
                            </>

                }

            </List>
        </>
    )
}