import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function InactiveClientRoute({ component: C, appProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>


        appProps.isAuthenticated && !appProps.isSubscribed && appProps.user.type === "client"
          ?
          <C {...props} {...appProps} />
          :
          <Redirect
            to={`/`}
          />

      }
    />
  );
}