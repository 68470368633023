import React, { useState, useEffect, useRef } from "react";
import SelectUserInvites from "./SelectUserInvites"
import ViewablePasswordField from "./ViewablePasswordField";
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { Auth } from "aws-amplify"
import Slide from '@material-ui/core/Slide';
import clsx from "clsx"
import makeStyles from "@material-ui/styles/makeStyles"
import PropTypes from "prop-types"
import { getUserInvites } from "../libs/awsLib"

const subModel = {
    product: PropTypes.string.isRequired,
    plan: PropTypes.string.isRequired,
    collection_method: PropTypes.oneOf(["send_invoice", "charge_automatically"]),
    default_payment_method: PropTypes.string,
    cancel_at_period_end: PropTypes.bool.isRequired,
}

const subDataModel = {
    default_payment_method: PropTypes.object,
    plan: PropTypes.object,
    product: PropTypes.object,
}

SubscriptionUpdateConfirmationModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    update: PropTypes.shape({
        oldSub: PropTypes.shape(subModel),
        newSub: PropTypes.shape(subModel),
        newData: PropTypes.shape(subDataModel),
    }),
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

const useStyles = makeStyles({
    button: {
        margin: "2rem 0 1rem 0"
    },
    panel: {
        padding: "1rem"
    },
    icon: {
        fontSize: "inherit",
        verticalAlign: "inherit",
        margin: "0 0.1rem"
    },
    dialog: {
        padding: "0 2rem 2rem 2rem",
        width: "100%",
    },
    slide: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: "2rem"
    },
    paper: {
        margin: 0,
        width: "90%",
        maxWidth: "700px",
        padding: "3rem 0"
    },
    uninviteLink: { fontSize: "0.8rem", cursor: "pointer" },
    margin: {
        marginTop: "1rem"
    }
})


export default function SubscriptionUpdateConfirmationModal(props) {

    const [mainProps, setProps] = useState(props)
    const { onSubmit, update, open, onClose, user } = props;
    const { oldSub, newSub, newData } = update;
    const [updatedKeys, setUpdatedKeys] = useState([]);
    const [uninviteDialogOpen, setUninviteDialogOpen] = useState(false);
    const [uninvites, setUninvites] = useState([]);

    const [confirmField, setConfirmField] = useState("")

    const [passwordError, setPasswordError] = useState(false)

    const [passwordVerifying, setPasswordVerifying] = useState(false)
    const limitExceeded = useRef();
    const classes = useStyles();

    const modelComponents = {
        "product": () => (
            <>
                <Paper elevation={3} className={classes.panel}>
                    <Typography variant="subtitle2">
                        {newData["product"].name}
                    </Typography>
                    <Typography variant="caption">
                        {newData["product"].description}
                    </Typography>
                </Paper>
            </>
        ),
        "plan": () => (
            <>
                {
                    limitExceeded.current ?
                        <>
                            &nbsp;&nbsp;<a onClick={() => setUninviteDialogOpen(true)} className={classes.uninviteLink}>Edit invites</a>
                        </>
                        : ""
                }
                <Paper elevation={3} className={classes.panel}>
                    <Typography variant="subtitle2">
                        {`${newData["plan"].nickname}`} Plan
                </Typography>
                    <Typography variant="caption">
                        <strong style={{ color: "#6b95f4", display: "block" }}>
                            ${(newData["plan"].amount / 100).toFixed(2)} / {newData["plan"].interval}
                        </strong>
                        {newData["plan"].description}
                    </Typography>
                </Paper>
            </>
        ),
        "collection_method": () => (
            <>
                {
                    newSub["collection_method"] === "send_invoice" ?
                        <Typography variant="subtitle2">
                            Send Invoice <i className={clsx("fas fa-file-invoice-dollar", classes.icon)} />
                        </Typography>
                        :
                        newSub["collection_method"] === "charge_automatically" ?
                            <Typography variant="subtitle2">
                                Charge Automatically <i className={clsx("fas fa-sync-alt", classes.icon)} />
                            </Typography>
                            : ""

                }

            </>
        ),
        "default_payment_method": () => {
            if (!newData["default_payment_method"]) {
                return (
                    <Typography variant="subtitle2">
                        Your Default Payment Method
                    </Typography>
                )
            }

            const method = newData["default_payment_method"];

            return (
                <Paper elevation={3}>

                    <ListItem>
                        <ListItemIcon>
                            <i className={`fab fa-cc-${method.card.brand.toLowerCase().split(" ").join("-")}`} />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    <div>
                                        ••••••••••••{method.card.last4}
                                    </div>

                                </>
                            }
                            secondary={
                                <>
                                    {method.card.funding.charAt(0).toUpperCase().concat(method.card.funding.slice(1))} | Expires {method.card.exp_month} / {method.card.exp_year}
                                </>
                            }
                        />
                    </ListItem>
                </Paper>
            )
        },
        "cancel_at_period_end": () => (
            <Typography variant="subtitle2" color={newSub["cancel_at_period_end"] ? "secondary" : "primary"}>
                {newSub["cancel_at_period_end"] ?

                    "Cancels Automatically"
                    :
                    "Renews Automatically"
                }
            </Typography>
        )
    }


    const handlePasswordVerify = async () => {
        setPasswordError(false)
        setPasswordVerifying(true);

        try {
            // Verify Password
            await Auth.signIn(user.email, confirmField);

            onSubmit({ ...newSub, uninvites });
        } catch (e) {
            setPasswordError(true)
        }
        setPasswordVerifying(false)
    }


    useEffect(() => {

        const verifyInvites = async () => {
            const invites = await getUserInvites(user.user_id);
            if (invites.length > parseInt(newData["plan"].metadata.INVITE_LIMIT)) {
                setUninviteDialogOpen(true);
                limitExceeded.current = true
            }
        }

        if (!oldSub || !newSub || !newData) return;

        let tmp = [];

        for (const key of Object.keys(subModel)) {
            if (oldSub[key] != newSub[key]) tmp.push(key)
        }

        // If a new product was chosen, show all keys
        if (tmp.includes("product")) tmp = Object.keys(subModel);

        setUpdatedKeys([...tmp])

        // Retrieve the customer's number of invites and if it is over their new product's invite limit, open the uninvite dialog
        verifyInvites()


    }, [props.update])



    useEffect(() => {
        setProps({ ...props })
    }, [props])

    return (
        <>

            {
                (!oldSub || !newSub || !newData) ?
                    <></>
                    :
                    <>

                        <Dialog
                            onClose={onClose}
                            open={open}
                            classes={{ paper: classes.paper }}

                        >
                            <DialogTitle >Confirm your Subscription</DialogTitle>
                            <div className={classes.dialog}>
                                {
                                    updatedKeys.map((key, idx) => (
                                        <div key={key + idx}>
                                            <Typography color="textSecondary" style={{ fontSize: "1rem" }} variant="overline" >
                                                {key.split("_").join(" ")}
                                            </Typography>

                                            {modelComponents[key]()}

                                        </div>
                                    ))
                                }
                                <div className={classes.margin}>
                                    <Typography className={classes.margin} color="textSecondary" style={{ fontSize: "1rem" }} variant="overline" >
                                        Password
                                </Typography>
                                    <ViewablePasswordField
                                        value={confirmField}
                                        onChange={(e) => setConfirmField(e.target.value)}
                                        fullWidth
                                        placeholder="Your Password"
                                        error={passwordError}
                                        helperText={passwordError ? "The entered password is incorrect." : ""}
                                    />
                                </div>

                                <Button
                                    className={classes.button}
                                    fullWidth
                                    onClick={handlePasswordVerify}
                                    disabled={passwordVerifying}
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: "#2ddaa2" }}
                                >
                                    {"Confirm"}
                                </Button>
                                <Slide direction="up" in={uninviteDialogOpen} mountOnEnter unmountOnExit>
                                    <Paper elevation={4} className={classes.slide}>
                                        <Typography align="center" variant="h5" color="primary">Client Invites</Typography>

                                        <SelectUserInvites
                                            limit={parseInt(newData["plan"].metadata.INVITE_LIMIT)}
                                            onSubmit={(uninvites) => {
                                                setUninviteDialogOpen(false);
                                                setUninvites(uninvites)
                                                limitExceeded.current = false;
                                            }}
                                            user_id={user.user_id}
                                            uninvites={uninvites}
                                        />
                                    </Paper>
                                </Slide>
                            </div>
                        </Dialog>
                    </>

            }


        </>
    )
}