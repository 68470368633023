import React, { useState, useEffect, useRef } from "react"
import NavigationPrompt from "react-router-navigation-prompt";
import PersonalForm from "./PersonalForm"
import ClientForm from "./ClientForm"
import RequestNotification from "./RequestNotification"
import ConfirmationModal from "./ConfirmationModal"
import Typography from "@material-ui/core/Typography"
import ViewablePasswordField from "./ViewablePasswordField"
import ChangePasswordDialog from "./ChangePasswordDialog"
import Button from "@material-ui/core/Button"
import makeStyles from "@material-ui/styles/makeStyles"
import { Auth } from "aws-amplify"
import { s3Update, updateUser, deleteUser, s3GetDownloadURL } from "../libs/awsLib";

const useStyles = makeStyles({
    infoSection: {
        margin: "1rem 0"
    },
    button: {
        marginLeft: "1rem"
    },
    section: {
        margin: "2rem 0"

    }
})

const userSavingAlertMap = {
    "success": "Your user info has been successfully updated!",
    "error": "An error occurred when attempting to update your information.",
    "loading": "Saving user information..."

}

const userDeletingAlertMap = {
    "success": `Your user account was successfully deleted. Logging out...`,
    "error": "An error occurred when attempting to delete your account.",
    "loading": "Deleting user account..."
}

const passChangeAlertMap = {
    "success": `Your password was successfully changed!`,
    "error": "An error occurred when attempting to change your password.",
    "loading": "Changing password..."
}

export default function UserSettings(props) {
    const { user, logout, storeUser } = props;

    const initialUser = useRef()

    const { name, email, phone, company, logo } = user

    const logoKey = useRef()

    const [updatedUser, setUpdatedUser] = useState({ name, email, phone, company, logo: null });

    const [actionPending, setActionPending] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [changePassDialogOpen, setChangePassDialog] = useState(false);

    const [requestDialogOpen, setRequestDialogOpen] = useState(false);

    const [requestStatus, setRequestStatus] = useState("error");

    const [requestText, setRequestText] = useState(userSavingAlertMap);

    const [confirmField, setConfirmField] = useState("")

    const [formComplete, setFormComplete] = useState(true)

    const [passwordError, setPasswordError] = useState(false)

    const [passwordVerifying, setPasswordVerifying] = useState(false)

    const classes = useStyles()

    const saveUser = async () => {

        // Show that an action is pending
        setActionPending(true)

        // Map user deleting request text
        setRequestText(userSavingAlertMap)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

        const { logo_file, company, name, phone } = updatedUser
        const updatedUserPayload = { company, name, phone }

        // Save the user
        try {
            // Update the S3 object if logo changed
            let newLogo;
            if (logo_file) {
                const result = await s3Update(logo_file, "public", logoKey.current);
                if (!result.error) newLogo = result
                else throw {}

            }

            if (newLogo) updatedUserPayload["logo"] = newLogo;

            const res = await updateUser(user.user_id, updatedUserPayload, user.type)
            if (res.error) setRequestStatus("error")
            else {
                setRequestStatus("success")
                // Update the initial user
                initialUser.current = updatedUser;
                const authUser = await Auth.currentAuthenticatedUser()
                await storeUser(authUser)
            }
        } catch (e) {
            setRequestStatus("error")
        }


        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)


    }

    const removeUser = async () => {
        // Close the delete dialog
        setDeleteDialogOpen(false)

        // Map user deleting request text
        setRequestText(userDeletingAlertMap)

        // Show that an action is pending
        setActionPending(true)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)


        // Delete the user
        try {


            const res = await deleteUser(user)
            if (res.error) throw {}
            else setRequestStatus("success")

            // Show that the action is no longer pending
            setActionPending(false)

            // Open the Request Notification dialog
            setRequestDialogOpen(true)

            // After ___ seconds, logout
            await new Promise(resolve => setTimeout(resolve, 4000))

            await logout();
        } catch (e) {
            setRequestStatus("error")
            setConfirmField("")
        }

        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)

    }

    const handleUpdatedUser = (user) => {
        const { name, email, phone, company, logo, logo_file } = user

        setFormComplete(user.complete)
        setUpdatedUser({ name, email, phone, company, logo, logo_file })

    }

    const handlePasswordVerify = async () => {
        setPasswordError(false)
        setPasswordVerifying(true);

        try {
            // Verify Password
            await Auth.signIn(user.email, confirmField);

            removeUser();
        } catch (e) {
            setPasswordError(true)
        }
        setPasswordVerifying(false)
    }

    async function handlePostPasswordChange() {

        setChangePassDialog(false)

        // Map user deleting request text
        setRequestText(passChangeAlertMap)

        setRequestStatus("success")

        // Show that the action is no longer pending
        setActionPending(false)

        // Open the Request Notification dialog
        setRequestDialogOpen(true)
    }

    useEffect(() => {

        async function loadLogo(logoKey) {
            const tmp = updatedUser;
            tmp["logo"] = await s3GetDownloadURL(logoKey);
            initialUser.current = { ...tmp };
            setUpdatedUser({ ...tmp })
        }

        initialUser.current = { ...updatedUser };

        logoKey.current = logo;
        if (logo) loadLogo(logo)
        else setUpdatedUser({ ...updatedUser })


    }, [])
    return (
        <>
            <RequestNotification
                status={requestStatus}
                text={actionPending ? requestText["loading"] : requestText[requestStatus]}
                open={requestDialogOpen}
                setOpen={setRequestDialogOpen}
                loading={actionPending}
            />

            <ChangePasswordDialog
                open={changePassDialogOpen}
                setOpen={setChangePassDialog}
                onPasswordChange={handlePostPasswordChange}
            />

            <ConfirmationModal
                open={deleteDialogOpen}
                title={"Are you sure you want to delete your account?"}
                text={"Your ADUROI account will be permanently deleted, along with any active subscriptions and saved evaluations. Any existing clients will also lose access. If you still wish to continue, enter your password below."}

                setOpen={setDeleteDialogOpen}
                handleConfirm={handlePasswordVerify}
                disabled={passwordVerifying}
            >
                <ViewablePasswordField
                    value={confirmField}
                    onChange={(e) => setConfirmField(e.target.value)}
                    fullWidth
                    placeholder="Your Password"
                    className={classes.infoSection}
                    error={passwordError}
                    helperText={passwordError ? "The entered password is incorrect." : ""}

                />
            </ConfirmationModal>

            <NavigationPrompt when={deleteDialogOpen || !(JSON.stringify(initialUser.current) == JSON.stringify(updatedUser))} afterCancel={() => console.log("cancel")} afterConfirm={() => console.log("confirm")}>
                {({ onConfirm, onCancel }) => (
                    <ConfirmationModal
                        open={true}
                        setOpen={onCancel}
                        handleConfirm={onConfirm}
                        text="Account creation is incomplete. Are you sure you want to leave this page?"
                    />
                )}
            </NavigationPrompt>
            <div className={classes.section}>

                {/* Personal Account Action Buttons */}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div>
                        {
                            user.type === "personal" ?
                                <>
                                    <Button
                                        onClick={!(actionPending || (JSON.stringify(initialUser.current) === JSON.stringify(updatedUser)) || !formComplete) ? saveUser : null}
                                        disabled={actionPending || (JSON.stringify(initialUser.current) === JSON.stringify(updatedUser)) || !formComplete} className={classes.button} size="small"
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Save&nbsp;<i className="fas fa-save fa-xs" />
                                    </Button>
                                    <Button onClick={() => setDeleteDialogOpen(true)} disabled={actionPending} className={classes.button} variant="outlined" size="small" color="secondary">
                                        Delete User&nbsp;<i className="far fa-trash-alt fa-xs" />
                                    </Button>
                                </>

                                : ""
                        }
                        <Button onClick={() => setChangePassDialog(true)} disabled={actionPending} className={classes.button} variant="outlined" size="small" color="default">
                            Change Pass&nbsp;<i className="fas fa-key fa-xs" />
                        </Button>
                    </div>

                </div>

                {/* Account Info */}
                <Typography variant="overline" component="h5" style={{ fontSize: "1.1rem" }}>User Information</Typography>


                {
                    user.type === "personal" ?
                        <>
                            <PersonalForm
                                user={updatedUser}
                                onChange={(updatedUser) => handleUpdatedUser({ ...updatedUser })}
                                disabled={["email"]}
                            />
                        </>
                        :

                        <>

                            <ClientForm
                                user={updatedUser}
                                disabled={["name", "email"]}
                            />




                        </>

                }
            </div>

        </>
    )
}