import React, { useState, useEffect } from "react";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import PropTypes from "prop-types";

SearchBar.propTypes = {
    onChange: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    items: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string
}

SearchBar.defaultProps = {
    label: "Search",
    placeholder: "Find items...",
    items: []
}

export default function SearchBar(props) {

    const { items, onChange, filter, label, placeholder } = props;

    const [query, setQuery] = useState("");

    const clearQuery = () => {
        setQuery("");
        onChange(items)
    }

    const handleChange = (event) => {
        const { value } = event.target;
        onChange(items.filter((item) => filter(item, value)));
        setQuery(value)
    }

    return (
        <div style={{padding: "0 0 1rem 0"}}>
            <FormControl style={{width: "100%"}}>
                <InputLabel>{label}</InputLabel>
                <Input
                    onChange={handleChange}
                    value={query}
                    placeholder={placeholder}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    endAdornment={
                        <IconButton onClick={clearQuery} style={{marginRight: "0.5rem", marginBottom: "0.2rem"}} size="small" position="end">
                            <ClearIcon style={{fontSize: "1em"}}/>
                        </IconButton>
                    }
                />
            </FormControl>

        </div>
    )

}