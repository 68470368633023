import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from "@material-ui/core/Typography";
import { Auth } from "aws-amplify";

// Requiring specific props
EmailVerificationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onEmailConfirm: PropTypes.func.isRequired,
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    field: {
        margin: "1.5rem 0"
    },
    paper: {
        margin: 0,
        width: "90%"
    },
})

export default function EmailVerificationDialog(props) {
    const classes = useStyles();
    const [mainProps, setProps] = useState(props);
    const { open, onEmailConfirm, setOpen, email } = mainProps;
    const [codeVerifying, setCodeVerifying] = useState(false);
    const [requestError, setRequestError] = useState("");

    const [verificationData, setVerificationData] = useState({
        code: ""
    });

    // Handle changes to verification code input
    const handleCodeChange = (event) => {
        const { name, value } = event.target;

        const tmp = verificationData;
        tmp[name] = value;

        setVerificationData({ ...tmp })
    }

    // Verify the code
    const handleVerifyCode = async () => {

        try {
            await Auth.confirmSignUp(email, verificationData["code"]);

            setCodeVerifying(false);
            onEmailConfirm()


        } catch (e) {

            setRequestError(e.message)
            setCodeVerifying(false);
        }

    }

    // Close the dialog
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setProps(props)
        // Reset if the dialog is closed
        setVerificationData({
            code: ""
        })
        setRequestError("")

    }, [props])

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={(!codeVerifying) ? handleClose : () => { }}
                classes={{ paper: classes.paper }}

            >


                <DialogTitle>{"Verify Your Email"}</DialogTitle>

                <DialogContent>

                    {

                        <>
                            {/* VERIFICATION CODE */}
                            <TextField
                                fullWidth
                                label="Verification Code (Check your email)"
                                className={classes.field}
                                onChange={handleCodeChange}
                                value={verificationData["code"]}
                                name="code"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><i className="fas fa-fingerprint"></i></InputAdornment>,
                                }}
                                variant="outlined"
                            />

                        </>
                    }

                    <Typography
                        color="secondary"
                        variant="caption"
                        component="p"
                    >
                        {requestError}
                    </Typography>

                </DialogContent>



                <DialogActions>


                    <>
                        <Button disabled={codeVerifying} onClick={handleClose} color="default">
                            Cancel
                                </Button>
                        <Button disabled={codeVerifying} onClick={handleVerifyCode} color="primary">
                            Submit
                        </Button>
                    </>

                </DialogActions>


            </Dialog>
        </div>
    )
}