import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';

// Requiring specific props
ConfirmationModal.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

ConfirmationModal.defaultProps = {
  title: "Confirmation"
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationModal(props) {

  const [mainProps, setProps] = useState(props);
  const { open, title, text, setOpen, handleConfirm, disabled } = mainProps;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setProps(props)
  }, [props])

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {
            text ?
              <DialogContentText>
                {text}
              </DialogContentText>

              : ""
          }
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button disabled={disabled} onClick={!disabled ? handleConfirm : null} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}