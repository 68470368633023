import React from "react";
import NumberFormat from "react-number-format";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { TextField } from "mui-rff";

function PercentField(props) {
    const { name, ...rest } = props;
    return (
        <Field
            name={name}
            parse={value => (value / 100)}
            format={value => (value * 100).toFixed(2)}
            component={
                ({ input, meta }) => {
                    return (
                        <NumberFormat
                            onValueChange={(values) => input.onChange(values.floatValue)}
                            {...rest}
                            error={meta.error ? true : false}
                            helperText={meta.error}
                            value={input.value}
                            customInput={TextField}
                            thousandSeparator
                            suffix="%"
                        />)
                }
            }
        />

    )
}

export default PercentField;
