import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import SearchBar from "./SearchBar"
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import {getUserInvites} from "../libs/awsLib"

import PropTypes from "prop-types"

SelectUserInvites.propTypes = {
    user_id: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string ]),
    uninvites: PropTypes.array,
}

SelectUserInvites.defaultProps = {
    height: "unset",
    uninvites: []
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
    sideMargin: {
        margin: "0 1rem"
    },
    selectIcon: {
        marginRight: "1rem"
    },
    textField: {
        margin: "1rem 0"
    }
}));

const inviteStatusIcons = {
    inactive: (
        <RadioButtonChecked style={{ color: "#04cf04" }} />
    ),
    active: (
        <RadioButtonChecked style={{ color: "rgb(255, 188, 0)" }} />
    )
}

const inviteStatusMessage = {
    active: "Inactive",
    inactive: "Active"
}

export default function SelectUserInvites(props) {

    const { user_id, limit, onSubmit, height, uninvites } = props;
    const classes = useStyles();
    const [unselectedInvites, setUnselectedInvites] = React.useState(uninvites);
    const [invites, setInvites] = useState([]);
    const [filteredInvites, setFilteredInvites] = useState([]);

    const handleInviteClick = (invite) => {
        let id = invite.code;

        const tmp = unselectedInvites;
        const index = tmp.indexOf(id);

        // If invite already in unselected, remove from unselected
        if (index > -1) tmp.splice(index, 1);

        // Else, add to unselected 
        else tmp.push(id)

        setUnselectedInvites([...tmp])
    };

    const simpleTime = (unix_timestamp, daysAhead = 0) => {
        var a = new Date(unix_timestamp * 1000);
        var months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
        var month = months[a.getMonth()];
        var year = a.getFullYear();

        var date = a.getDate() + daysAhead;

        var time = month + ' ' + date + ' ' + year;
        return time;
    }

    useEffect(() => {
        async function onLoad() {
            const invites = await getUserInvites(user_id)
            setInvites(invites);
            setFilteredInvites(invites)
        }

        onLoad()
    }, [])

    return (
        <>

            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <>
                        <ListSubheader disableGutters component="div" >
                            My Invites - <strong><i>Please deselect <span>{(invites.length - unselectedInvites.length) - limit}</span> more invite(s)</i></strong>
                        </ListSubheader>
                        <SearchBar
                            items={invites}
                            placeholder="Find invites..."
                            onChange={(filtered) => setFilteredInvites(filtered)}
                            filter={(invite, query) => {
                                let { name, email } = invite;
                                name = name.toLowerCase()
                                email = email.toLowerCase()

                                const mq = query.toLowerCase();

                                if (name.includes(mq) || email.includes(mq)) return true

                                return false
                            }}
                        />

                    </>
                }
                className={classes.root}
                style={{ height }}
            >

                {
                    filteredInvites.map((invite, idx) => (
                        <span key={idx}>
                            <ListItem
                                button
                                selected={!unselectedInvites.includes(invite.code)}
                                onClick={() =>
                                    handleInviteClick(invite)
                                }>
                                {
                                    !unselectedInvites.includes(invite.code) ?
                                        <CheckCircle style={{ color: "#04cf04" }} className={classes.selectIcon} />
                                        :
                                        <CheckCircleOutline style={{ color: "#cfcccc" }} className={classes.selectIcon} />

                                }
                                <ListItemText
                                    primary={
                                        <>
                                            {invite.name} | <Typography color="textSecondary" component={"span"} >{invite.email}</Typography>
                                        </>
                                    }
                                    secondary={
                                        <>
                                            <span style={{ display: "block", margin: "0.2rem 0" }}>
                                                {
                                                    !invite.active ?
                                                        <>
                                                            Last login on {simpleTime(invite.lastLogin)}
                                                        </>
                                                        :

                                                        <>
                                                            Invite sent on {simpleTime(invite.createdAt)}
                                                        </>
                                                }

                                            </span>
                                        </>
                                    }
                                />
                                <Typography className={classes.sideMargin} variant="overline" color="textSecondary">
                                    {inviteStatusMessage[invite.active ? "active" : "inactive"]}
                                </Typography>
                                {inviteStatusIcons[invite.active ? "active" : "inactive"]}
                            </ListItem>


                        </span>
                    ))
                }

            </List>
            <Button
                className={classes.textMargin}
                fullWidth
                disabled={(invites.length - unselectedInvites.length) > limit}
                variant="contained"
                color="primary"
                onClick={() => onSubmit(unselectedInvites)}
            >
                {"Save"}
            </Button>
        </>
    )
}