const dev = {
  STRIPE_KEY: "pk_test_i0JfCUYX4vwIty3klZxObmgA",
  s3: {
    REGION: "us-west-2",
    BUCKET: "notes-app-uploads-demoapp"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://on4pa4oc1e.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_tuZ4rbKYe",
    APP_CLIENT_ID: "3qigbht6p8eap0tscj38tiaopb",
    IDENTITY_POOL_ID: "us-west-2:463534ba-6e88-42de-aa87-c625f083b5dd"
  }
};

const prod = {
  STRIPE_KEY: "pk_live_tAbLocuhkyEjZbaTdIa30Nuq",
  s3: {
    REGION: "us-west-2",
    BUCKET: "notes-app-uploads-demoapp"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://exp0z963a4.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_kcIbkj8h4",
    APP_CLIENT_ID: "3oc9b5btvkdaj69g10q9s8gne3",
    IDENTITY_POOL_ID: "us-west-2:4881ab4c-7e88-48e8-8c08-3590cfbee42e"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};