import React from "react";
import { Link } from "react-router-dom";
import { fade, makeStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';


const useStyles = makeStyles((theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        menuItem: {
            display: "flex",
            justifyContent: "space-between",
            textDecoration: "none",
            color: "#337ab7",
            width: "200px"
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    }),
);

function NavBar(props) {
    const { isAuthenticated, logout, user } = props;
    const classes = useStyles();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {isAuthenticated && user ?
                <span>
                    <Link to="/settings">
                        <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
                            My Account&nbsp;
                                <i className="fas fa-user-cog fa-xs" style={{ color: "grey" }}></i>
                        </MenuItem>
                    </Link>
                    <MenuItem onClick={logout} className={classes.menuItem}>
                        Logout&nbsp;
                        <i className="fas fa-sign-out-alt fa-xs" style={{ color: "grey" }}></i>
                    </MenuItem>
                </span>
                :
                <span>
                    <Link to="/signup">
                        <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
                            Sign Up&nbsp;
                                <i className="fas fa-user-plus fa-xs" style={{ color: "grey" }}></i>
                        </MenuItem>
                    </Link>

                    <Link to="/login">
                        <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
                            Login&nbsp;
                                <i className="fas fa-sign-in-alt fa-xs" style={{ color: "grey" }}></i>
                        </MenuItem>
                    </Link>
                </span>
            }
        </Menu>
    );

    return (
        <>
            <AppBar style={{ backgroundColor: "#f8f8f8" }} position="fixed">
                <Toolbar >
                    <Link to="/">
                        <Typography variant="h6" style={{ color: "grey" }}>
                            ADUROI
                         </Typography>
                    </Link>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {
                            isAuthenticated && user ?
                                <span>
                                    <Link to="/settings">
                                        <Tooltip title="Account Settings">

                                            <IconButton>
                                                <i className="fas fa-user-cog fa-xs"></i>
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <Tooltip title="Logout">
                                        <IconButton onClick={logout}>
                                            <i className="fas fa-sign-out-alt fa-xs"></i>
                                        </IconButton>
                                    </Tooltip>
                                </span>

                                :

                                <span>
                                    <Link to="/signup">
                                        <Tooltip title="Sign Up">
                                            <IconButton>
                                                <i className="fas fa-user-plus fa-xs"></i>
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <Link to="/login">
                                        <Tooltip title="Login">

                                            <IconButton>
                                                <i className="fas fa-sign-in-alt"></i>
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                </span>


                        }



                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon style={{ color: "grey" }} />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}

        </>

    )

}

export default NavBar;
