import React, { useState, useEffect } from "react";
import axios from "axios";

const AduSummary = () => {
  const [spreadsheetData, setSpreadsheetData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://ioalkv1spi.execute-api.us-west-2.amazonaws.com/production/sheets-get`
      )
      .then(res => {
        const data = res.data;
        // console.log("data", data);
        setSpreadsheetData(data);
      });
  }, [setSpreadsheetData]);

  return (
    <div>
      <br />
      Data
      {spreadsheetData !== undefined && (
        <div>
          {spreadsheetData.map(item => {
            return (
              <div>
                <span>{item.noteid}</span>
                <span>{item.nickname}</span>
                <span>{item.purchasePrice}</span>
                <span>{item.noteid}</span>
                <span>{item.noteid}</span>
                <span>{item.noteid}</span>
                <span>{item.noteid}</span>
                <span>{item.noteid}</span>
                <span>{item.noteid}</span>
                <span>{item.noteid}</span>
                <span>{item.noteid}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AduSummary;
