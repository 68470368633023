import React from "react";
import Button from "@material-ui/core/Button"
import "./LoaderButton.css";

export default function LoaderButton({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      disabled={disabled || isLoading}
      {...props}
    >

      {props.children}&nbsp;&nbsp;{isLoading && <i className="fas fa-circle-notch fa-spin"></i>}
    </Button>
  );
}