import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify"
import UserPaymentMethods from "./UserPaymentMethods"
import { Typography, InputLabel, Select, MenuItem, Button, Icon } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles"
import PropTypes from "prop-types"
import { getProducts } from "../libs/awsLib";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

SubscriptionForm.propTypes = {
    subscription: PropTypes.shape({
        cancel_at_period_end: PropTypes.bool,
        default_payment_method: PropTypes.string,
        collection_method: PropTypes.oneOf(["charge_automatically", "send_invoice"]),
        plan: PropTypes.shape({
            id: PropTypes.string
        })

    }),
    customer_id: PropTypes.string,
    onSubmit: PropTypes.func
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    field: {
        margin: "1.5rem 0"
    },
    input: {
        width: "100%"
    },
    switch: {
        justifyContent: "space-between",
        margin: 0
    }
}))

export default function SubscriptionForm(props) {
    const { subscription, customer_id, onSubmit } = props;
    const classes = useStyles();
    const { cancel_at_period_end, default_payment_method, collection_method, plan, start_date } = subscription;
    const initialSub = useRef()
    const [subData, setSubData] = useState({
        cancel_at_period_end,
        default_payment_method,
        collection_method,
        plan: plan.id,
        product: subscription.plan.product.id
    });
    const [isComplete, setComplete] = useState(true)
    const [loading, setLoading] = useState({
        main: true,
        products: true,
        plans: true
    });


    const [products, setProducts] = useState([])
    const [plans, setPlans] = useState([])
    const [newSubData, setNewSubData] = useState({})
    const [currentPlan, setCurrentPlan] = useState({})

    const handleSubmit = () => {

        const tmp = newSubData;

        // Save new product info
        for (const product of products) {
            if (product.id == subData["product"]) {
                tmp["product"] = product;
            }
        }

        // Save new plan info
        for (const plan of plans) {
            if (plan.id == subData["plan"]) tmp["plan"] = plan;
        }

        onSubmit({
            oldSub: initialSub.current,
            newSub: subData,
            newData: tmp
        })

    }

    const handleChange = (event) => {
        const { value, name } = event.target;

        const tmp = subData;
        tmp[name] = value;

        setSubData({ ...tmp })

        if (tmp.plan.length) setComplete(true);
        else setComplete(false)
    }

    const handleSwitchChange = (event) => {
        const { checked, name } = event.target;
        handleChange({
            target: {
                name,
                value: checked
            }
        })
    }

    const handlePaymentChange = (event, method) => {

        handleChange(event);
        const { name } = event.target;

        const tmp = newSubData;
        tmp[name] = method;
        setNewSubData({ ...tmp })
    }

    // const handleProductChange = (event) => {    
    //     // Reset the current selected plan
    //     const tmp = subData;
    //     tmp["plan"] = '';
    //     setSubData({...tmp})

    //     // Show the plans for this product
    //     loadPlans(event.target.value, products)

    //     handleChange(event);
    // }

    const handlePlanChange = (e) => {
        const { value: plan, name } = e.target;

        setCurrentPlan(plan)
        handleChange({
            target: {
                name,
                value: plan.id
            }
        })
    }

    const getCancelLabel = (cancel) => {
        let label;

        if (!cancel) label = "Don't Cancel"
        else {
            let sub_start1 = new Date(start_date * 1000)
            let sub_start2 = new Date(start_date * 1000)
            let startPlusMonth = new Date(sub_start1.setMonth(sub_start1.getMonth() + 1))
            let startPlusYear = new Date(sub_start2.setYear(sub_start2.getFullYear() + 1))

            switch (currentPlan["interval"]) {
                case "month":
                    label = `Cancel on ${startPlusMonth.toDateString()}`;
                    break;
                case "year":
                    label = `Cancel on ${startPlusYear.toDateString()}`;
                    break
                default:
                    label = "Don't Cancel"
            }
        }

        return label;
    }


    async function loadPlans(product_id, products) {

        for (const product of products) {
            if (product.id === product_id) {
                setPlans(product.plans)

                if(!currentPlan.id) {
                    for (const availablePlan of product.plans) {
                        if(availablePlan.id === plan.id) {
                            setCurrentPlan(availablePlan);
                            break;
                        }
                    }
                }

                break;
            }
        }


        const tmp = loading;
        tmp["plans"] = false;
        setLoading({ ...tmp });
    }

    async function loadProducts(product_id) {

        const products = await getProducts();

        setProducts(products);

        const tmp = loading;
        tmp["products"] = false;
        setLoading({ ...tmp });

        loadPlans(product_id, products);
    }

    useEffect(() => {

        const oldSub = {
            cancel_at_period_end,
            default_payment_method,
            collection_method,
            plan: plan.id,
            product: subscription.plan.product.id
        }

        initialSub.current = oldSub;

        const tmp = loading;
        tmp["main"] = false;
        setLoading({ ...tmp })

        loadProducts(oldSub.product);

    }, [])

    return (
        <>
            <div className={classes.root}>
                {
                    !loading["main"] && (
                        <>
                            <div className={classes.field}>
                                {/* Select Subscription */}
                                <InputLabel>Product</InputLabel>
                                <Select
                                    disabled
                                    onChange={() => { }}
                                    className={classes.input}
                                    name={"product"}
                                    displayEmpty
                                    value={subData["product"]}>
                                    {
                                        loading["products"] &&
                                        <MenuItem value="loading">Loading&nbsp;<Icon style={{ fontSize: "inherit", color: "rgb(107, 149, 244)" }} className="fas fa-circle-notch fa-spin" /></MenuItem>

                                    }
                                    {
                                        products.map((product, idx) => (
                                            <MenuItem key={product + idx} value={product.id}>
                                                <Typography>
                                                    {product.name}
                                                </Typography>
                                            </MenuItem>
                                        ))
                                    }
                                    {
                                        products.length === 0 &&
                                        <MenuItem value="">
                                            <em>No products found</em>
                                        </MenuItem>
                                    }

                                </Select>
                            </div>

                            <div className={classes.field}>
                                {/* Select Pricing Plan */}
                                <InputLabel>Pricing Plan</InputLabel>
                                <Select
                                    onChange={handlePlanChange}
                                    className={classes.input}
                                    name={"plan"}
                                    value={currentPlan}
                                >
                                    {
                                        loading["plans"] &&
                                        <MenuItem value="loading">Loading&nbsp;<Icon style={{ fontSize: "inherit", color: "rgb(107, 149, 244)" }} className="fas fa-circle-notch fa-spin" /></MenuItem>

                                    }
                                    {
                                        plans.map((plan, idx) => (
                                            <MenuItem key={plan + idx} value={plan}>
                                                {`${plan.nickname}`} Plan&nbsp;·&nbsp;<strong style={{ color: "#6b95f4" }}> ${(plan.amount / 100).toFixed(2)} / {plan.interval}</strong>
                                            </MenuItem>
                                        ))
                                    }
                                    {
                                        plans.length === 0 &&
                                        <MenuItem value="">
                                            <em>No plans found</em>
                                        </MenuItem>
                                    }
                                </Select>
                            </div>

                            <div className={classes.field}>
                                {/* Select Collection Method */}
                                <InputLabel>Collection Method</InputLabel>
                                <Select
                                    onChange={handleChange}
                                    className={classes.input}
                                    name={"collection_method"}
                                    value={subData["collection_method"]}
                                >
                                    <MenuItem value="charge_automatically">Charge Automatically</MenuItem>
                                    <MenuItem value="send_invoice">Send Invoice</MenuItem>
                                </Select>
                            </div>

                            <div className={classes.field}>
                                {/* Toggle 'Cancel At Period End' */}
                                <FormControl fullWidth component="fieldset">
                                    <FormLabel component="legend">Cancel At Period End</FormLabel>
                                    <FormGroup>

                                        <FormControlLabel
                                            control={<Switch disabled={!currentPlan.id} checked={subData["cancel_at_period_end"] ? true : false} onChange={handleSwitchChange} name="cancel_at_period_end" color="primary" />}
                                            label={
                                                <i>{getCancelLabel(subData["cancel_at_period_end"])}</i>
                                            }
                                            labelPlacement="start"
                                            classes={{
                                                root: classes.switch
                                            }}
                                        />
                                    </FormGroup>

                                </FormControl>
                            </div>

                            {/* Select Default Payment Method */}
                            <UserPaymentMethods
                                customer_id={customer_id}
                                onChange={(method) => {
                                    handlePaymentChange({
                                        target: {
                                            name: "default_payment_method",
                                            value: method ? method.id : null
                                        }
                                    }, method)
                                }}
                                selected={subData["default_payment_method"]}
                            />

                            {/* Submit Button */}
                            <Button
                                className={classes.field}
                                fullWidth
                                disabled={!isComplete || Object.is(JSON.stringify(initialSub.current), JSON.stringify(subData))}
                                onClick={isComplete ? handleSubmit : null}
                                variant="contained"
                                color="primary"
                            >
                                {"Save"}
                            </Button>
                        </>

                    )
                }
            </div>
        </>
    )

}