import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types"

CardExpField.propTypes = {
    expMonth: PropTypes.number,
    expYear: PropTypes.number,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

CardExpField.defaultProps = {
    error: "",
}

function CardExpField(props) {
    const { expMonth, expYear, error, onChange } = props;

    const value = expMonth.toString().concat(expYear.toString())
    function limit(val, max) {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    function cardExpiry(val) {
        let month, year;

        console.log(val)

        let singleMonth = val.substr(0, 1);
        let doubleMonth = val.substr(0, 2);

        if (parseInt(doubleMonth) > 12) {
            month = `0${singleMonth}`;
            year = val.substr(1, 4)
        } else {
            month = doubleMonth;
            year = val.substr(2, 4)
        }

        return month + (year.length ? ' / ' + year : '');
    }

    return (
        <NumberFormat
            format={cardExpiry}
            placeholder="MM / YYYY"
            customInput={TextField}
            label={"Card Expiry Date"}
            error={error.length ? true : false}
            helperText={error.length ? error : ""}
            value={value}
            fullWidth
            onValueChange={(values) => {
                const { formattedValue } = values;

                const exp_month_str = formattedValue.split(" / ")[0]
                const exp_year_str = formattedValue.split(" / ")[1]
                const exp_month = parseInt(exp_month_str)
                const exp_year = parseInt(exp_year_str)

                let complete = true;

                const today = new Date()

                if ((exp_month_str).concat(exp_year_str).length !== 6) complete = false;

                if (((today.getMonth() + 1) > exp_month) && (today.getFullYear() > exp_year)) complete = false;

                if (today.getFullYear() > exp_year) complete = false;

                onChange({
                    exp_month,
                    exp_year,
                    complete
                })
            }}
        />
    )

}

export default CardExpField;
