import React from "react";
import { Grid, Typography } from "@material-ui/core";

function Loading(props) {
    return (
        <div style={{ position: "absolute", width: "calc(100% - 30px)", height: "100%", display: "flex" }}>
            <div style={{ margin: "auto" }}>
                <Grid container>
                    <Grid item>
                        <div style={{ marginBottom: "5rem" }}>
                            <Typography variant={"h5"} align="center" color="textSecondary">
                                {props.text? props.text : "Loading"}
                    </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                            <i style={{ margin: "auto", fontSize: "5rem", color: "rgb(45, 218, 162)" }} className="fas fa-circle-notch fa-spin"></i>
                        </div>
                    </Grid>

                </Grid>
            </div>
        </div>
    )
}

export default Loading;