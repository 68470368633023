import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';

import PropTypes from "prop-types"
import LoadingMenu from "./LoadingMenu";
import SearchBar from "./SearchBar";
import { getUserInvites } from "../libs/awsLib"

UserInvites.propTypes = {
    user_id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
    sideMargin: {
        margin: "0 1rem"
    }
}));

const inviteStatusIcons = {
    inactive: (
        <RadioButtonChecked style={{ color: "#04cf04" }} />
    ),
    active: (
        <RadioButtonChecked style={{ color: "rgb(255, 188, 0)" }} />
    )
}

const inviteStatusMessage = {
    active: "Inactive",
    inactive: "Active"
}

export default function UserInvites(props) {

    const { user_id, onChange, updated, onLoaded } = props;
    const classes = useStyles();
    const [selectedInvite, setSelectedInvite] = React.useState({});
    const [isLoading, setLoading] = useState(true);
    const [invites, setInvites] = useState([]);
    const [filteredInvites, setFilteredInvites] = useState([]);


    const handleInviteClick = (invite) => {

        let id = invite.code;

        if (selectedInvite !== id) {
            setSelectedInvite(id);
            onChange(invite);
        } else {
            setSelectedInvite(null);
            onChange(null);
        }

    };

    const simpleTime = (unix_timestamp, daysAhead = 0) => {
        var a = new Date(unix_timestamp * 1000);
        var months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
        var month = months[a.getMonth()];
        var year = a.getFullYear();

        var date = a.getDate() + daysAhead;

        var time = month + ' ' + date + ' ' + year;
        return time;
    }

    useEffect(() => {
        async function onLoad() {
            const invites = await getUserInvites(user_id);
            setInvites(invites);
            setFilteredInvites(invites)
            setLoading(false);
            onLoaded()
        }

        onLoad()
    }, [])

    useEffect(() => {
        async function refresh() {
            setLoading(true);
            const invites = await getUserInvites(user_id);
            setInvites(invites);
            setFilteredInvites(invites)
            setLoading(false);
            onLoaded()
        }

        if(!updated) refresh()
        

    }, [updated])

    return (
        <>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <>
                        <ListSubheader component="div" id="nested-list-subheader">
                            My Invites
                    </ListSubheader>
                        <div style={{ padding: "0 1rem" }}>
                            <SearchBar
                                items={invites}
                                onChange={(filtered) => setFilteredInvites(filtered)}
                                placeholder={"Find Invites..."}
                                filter={(invite, query) => {
                                    let { name, email } = invite;
                                    name = name.toLowerCase()
                                    email = email.toLowerCase()

                                    const mq = query.toLowerCase();

                                    if (name.includes(mq) || email.includes(mq)) return true

                                    return false
                                }}
                            />
                        </div>
                    </>
                }
                className={classes.root}
            >

                {
                    isLoading ?
                        <LoadingMenu />
                        :
                        filteredInvites.length === 0 ? (
                            <div style={{ width: "100%", display: "flex", margin: "3rem 0" }}>
                                <div style={{ margin: "auto" }}>
                                    No invites found.
                            </div>
                            </div>
                        )

                            :
                            filteredInvites.map((invite, idx) => (
                                <span key={idx}>
                                    <ListItem
                                        button
                                        selected={selectedInvite === invite.code}
                                        onClick={() =>
                                            handleInviteClick(invite)
                                        }>
                                        <ListItemText
                                            primary={
                                                <>
                                                    {invite.name} | <Typography color="textSecondary" component={"span"} >{invite.email}</Typography>
                                                </>
                                            }
                                            secondary={
                                                <>
                                                    <span style={{ display: "block", margin: "0.2rem 0" }}>
                                                        {
                                                            !invite.active ?
                                                                <>
                                                                    Last login on {simpleTime(invite.lastLogin)}
                                                                </>
                                                                :

                                                                <>
                                                                    Invite sent on {simpleTime(invite.createdAt)}
                                                                </>
                                                        }

                                                    </span>
                                                </>
                                            }
                                        />
                                        <Typography className={classes.sideMargin} variant="overline" color="textSecondary">
                                            {inviteStatusMessage[invite.active ? "active" : "inactive"]}
                                        </Typography>
                                        {inviteStatusIcons[invite.active ? "active" : "inactive"]}
                                    </ListItem>


                                </span>
                            ))
                }


            </List>
        </>
    )
}