import React, { useState, useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper"
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from "@material-ui/styles/makeStyles"
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from "prop-types"

SlidePanel.propTypes = {
    direction: PropTypes.oneOf(["down", "left", "right", "up"]),
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

SlidePanel.defaultProps = {
    direction: "up"
}
const useStyles = makeStyles({

    slide: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        zIndex: 5,
    },

})


export default function SlidePanel(props) {


    const [mainProps, setProps] = useState(props)

    const { open, handleClose, direction, children } = props;

    const classes = useStyles();

    useEffect(() => {

        setProps({ ...props })

    }, [props])

    return (
        <>


            <Slide direction={direction} in={open} mountOnEnter unmountOnExit>
                <Paper elevation={10} className={classes.slide} style={{...props.style}}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton onClick={handleClose} aria-label="delete">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {children}
                </Paper>
            </Slide>

        </>
    )
}