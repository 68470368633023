import React, { useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify"
import UserPaymentMethods from "./UserPaymentMethods"
import { Typography, InputLabel, Select, MenuItem, Button, Icon } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles"
import PropTypes from "prop-types"
import { getProducts } from "../libs/awsLib";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ViewablePasswordField from "./ViewablePasswordField";


NewSubscriptionForm.propTypes = {
    customer_id: PropTypes.string,
    onSubmit: PropTypes.func
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    field: {
        margin: "1.5rem 0"
    },
    input: {
        width: "100%"
    },
    switch: {
        justifyContent: "space-between",
        margin: 0
    },
    margin: {
        margin: "1rem 0"
    }
}))

export default function NewSubscriptionForm(props) {
    const { customer_id, onSubmit, user } = props;
    const classes = useStyles();
    const [subData, setSubData] = useState();
    const [isComplete, setComplete] = useState(false)
    const [loading, setLoading] = useState({
        main: true,
        products: true,
        plans: true
    });

    const [products, setProducts] = useState([])
    const [plans, setPlans] = useState([])
    const [newSubData, setNewSubData] = useState({});
    const [isEmpty, setIsEmpty] = useState(false)
    const [currentPlan, setCurrentPlan] = useState({})

    const [confirmField, setConfirmField] = useState("")

    const [passwordError, setPasswordError] = useState(false)

    const [passwordVerifying, setPasswordVerifying] = useState(false)

    const handleSubmit = () => {

        const tmp = newSubData;

        // Save new product info
        for (const product of products) {
            if (product.id == subData["product"]) {
                tmp["product"] = product;
            }
        }

        // Save new plan info
        for (const plan of plans) {
            if (plan.id == subData["plan"]) tmp["plan"] = plan;
        }

        onSubmit({
            ...subData
        })

    }

    const handleChange = (event) => {
        const { value, name } = event.target;

        const tmp = subData;
        tmp[name] = value;

        setSubData({ ...tmp })

        if (tmp.plan.length && tmp.default_payment_method) setComplete(true);
        else setComplete(false)
    }

    const handleSwitchChange = (event) => {
        const { checked, name } = event.target;
        handleChange({
            target: {
                name,
                value: checked
            }
        })
    }

    const handlePlanChange = (e) => {
        const { value: plan, name } = e.target;

        setCurrentPlan(plan)
        handleChange({
            target: {
                name,
                value: plan.id
            }
        })
    }

    const getCancelLabel = (cancel) => {
        let label;

        if (!cancel) label = "Don't Cancel"
        else {
            let today1 = new Date()
            let today2 = new Date()
            let todayPlusMonth = new Date(today1.setMonth(today1.getMonth() + 1))
            let todayPlusYear = new Date(today2.setYear(today2.getFullYear() + 1))

            switch (currentPlan["interval"]) {
                case "month":
                    label = `Cancel on ${todayPlusMonth.toDateString()}`;
                    break;
                case "year":
                    label = `Cancel on ${todayPlusYear.toDateString()}`;
                    break
                default:
                    label = "Don't Cancel"
            }
        }

        return label;
    }

    const handlePaymentChange = (event, method) => {

        handleChange(event);
        const { name } = event.target;

        const tmp = newSubData;
        tmp[name] = method;
        setNewSubData({ ...tmp })
    }

    const handleProductChange = (event) => {
        // Reset the current selected plan
        const tmp = subData;
        tmp["plan"] = '';
        setSubData({ ...tmp })

        // Show the plans for this product
        loadPlans(event.target.value, products)

        handleChange(event);
    }

    async function loadPlans(product_id, products) {

        for (const product of products) {
            if (product.id === product_id) {
                setPlans(product.plans)
                break;
            }
        }

        const tmp = loading;
        tmp["plans"] = false;
        setLoading({ ...tmp });
    }

    async function loadProducts() {

        const products = await getProducts();

        if (!products.length) {
            setIsEmpty(true);
            return;
        }

        const firstProduct = products[0]
        const product_id = firstProduct.id;
        const plan_id = products[0].plans.length ? products[0].plans[0].id : null

        // Set initial sub  
        const initialSub = {
            cancel_at_period_end: false,
            default_payment_method: null,
            collection_method: "charge_automatically",
            plan: plan_id,
            product: product_id
        }

        setProducts(products);

        setSubData({ ...initialSub })

        const tmp = loading;
        tmp["main"] = false;
        tmp["products"] = false;

        setLoading({ ...tmp });

        loadPlans(product_id, products);
    }

    const handlePasswordVerify = async () => {
        setPasswordError(false)
        setPasswordVerifying(true);

        try {
            // Verify Password
            await Auth.signIn(user.email, confirmField);

            handleSubmit();
        } catch (e) {
            setPasswordError(true)
        }
        setPasswordVerifying(false)
    }

    useEffect(() => {

        loadProducts();

    }, [])

    return (
        <>
            <div className={classes.root}>
                {
                    !loading["main"] && (
                        <>
                            {
                                !isEmpty ?
                                    <>

                                        <div className={classes.field}>
                                            {/* Select Subscription */}
                                            <InputLabel>Product</InputLabel>
                                            <Select
                                                onChange={handleProductChange}
                                                className={classes.input}
                                                name={"product"}
                                                displayEmpty
                                                value={subData["product"]}>
                                                {
                                                    loading["products"] &&
                                                    <MenuItem value="loading">Loading&nbsp;<Icon style={{ fontSize: "inherit", color: "rgb(107, 149, 244)" }} className="fas fa-circle-notch fa-spin" /></MenuItem>

                                                }
                                                {
                                                    products.map((product, idx) => (
                                                        <MenuItem key={product + idx} value={product.id}>
                                                            <Typography>
                                                                {product.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))
                                                }
                                                {
                                                    products.length === 0 &&
                                                    <MenuItem value="">
                                                        <em>No products found</em>
                                                    </MenuItem>
                                                }

                                            </Select>
                                        </div>

                                        <div className={classes.field}>
                                            {/* Select Pricing Plan */}
                                            <InputLabel>Pricing Plan</InputLabel>
                                            <Select
                                                onChange={handlePlanChange}
                                                className={classes.input}
                                                name={"plan"}
                                                value={currentPlan}
                                            >
                                                {
                                                    loading["plans"] &&
                                                    <MenuItem value="loading">Loading&nbsp;<Icon style={{ fontSize: "inherit", color: "rgb(107, 149, 244)" }} className="fas fa-circle-notch fa-spin" /></MenuItem>

                                                }
                                                {
                                                    plans.map((plan, idx) => (
                                                        <MenuItem key={plan + idx} value={plan}>
                                                            {`${plan.nickname}`} Plan&nbsp;‧&nbsp;<strong style={{ color: "#6b95f4" }}> ${(plan.amount / 100).toFixed(2)} / {plan.interval}</strong>
                                                        </MenuItem>
                                                    ))
                                                }
                                                {
                                                    plans.length === 0 &&
                                                    <MenuItem value="">
                                                        <em>No plans found</em>
                                                    </MenuItem>
                                                }
                                            </Select>
                                        </div>

                                        <div className={classes.field}>
                                            {/* Select Collection Method */}
                                            <InputLabel>Collection Method</InputLabel>
                                            <Select
                                                onChange={handleChange}
                                                className={classes.input}
                                                name={"collection_method"}
                                                value={subData["collection_method"]}
                                            >
                                                <MenuItem value="charge_automatically">Charge Automatically</MenuItem>
                                                <MenuItem value="send_invoice">Send Invoice</MenuItem>
                                            </Select>
                                        </div>

                                        <div className={classes.field}>
                                            {/* Toggle 'Cancel At Period End' */}
                                            <FormControl fullWidth component="fieldset">
                                                <FormLabel component="legend">Cancel At Period End</FormLabel>
                                                <FormGroup>

                                                    <FormControlLabel
                                                        control={<Switch disabled={!currentPlan.id} checked={subData["cancel_at_period_end"] ? true : false} onChange={handleSwitchChange} name="cancel_at_period_end" color="primary" />}
                                                        label={
                                                            <i>{getCancelLabel(subData["cancel_at_period_end"])}</i>
                                                        }
                                                        labelPlacement="start"
                                                        classes={{
                                                            root: classes.switch
                                                        }}
                                                    />
                                                </FormGroup>

                                            </FormControl>
                                        </div>

                                        {/* Select Default Payment Method */}
                                        <UserPaymentMethods
                                            customer_id={customer_id}
                                            onChange={(method) => {
                                                handlePaymentChange({
                                                    target: {
                                                        name: "default_payment_method",
                                                        value: method ? method.id : null
                                                    }
                                                }, method)
                                            }}
                                            selected={subData["default_payment_method"]}
                                        />

                                        {/* Password Verification Field */}
                                        <div className={classes.field}>
                                            <Typography className={classes.margin} color="textSecondary" style={{ fontSize: "1rem" }} variant="overline" >
                                                Password
                                            </Typography>
                                            <ViewablePasswordField
                                                value={confirmField}
                                                onChange={(e) => setConfirmField(e.target.value)}
                                                fullWidth
                                                placeholder="Your Password"
                                                error={passwordError}
                                                helperText={passwordError ? "The entered password is incorrect." : ""}
                                            />
                                        </div>
                                        {/* Submit Button */}
                                        <Button
                                            className={classes.field}
                                            fullWidth
                                            disabled={!isComplete || passwordVerifying}
                                            onClick={isComplete ? handlePasswordVerify : null}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {"Save"}
                                        </Button>
                                    </>

                                    :
                                    <>
                                        <div style={{ width: "100%", display: "flex", margin: "3rem 0" }}>
                                            <div style={{ margin: "auto" }}>
                                                No subscription products were found.
                                            </div>
                                        </div>
                                    </>
                            }
                        </>
                    )
                }
            </div>
        </>
    )

}