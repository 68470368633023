import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function SubscribedRoute({ component: C, appProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        appProps.isAuthenticated && appProps.isSubscribed
          ?
          <C {...props} {...appProps} />
          :
          appProps.isAuthenticated && !appProps.isSubscribed && appProps.user.type === "personal" ?
            <Redirect
              to={`/settings/subscriptions`}
            />

            :
            appProps.isAuthenticated && !appProps.isSubscribed && appProps.user.type === "client" ?
              <Redirect
                to={`/upgrade`}
              />

              :
              <Redirect
                to={`/login?redirect=${props.location.pathname}${props.location
                  .search}`}
              />
      }
    />
  );
}