import React, { useState, useEffect } from "react";
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types"

InviteForm.propTypes = {
    user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string
    }),
    disabled: PropTypes.array,
    onChange: PropTypes.func.isRequired,
}

InviteForm.defaultProps = {
    user: {
        name: "",
        email: ""
    },
    disabled: []
}

const useStyles = makeStyles({
    textMargin: {
        margin: "0.5rem 0"
    }
})

const requiredValues = [
    {
        value: "name",
        verify: (value) => value
    },
    {
        value: "email",
        verify: (value) => {
            if (!value) return false;
            return value.includes("@")
        }
    },
]

function InviteForm(props) {
    const {
        onChange, 
        user,
        disabled } = props;
    const [mainProps, setProps] = useState(props)
    const [userData, setUserData] = useState(user);
    const classes = useStyles();

    const isComplete = (values) => {
        // Verify these fields are valid and not undefined
        let isComplete = true;

        for (const rv of requiredValues) {
            isComplete = isComplete && rv.verify(values[rv.value])
        }

        return isComplete
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const tmp = userData;
        tmp[name] = value;

        setUserData({ ...tmp })
        onChange({ ...tmp, complete: isComplete(tmp) })
    }

    useEffect(() => {
        setUserData({...props.user})
    }, [props])
    return (
        <>
            <div>
                {/* <form onSubmit={handleSubmit}> */}
                <TextField
                    fullWidth
                    name="name"
                    value={userData["name"]}
                    label={"Client's Name"}
                    onChange={handleChange}
                    className={classes.textMargin}
                    required
                    disabled={disabled.includes("name")}
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    name="email"
                    value={userData["email"]}
                    label={"Client's Email"}
                    onChange={handleChange}
                    className={classes.textMargin}
                    required
                    disabled={disabled.includes("email")}
                    variant="outlined"
                />

                {/* <Button className={classes.textMargin} fullWidth type="submit" variant="contained" color="primary">
                    {"Save"}
                </Button> */}
                {/* </form> */}
            </div>
        </>
    )

}

export default InviteForm;
