import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { checkStrength } from "../libs/verify";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LoadingMenu from "./LoadingMenu"
import { Auth } from "aws-amplify";

// Requiring specific props
FinalSignUpDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onEmailConfirm: PropTypes.func.isRequired,
    onPasswordSave: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    email: PropTypes.string
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    field: {
        margin: "1.5rem 0"
    },
    paper: {
        margin: 0,
        width: "90%"
    },
})

export default function FinalSignUpDialog(props) {
    const classes = useStyles();
    const [mainProps, setProps] = useState(props);
    const { open, onEmailConfirm, onPasswordSave, onError, setOpen, email } = mainProps;

    // Boolean indicating the current password is verified (free of all errors)
    const [passwordVerified, setPasswordVerified] = useState(false);

    // Boolean indicating the password is successfully saved
    const [passwordSaved, setPasswordSaved] = useState(false);

    // All current password errors
    const [reqPassErrors, setReqPassErrors] = useState([]);

    // A boolean indicating if the current password is viewable
    const [showPass, setShowPass] = useState(false)

    // A boolean indicating that the email code is currently verifying
    const [codeVerifying, setCodeVerifying] = useState(false);

    // A boolean indicating that the user account is in the process of being created
    const [creatingUser, setCreatingUser] = useState(false);

    // The error thrown by a request
    const [requestError, setRequestError] = useState("");

    // Form data
    const [verificationData, setVerificationData] = useState({
        original: "",
        duplicate: "",
        code: ""
    });

    // Handle password input changes
    const handlePasswordChange = (event) => {
        const { name, value } = event.target;

        const tmp = verificationData;
        tmp[name] = value;
        setVerificationData({ ...tmp })

        // Retrieve password strength errors
        const requiredTestErrors = checkStrength(tmp.original, { upper: true, lower: true, number: true });

        // if the password and the duplicate match AND there are no failed required tests...
        if ((tmp.original === tmp.duplicate) && !requiredTestErrors.length) {
            setReqPassErrors([])
            setPasswordVerified(true);
        }
        // Otherwise show the password errors
        else {
            setPasswordVerified(false);
            let errors = [];
            if (tmp.original !== tmp.duplicate) errors.push("Your passwords must match.")

            // Add all other errors
            errors = errors.concat(requiredTestErrors);
            setReqPassErrors([...errors])

        }

    }

    // Save the password and perform
    const handlePasswordSave = () => {

        async function sendCode(email, password) {
            try {
                // Reset errors
                setRequestError("")

                // Show that the user is being created
                setCreatingUser(true)

                // Call the specified function on password save
                await onPasswordSave()

                // Create the Cognito user
                await Auth.signUp({
                    username: email,
                    password
                });

                // Show that the user is done being created
                setCreatingUser(false)
                setPasswordSaved(true)
                setRequestError("")
            } catch (e) {
                setRequestError(e.message)
                onError(e);
                setCreatingUser(false)
                console.log(e)
            }


        }

        // Send the verification code
        sendCode(email, verificationData.original)
    }

    // Handle changes to verification code input
    const handleCodeChange = (event) => {
        const { name, value } = event.target;

        const tmp = verificationData;
        tmp[name] = value;

        setVerificationData({ ...tmp })
    }

    // Verify the code
    const handleVerifyCode = async () => {

        try {
            // Reset errors
            setRequestError("")

            // Show the code is currently verifying
            setCodeVerifying(true);

            // // Confirm the Cognito sign up
            await Auth.confirmSignUp(email, verificationData["code"]);

            // // Sign in the Cognito user
            await Auth.signIn(email, verificationData.original);

            // Show the code is done verifying
            setCodeVerifying(false);

            // Perform the specified function on email confirmation
            await onEmailConfirm()


        } catch (e) {

            setRequestError(e.message)
            setCodeVerifying(false);
        }

    }

    // Close the dialog
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        // Reset if and of the dialog's props change (i.e. dialog is closed, email changes)
        setProps(props)
        setVerificationData({
            original: "",
            duplicate: "",
            code: ""
        })
        setPasswordSaved(false)
        setRequestError("")
        setReqPassErrors([])

    }, [props])

    return (
        <div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={(!passwordSaved && !creatingUser) ? handleClose : () => { }}
                classes={{ paper: classes.paper }}

            >


                <DialogTitle>{passwordSaved ? "Verify Your Email" : "Select Your Password"}</DialogTitle>

                <DialogContent>

                    {/* If the password has not been saved yet.. show the password dialog */}
                    {
                        creatingUser ?

                            <>
                                <LoadingMenu
                                    text={`Your account is being created!`}
                                />
                            </>
                            :

                            !passwordSaved ?
                                <>
                                    {/* Print all password errors */}
                                    {
                                        reqPassErrors.map((item, idx) => (
                                            <Typography
                                                color="secondary"
                                                key={item + idx}
                                                variant="caption"
                                                component="p"
                                            >
                                                {item}
                                            </Typography>
                                        ))
                                    }
                                    <TextField
                                        fullWidth
                                        label="Your Password"
                                        className={classes.field}
                                        onChange={handlePasswordChange}
                                        type={!showPass ? "password" : ""}
                                        value={verificationData["original"]}
                                        name="original"

                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><i className="fas fa-key"></i></InputAdornment>,
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton edge="end" onClick={() => setShowPass(!showPass)}>
                                                        {
                                                            showPass ?
                                                                <Visibility />
                                                                :
                                                                <VisibilityOff />
                                                        }
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextField
                                        fullWidth
                                        label="Verify Your Password"
                                        className={classes.field}
                                        onChange={handlePasswordChange}
                                        type={!showPass ? "password" : ""}
                                        value={verificationData["duplicate"]}
                                        name="duplicate"

                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{passwordVerified ? <i style={{ color: "green" }} className="fas fa-check-circle"></i> : <i style={{ color: "red" }} className="fas fa-times-circle"></i>}</InputAdornment>,
                                        }}
                                        variant="outlined"
                                    />

                                </>
                                :
                                <>
                                    {/* VERIFICATION CODE */}
                                    <TextField
                                        fullWidth
                                        label="Verification Code (Check your email)"
                                        className={classes.field}
                                        onChange={handleCodeChange}
                                        value={verificationData["code"]}
                                        name="code"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><i className="fas fa-fingerprint"></i></InputAdornment>,
                                        }}
                                        variant="outlined"
                                    />

                                </>
                    }
                    <Typography
                        color="secondary"
                        variant="caption"
                        component="p"
                    >
                        {requestError}
                    </Typography>

                </DialogContent>


                <DialogActions>
                    {/* Show Dialog Actions for saving the password */}

                    {
                        !passwordSaved ?
                            <>
                                <Button disabled={creatingUser} onClick={handleClose} color="default">
                                    Cancel
                                </Button>
                                <Button disabled={!passwordVerified || creatingUser} onClick={handlePasswordSave} color="primary">
                                    Submit
                                </Button>
                            </>
                            :

                            <>
                                <Button disabled={codeVerifying} onClick={handleClose} color="default">
                                    Cancel
                                </Button>
                                <Button disabled={codeVerifying} onClick={handleVerifyCode} color="primary">
                                    Submit
                                </Button>
                            </>
                    }



                </DialogActions>


            </Dialog>
        </div>
    )
}