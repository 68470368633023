import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { API } from "aws-amplify";
import "./App.css";
import { loadCSS } from 'fg-loadcss';
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import { getUser, getUserSubscriptions, updateInvite } from "./libs/awsLib";


function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isSubscribed, userHasSubscribed] = useState(false)
  const [user, setUser] = useState({});

  async function retrieveUser(cognitoUser) {
    const { email } = cognitoUser.attributes
    const user_id = cognitoUser.attributes["custom:user_id"]
    const userData = await getUser(user_id)

    if (userData.type === "client" && userData.active) await updateInvite(userData, { lastLogin: (new Date().getTime()) / 1000 })

    return { ...userData, email };
  }

  async function onLoad() {
    try {
      // Verify account authentication
      const session = await Auth.currentSession();

      const user = await Auth.currentAuthenticatedUser();
      // Retrieve the user account 

      const userData = await retrieveUser(user)

      // If no user was retrieved, sign the current user out
      if (!userData) throw {};

      await verifyActiveSubscription(userData)

      userHasAuthenticated(true);
      setUser(userData)

    } catch (e) {

      await Auth.signOut();
      userHasAuthenticated(false);

      userHasSubscribed(false);


    }

    setIsAuthenticating(false);

    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    userHasSubscribed(false);

    setUser({})

    props.history.push("/login");
  }

  async function storeUser(userInfo) {
    if (userInfo) {
      try {
        const userData = await retrieveUser(userInfo)

        if (!userData) throw {}

        await verifyActiveSubscription(userData)

        setUser(userData)
        userHasAuthenticated(true);
        return {}
      }
      catch (e) {
        await Auth.signOut();
        userHasAuthenticated(false);
        userHasSubscribed(false);
        return null
      }

    }

    return null

  }

  async function verifyActiveSubscription(userData) {

    if (!userData) throw {}
    
    // Verify the user or realtor's subscription status
    let activePlan = false;
    let customer_id = ""
    
    if (userData.type === "personal") customer_id = userData.customer_id;
    
    else {
      // Retrieve realtor's Stripe customer ID
      const realtorData = await getUser(userData.realtor_id);

      // If the realtor's user account no longer exists... (TODO)
      if (!realtorData) return;
      setUser({ ...user, realtor: realtorData })

      customer_id = realtorData.customer_id;
    }

    // Retrieve the user's subscriptions
    const { data: userSubscriptions } = await getUserSubscriptions(customer_id);

    // If subscriptions is null, error case
    if (!userSubscriptions) throw {};

    // If there are no active subscriptions
    if (!userSubscriptions.length) return;

    else {

      // If the user's subscription is active or trialing..
      const activeStatus = ["trialing", "active"]
      if (activeStatus.includes(userSubscriptions[0].status)) activePlan = true;

      // If they are a client, verify that their account also hasn't been deactivated
      if (userData.type === "client") activePlan = activePlan && userData.active

      userHasSubscribed(activePlan);
    }

  }

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div className="App">

      <NavBar
        isAuthenticated={isAuthenticated}
        logout={handleLogout}
        user={user}
      />
      {
        isAuthenticating ?

          <Loading
            text={"Verifying your account..."}
          />

          :

          !isAuthenticating && user ?

            <>
              <div style={{ "padding": "2rem 1rem", height: "fit-content", minHeight: "100%" }}>

                <Routes appProps={{ isAuthenticated, userHasAuthenticated, isSubscribed, userHasSubscribed, storeUser, user, logout: handleLogout }} />
              </div>

              <Footer
                user={user}
              />
            </>
            : ""
      }

    </div>
  );
}

export default withRouter(App);
