import React, { useState } from "react";
import {
    Typography,
    Paper,
    Link,
    Grid,
    IconButton,
    Tooltip,
    CssBaseline,
    MenuItem,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "react-final-form";
import HomeTwoToneIcon from "@material-ui/icons/HomeTwoTone"; // Picker
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import validate from "../libs/validateForm";
import formFields from "../libs/formFields";

const { general, propertyLoanDetails, rentalDetails, constructionRemodelingDetails, taxDetails } = formFields;
const useStyles = makeStyles({
    header: {
        fontWeight: "bold",
        margin: "1rem 0"
    },
    root: {
        width: "90%",
        maxWidth: "1000px",
        margin: "auto"
    },
    title: {
        margin: "1rem 0",
        fontSize: "1.5rem"
    }
});

function AduForm({ onSubmit, initialValues, ActionButtons }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);

    const handleOpen = (event, newExpanded) => {
        setExpanded(!expanded);
    };

    const loadInitialFormData = adu => {
        const {
            address,
            propertyURL,
            propertyNickname,
            purchasePrice,
            purchaseDate,
            downPayment,
            mortgageAPR,
            mortgageTerm,
            closingCosts,
            rentIncreaseAnnually,
            unit1MonthlyRent,
            unit2MonthlyRent,
            unit3MonthlyRent,
            unit4MonthlyRent,
            unit1FirstRentMonth,
            unit2FirstRentMonth,
            unit3FirstRentMonth,
            unit4FirstRentMonth,
            constructionCosts,
            monthsofConstruction,
            constructionStartMonth,
            constructionFundedViaLoan,
            constructionLoanAPR,
            contructionLoanTerm,
            annualIncreaseInPropertyValue,
            sellingCosts,
            utilitiesGardenerHoa,
            vacancyandPropertyManagementFees,
            realEstateProfessional,
            incomeRaxRate,
            capGainsTaxRate,
            filingSingleorJointly
        } = adu;
        return {
            address,
            propertyURL,
            propertyNickname,
            purchasePrice,
            purchaseDate,
            downPayment,
            mortgageAPR,
            mortgageTerm,
            closingCosts,
            rentIncreaseAnnually,
            unit1MonthlyRent,
            unit2MonthlyRent,
            unit3MonthlyRent,
            unit4MonthlyRent,
            unit1FirstRentMonth,
            unit2FirstRentMonth,
            unit3FirstRentMonth,
            unit4FirstRentMonth,
            constructionCosts,
            monthsofConstruction,
            constructionStartMonth,
            constructionFundedViaLoan,
            constructionLoanAPR,
            contructionLoanTerm,
            annualIncreaseInPropertyValue,
            sellingCosts,
            utilitiesGardenerHoa,
            vacancyandPropertyManagementFees,
            realEstateProfessional,
            incomeRaxRate,
            capGainsTaxRate,
            filingSingleorJointly
        };
    };

    return (
        <div style={{ display: "flex" }}>

            <div className={classes.root}>
                <CssBaseline />
                <Form
                    onSubmit={onSubmit}
                    initialValues={loadInitialFormData(initialValues)}
                    validate={validate}
                    render={({ handleSubmit, submitting }) => (

                        <>
                            <form onSubmit={handleSubmit} noValidate>
                                <ActionButtons submitting={submitting} />
                                <Typography className={classes.title} variant="overline" color="textSecondary" component="h3" align="center" gutterBottom>
                                    ADU Evaluation Form
                                </Typography>

                                {/* <Paper style={{ padding: 16 }}> */}

                                <ExpansionPanel expanded={expanded} className={classes.rowMargin} onChange={handleOpen}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="general-content"
                                    >
                                        <Typography>General</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ display: "block" }}>

                                        <Grid container alignItems="flex-start" spacing={2}>
                                            {general.map((item, idx) => (
                                                <Grid item {...item.size} key={idx}>
                                                    {item.field}
                                                </Grid>
                                            ))}

                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                {/* Advanced Inputs */}
                                <ExpansionPanel className={classes.rowMargin}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="advanced-content"
                                    >
                                        <Typography>Advanced</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ display: "block" }}>
                                        <Typography className={classes.header} variant="h5" color="primary"> Property and Loan Details </Typography>
                                        <Grid container alignItems="flex-start" spacing={2}>
                                            {propertyLoanDetails.map((item, idx) => (
                                                <Grid item {...item.size} key={idx}>
                                                    {item.field}
                                                </Grid>
                                            ))}

                                        </Grid>
                                        <Typography className={classes.header} variant="h5" color="primary"> Rental ADU Details </Typography>
                                        <Grid container alignItems="flex-start" spacing={2}>
                                            {rentalDetails.map((item, idx) => (
                                                <Grid item {...item.size} key={idx}>
                                                    {item.field}
                                                </Grid>
                                            ))}

                                        </Grid>
                                        <Typography className={classes.header} variant="h5" color="primary"> Construction or Remodeling Details </Typography>
                                        <Grid container alignItems="flex-start" spacing={2}>
                                            {constructionRemodelingDetails.map((item, idx) => (
                                                <Grid item {...item.size} key={idx}>
                                                    {item.field}
                                                </Grid>
                                            ))}

                                        </Grid>
                                        <Typography className={classes.header} variant="h5" color="primary"> Tax Details </Typography>
                                        <Grid container alignItems="flex-start" spacing={2}>
                                            {taxDetails.map((item, idx) => (
                                                <Grid item {...item.size} key={idx}>
                                                    {item.field}
                                                </Grid>
                                            ))}

                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                                {/* </Paper> */}
                                {/* CRUD Button Bar */}
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                            </form>
                        </>
                    )}
                />
            </div>
        </div>
    );
}

export default AduForm;