import React, { useState } from "react"
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PropTypes from "prop-types";

ViewablePasswordField.propTypes = {
    value: PropTypes.string.isRequired
}

export default function ViewablePasswordField(props) {
    const [showPass, setShowPass] = useState(false)
    const { value, onChange } = props;

    return (

        <TextField
            autoComplete="off"
            required
            label="Password"
            type={!showPass ? "password" : ""}
            value={value}
            name="password"
            onChange={onChange}
            variant="outlined"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" >
                        <IconButton edge="end" onClick={() => setShowPass(!showPass)}>
                            {
                                showPass ?
                                    <Visibility />
                                    :
                                    <VisibilityOff />
                            }
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}

        />
    )
}