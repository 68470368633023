const owasp = require("owasp-password-strength-test");

owasp.config({
    allowPassphrases       : false,
    maxLength              : 64,
    minLength              : 8,
    minOptionalTestsToPass : 4,
  });

exports.checkStrength = (password, requirements) => {
  const {upper, lower, special, number} = requirements;

  let {requiredTestErrors: results} = owasp.test(password);

  let containsUpper = !(password === password.toLowerCase());
  let containsLower = !(password === password.toUpperCase());
  let containsSpecial = /^[a-zA-Z0-9]*$/.test(password);
  let containsNumber = !(/^[a-zA-Z]*$/.test(password));

  if(upper && !containsUpper) results.push('The password must contain at least one uppercase letter.')

  if(lower && !containsLower) results.push('The password must contain at least one lowercase letter.')

  if(special && !containsSpecial) results.push('The password must contain at least one special letter.')

  if(number && !containsNumber) results.push('The password must contain at least one number.')

  return results
}


